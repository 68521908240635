import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dates: [],
};

const reducers = {
  setBooking: (state, action) => {
    state.reservations = action.payload;
  },

  setDates: (state, action) => {
    let dates = state.dates.push(action.payload)
    state.dates = dates;
  },


};

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers,
});

const { actions, reducer } = reservationSlice ;
// ACTIONS
export const { setBooking , setDates} = actions;
// GETTERS
export const selectReservationState = (state) => state.asset;

export default reducer;
