import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Switch } from 'antd';
import { BookingItemStyled, BookingItemLeft, BookingItemRight } from './Wraps';
import { ADMINISTRATIVEROLES } from 'constants';

function BookingItem({
  bookerName,
  date,
  type,
  status,
  startTime,
  endingTime,
  attendees,
  adminHandler,
  residentHandler,
}) {
  const user = localStorage.getItem('bmF2aWdhdGlvbi10eXBl').slice(1, -1);
  const role = window.atob(user);

  return (
    <BookingItemStyled>
      <Link to='/dashboard/reservas'>
        <BookingItemLeft>
          <span>{date}</span>
          <br></br>
          <p>{type}</p>
          {ADMINISTRATIVEROLES.includes(role) ? (
            <div>
              <span>Reserva a nombre de : {bookerName}</span>
              <br></br>
            </div>
          ) : (
            ''
          )}
          <span>
            Horario: desde {moment(startTime, 'H:m:s').format('h:mm a')} hasta {moment(endingTime, 'H:m:s').format('h:mm a')}
          </span>
          <br></br>
          <span> Asistentes: {attendees}</span>
        </BookingItemLeft>
        <BookingItemRight>
          {ADMINISTRATIVEROLES.includes(role) ? (
            <Switch
              defaultChecked={status === 'Confirmada' && ['true']}
              checkedChildren={status !== 'Confirmada' ? 'Confirmar' : status}
              unCheckedChildren={status !== 'Confirmada' ? status : 'Rechazar'}
              onChange={adminHandler}
            />
          ) : status === 'Confirmada' ? (
            <Switch
              defaultChecked={status === 'Confirmada' && ['false']}
              checkedChildren={status}
              unCheckedChildren='Cancelar'
              onChange={residentHandler}
            />
          ) : (
            <span>{status}</span>
          )}
        </BookingItemRight>
      </Link>
    </BookingItemStyled>
  );
}

export default BookingItem;
