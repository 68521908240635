import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, message } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { SpaceYWrap, ButtonStyled, InputStyled, PasswordStyled } from './Wraps';
import { TopBar, Logo, AutoScreenWrap } from 'components';
import { singIn, selectAuthState, setReqError } from 'redux/index';
import { formRules } from './form-rules';
import { AUTH_KEY } from 'constants';
import { mapLoginData } from './helper';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isAuth, token, isBadReq } = useSelector(selectAuthState);

  useEffect(() => {
    if (isAuth === true && token !== null) {
      navigate('dashboard', { replace: true });
    }
    if (isBadReq === true) {
      message.error({
        content:
          '¡Autenticación fallida! nombre de usuario o contraseña inválidos.',
        key: AUTH_KEY,
      });
      dispatch(setReqError({ isBadReq: false }));
    }
  }, [isAuth, token, isBadReq, navigate, dispatch]);
  const onReset = () => form.resetFields();
  const onFinish = (loginData) => {
    const payload = {
      password: loginData.password,
      username: loginData.username,
    };
    dispatch(singIn(mapLoginData(payload)));
    message.loading({
      content: 'Ejecutando autenticación...',
      key: AUTH_KEY,
      duration: null,
    });
    onReset();
  };
  return (
    <AutoScreenWrap>
      <TopBar name='Login' />

      <Row justify='center' align='center'>
        <Col
          xs={{ span: 20 }}
          sm={{ span: 18 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          xl={{ span: 6 }}
        >
          <SpaceYWrap>
            <Logo />
          </SpaceYWrap>
        </Col>
      </Row>

      <Row justify='center' align='center'>
        <Col
          xs={{ span: 20 }}
          sm={{ span: 18 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          xl={{ span: 6 }}
        >
          <Form
            onFinish={onFinish}
            autoComplete='off'
            size='large'
            autoSave='on'
            form={form}
          >
            <Form.Item name='username' rules={formRules.username} hasFeedback>
              <InputStyled placeholder='Nombre de usuario o email.' />
            </Form.Item>

            <Form.Item name='password' rules={formRules.password} hasFeedback>
              <PasswordStyled placeholder='Contraseña' />
            </Form.Item>

            <div style={{ textAlign: 'right' }}>
              <Link to='/restablecer-contrasena'>Restablecer contraseña</Link>
            </div>

            <Form.Item>
              <Row justify='center'>
                <Col xs={{ span: 16 }} sm={{ span: 16 }}>
                  <ButtonStyled
                    type='primary'
                    size='large'
                    block
                    htmlType='submit'
                  >
                    Ingresar
                  </ButtonStyled>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </AutoScreenWrap>
  );
}

export default Login;
