import styled from 'styled-components';

export const MobileNavWrap = styled.div`
  margin-top: auto;
  height: 6rem;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  & > a > svg {
    color: ${({ theme }) => theme.colors.graySix};
    width: 3.2rem;
    height: 3.2rem;
  }

  & > a.is-active {
    & > svg {
      color: ${({ theme }) => theme.colors.primary} !important;
      transform: scale(0.9);
    }
  }

`;
