import {
  OPERATOR_MODULE,
  RESIDENT_MODULE,
  ADMIN_MODULE,
} from '../../../constants';
import {
  AdminOptions,
  OperatorOptions,
  ResidentOptions,
  SuperAdminOptions,
} from './routes';
import PropTypes from 'prop-types';
function Home({ role, residential }) {
  switch (true) {
    case role === OPERATOR_MODULE:
      return <OperatorOptions uuid={residential} />;
    case role === RESIDENT_MODULE:
      return <ResidentOptions uuid={residential} />;
    case role === ADMIN_MODULE:
      return <AdminOptions uuid={residential} />;
    default:
      return <SuperAdminOptions uuid={residential} />;
  }
}

Home.propTypes = {
  role: PropTypes.string.isRequired,
  residential: PropTypes.string.isRequired,
};
export default Home;
