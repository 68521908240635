export const getImageObject = (payload) => {
  const url = new URL(payload.post.image);
  const fileName = url.pathname.split('/').slice(-1)[0];
  return {
    uid: fileName,
    name: fileName,
    status: 'done',
    url: payload.post.image,
  };
};
