import { useEffect } from 'react';
import { Form, Col, Row, DatePicker, Input } from 'antd';
import { ButtonStyled } from 'components';
import { vehicleForm } from './form-rules';
import { preventDefault } from 'helpers/forms';
import PropTypes from 'prop-types';

function VehicleForm({ onVehicles, onEdition, edition }) {
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (data) => {
    if (edition == null) onVehicles(data);
    else onEdition({ id: edition?.id, ...data });
    onReset();
  };
  const onFill = () => {
    form.setFieldsValue({
      autoColor: edition?.autoColor,
      license: edition?.license,
      carModel: edition?.carModel,
      carYear: edition?.carYear,
    });
  };
  useEffect(() => {
    if (edition != null) onFill();
    return () => {
      onReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edition]);

  return (
    <Form size='large' onFinish={onFinish} autoComplete='off' form={form}>
      <Row justify='center' align='center' gutter={[16, {}]}>
        <Col xs={{ span: 22 }} sm={{ span: 11 }}>
          <Form.Item
            label='Color del vehículo'
            name='autoColor'
            required
            hasFeedback
            tooltip='Por favor escribe el color del vehículo.'
            style={{
              display: 'block',
            }}
            rules={vehicleForm.autoColor}
          >
            <Input
              placeholder='Escribe aquí...'
              onPressEnter={preventDefault}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 22 }} sm={{ span: 11 }}>
          <Form.Item
            label='Placa del vehículo'
            name='license'
            required
            tooltip='Por favor escribe la placa del vehículo.'
            hasFeedback
            style={{
              display: 'block',
            }}
            rules={vehicleForm.license}
          >
            <Input
              placeholder='Escribe aquí...'
              onPressEnter={preventDefault}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center' align='center'>
        <Col xs={{ span: 22 }}>
          <Form.Item
            label='Modelo del vehículo'
            name='carModel'
            required
            tooltip='Por favor escribe el modelo del vehículo.'
            style={{
              display: 'block',
            }}
            hasFeedback
            rules={vehicleForm.carModel}
          >
            <Input
              placeholder='Escribe aquí...'
              onPressEnter={preventDefault}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 22 }}>
          <Form.Item
            label='Año'
            name='carYear'
            required
            tooltip='Por favor seleccionar el año del vehículo.'
            hasFeedback
            style={{
              display: 'block',
            }}
            rules={vehicleForm.carYear}
          >
            <DatePicker
              onChange={null}
              picker='year'
              style={{
                display: 'block',
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={{ span: 16 }}>
          <ButtonStyled type='primary' size='large' htmlType='submit' block>
            Agregar vehículo
          </ButtonStyled>
        </Col>
      </Row>
    </Form>
  );
}

vehicleForm.propTypes = {
  onVehicles: PropTypes.func.isRequired,
  onEdition: PropTypes.func.isRequired,
  edition: PropTypes.object,
};

export default VehicleForm;
