import moment from 'moment';
import { getToken } from 'helpers/localstorage';
import {
  validateUniqueEmail,
  validateUniqueUsername,
  validateUniquePhone,
} from 'redux/index';
import {
  SUPERUSER_MODULE,
  ADMIN_MODULE,
  OPERATOR_MODULE,
  RESIDENT_MODULE,
  ERROR,
} from 'constants';
import { openNotification } from 'helpers';
import { Form, Select } from 'antd';

const USER = 'Usuario';
const OPERATOR = 'Operador';
const ADMINISTRATOR = 'Administrador';
export const gender = ['male', 'female'];
export const roles = [
  SUPERUSER_MODULE,
  ADMIN_MODULE,
  OPERATOR_MODULE,
  RESIDENT_MODULE,
];

export const userRules = {
  fullName: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 4,
    },
    {
      max: 150,
    },
  ],
  userName: [
    { transform: (value) => mapShippingUsername(value) },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 8,
    },
    {
      max: 100,
    },
    (form) => ({
      async validator(_, value) {
        const valueLen = value ? value.trim().length : 0;
        if (valueLen < 8) return Promise.reject();
        if (valueLen > 100) return Promise.reject();
        const mappedValue = mapShippingUsername(value);
        form.setFieldsValue({
          userName: mappedValue,
        });
        const res = await validateUniqueUsername(mappedValue, getToken());
        if (res.available === false)
          return Promise.reject(new Error('El nombre de usuario esta en uso.'));
        return Promise.resolve();
      },
    }),
  ],
  address: [
    { transform: (value) => value.trim() },
    {
      required: false,
    },
    {
      whitespace: true,
    },
    {
      min: 2,
    },
    {
      max: 255,
    },
  ],
  email: [
    { transform: (value) => value.trim() },
    {
      type: 'email',
    },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 150,
    },
    ({ _getFieldValue }) => ({
      async validator(_, value) {
        const valueLen = value ? value.trim().length : 0;
        if (valueLen < 6) return Promise.reject();
        if (valueLen > 150) return Promise.reject();
        // eslint-disable-next-line no-useless-escape
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value))
          return Promise.reject();
        const res = await validateUniqueEmail(value, getToken());
        if (res.available === false)
          return Promise.reject(new Error('El email ya está en uso.'));
        return Promise.resolve();
      },
    }),
  ],
  cellphone: [
    { transform: (value) => value.trim() },
    {
      type: 'string',
      required: true,
      pattern: /3[0-9]{9}$/gm,
      message: 'El Numero Celular debe contender 10 números',
    },
    ({ _getFieldValue }) => ({
      async validator(_, value) {
        const regex = /3[0-9]{9}$/gm;
        if (value == null || value === '' || !regex.test(value)) return;

        const res = await validateUniquePhone(value, getToken());
        if (res === null)
          return Promise.reject(
            new Error(
              'Error al verificar el número, por favor comunícate con soporte.'
            )
          );

        if (res.available === false)
          return Promise.reject(
            new Error(
              'El número celular ya está en uso, por favor ingresa un número distinto.'
            )
          );
        return Promise.resolve();
      },
    }),
  ],
  dayOfBirthday: [
    { transform: (value) => value.trim() },
    {
      type: 'date',
    },
  ],
  gender: [{ transform: (value) => value.trim() }],
  role: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
  ],
  apartmentNumber: [
    { transform: (value) => value.trim() },
    {
      whitespace: true,
    },
    {
      min: 1,
    },
    {
      max: 50,
    },
    {
      type: 'string',
    },
  ],
  parking: [
    { transform: (value) => value.trim() },
    {
      whitespace: true,
    },
    {
      min: 1,
    },
    {
      max: 15,
    },
    {
      type: 'string',
    },
  ],
  members: [
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 255,
    },
    {
      type: 'string',
    },
  ],
  frequentVisits: [
    { transform: (value) => value.trim() },
    {
      type: 'string',
    },
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 255,
    },
  ],
};

export const userEditionRules = {
  userName: [
    { transform: (value) => mapShippingUsername(value) },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 8,
    },
    {
      max: 100,
    },
    (form) => ({
      async validator(_, value) {
        const valueLen = value?.length;
        if (valueLen < 8) return Promise.reject();
        if (valueLen > 100) return Promise.reject();
        const userId = form.getFieldValue('userId');
        const mappedValue = mapShippingUsername(value);
        form.setFieldsValue({
          userName: mappedValue,
        });
        const res = await validateUniqueUsername(mappedValue, getToken());
        if (res.available === false) {
          if (res.id === userId) return Promise.resolve();
          return Promise.reject(new Error('El nombre de usuario esta en uso.'));
        }
        return Promise.resolve();
      },
    }),
  ],
  email: [
    { transform: (value) => value.trim() },
    {
      type: 'email',
    },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 100,
    },
    (form) => ({
      async validator(_, value) {
        const valueLen = value?.length;
        if (valueLen < 6) return Promise.resolve();
        if (valueLen > 150) return Promise.resolve();
        // eslint-disable-next-line no-useless-escape
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value))
          return Promise.reject();
        const userId = form.getFieldValue('userId');
        const res = await validateUniqueEmail(value.trim(), getToken());
        if (res.available === false) {
          if (res.id === userId) return Promise.resolve();
          return Promise.reject(new Error('El email ya está en uso.'));
        }
        return Promise.resolve();
      },
    }),
  ],
  cellphone: [
    { transform: (value) => value.trim() },
    {
      type: 'string',
      required: true,
      pattern: /3[0-9]{9}$/gm,
      message: 'El Numero Celular debe contender 10 números',
    },
    (form) => ({
      async validator(_, value) {
        const regex = /3[0-9]{9}$/gm;
        if (value == null || value === '' || !regex.test(value)) return;

        const res = await validateUniquePhone(value, getToken());
        if (res === null)
          return Promise.reject(
            new Error(
              'Error al verificar el número, por favor comunícate con soporte.'
            )
          );
        const userId = form.getFieldValue('userId');
        if (res.available === false) {
          if (res.id === userId) return Promise.resolve();
          return Promise.reject(
            new Error(
              'El número celular ya está en uso, por favor ingresa un número distinto.'
            )
          );
        }

        return Promise.resolve();
      },
    }),
  ],
};

export function mapUserData(user) {
  const formData = {
    userId: user.id,
    fullName: user.fullName,
    userName: user.userName,
    email: user.email,
    gender: user.gender ?? null,
  };

  if (user.phoneNumber) {
    const { prefix, phone } = mapPhoneNumber(user.phoneNumber);
    formData.phoneNumber = phone;
    formData.prefix = prefix;
  }

  formData.image = user.image ? mapImage(user.image) : [];
  formData.dateOfBirth = user.dateOfBirth ? moment(user.dateOfBirth) : '';

  return formData;
}

export function mapProfileData(profile) {
  const { vehicles, members, pets, visits } = profile;

  const formObj = {};

  // address
  if (profile.address != null) formObj['address'] = profile.address;

  // parking
  if (profile.parkingNumber != null) formObj['parking'] = profile.parkingNumber;

  // frequent visits
  const frequentVisitsArr =
    visits.length !== 0 && visits.length != null
      ? mapFrequentVisits(visits)
      : [];

  // members
  const membersArr =
    members.length !== 0 && members.length != null ? mapMembers(members) : [];

  // vehicles
  const vehiclesArr =
    vehicles.length !== 0 && vehicles.length != null
      ? mapVehicles(vehicles)
      : [];

  // pets
  const petsArr = pets.length !== 0 ? mapPets(pets) : [];

  return {
    formObj,
    frequentVisitsArr,
    membersArr,
    vehiclesArr,
    petsArr,
  };
}

function mapImage(imgUrl) {
  const url = new URL(imgUrl);
  const fileName = url.pathname.split('/').slice(-1)[0];
  return [
    {
      uid: fileName,
      name: fileName,
      status: 'done',
      url: imgUrl,
    },
  ];
}

export function mapPets(pets) {
  return pets.map((pet) => {
    const petObj = {
      id: pet.id,
      petName: pet.name,
      petColor: pet.color,
    };
    if (pet.dogBreed) petObj['dogBreed'] = pet.dogBreed;
    if (pet.dateOfBirth) petObj['petBirthday'] = moment(pet.dateOfBirth);
    return petObj;
  });
}
export function mapVehicles(vehicles) {
  return vehicles.map((vehicle) => {
    return {
      id: vehicle.id,
      license: vehicle.plate,
      autoColor: vehicle.color,
      carModel: vehicle.model,
      carYear: moment(vehicle.year),
    };
  });
}
export function mapMembers(members) {
  return members.map((member) => {
    return {
      id: member.id,
      value: member.name,
    };
  });
}
export function mapFrequentVisits(visits) {
  const mappedVisits = visits.map((visit) => {
    return {
      id: visit.id,
      value: visit.fullName,
    };
  });
  return mappedVisits;
}

export function mapPhoneNumber(phoneNumber) {
  if (phoneNumber == null) return { prefix: '57', phone: '' };
  const [prefix, phone] = phoneNumber.split(' ');
  return { prefix: prefix.slice(1), phone };
}

export function mapRole(role) {
  if (role === RESIDENT_MODULE) return USER;
  if (role === OPERATOR_MODULE) return OPERATOR;
  if (role === ADMIN_MODULE) return ADMINISTRATOR;
  return null;
}

export function getOriginalRole(role) {
  if (role === USER) return RESIDENT_MODULE;
  if (role === OPERATOR) return OPERATOR_MODULE;
  if (role === ADMINISTRATOR) return ADMIN_MODULE;
  return null;
}

// Submit
export function mapShippingPhone(prefix, phoneNumber) {
  return `+${prefix} ${phoneNumber}`;
}

export function mapShippingMembers(members) {
  return members.map((member) => {
    return { name: member.value };
  });
}

export function mapShippingPets(pets) {
  return pets.map((pet) => {
    const petObj = {
      name: pet.petName,
      color: pet.petColor,
    };
    if (pet?.dogBreed) petObj['dogBreed'] = pet.dogBreed;
    if (pet?.petBirthday) petObj['dateOfBirth'] = pet.petBirthday.format();
    return petObj;
  });
}

export function mapShippingVisits(visists) {
  return visists.map((visit) => visit.value);
}

export function mapShippingVehicles(vehicles) {
  return vehicles.map((vehicle) => {
    return {
      plate: vehicle.license,
      color: vehicle.autoColor,
      model: vehicle.carModel,
      year: vehicle.carYear.format('YYYY'),
    };
  });
}

export function mapShippingDateOfBirth(dayOfBirthday) {
  return new Date(dayOfBirthday.format('YYYY-MM-DD')).toISOString();
}

export function hanldeShippingValidation(formData) {
  if (formData.gender) {
    if (!gender.includes(formData.gender)) {
      openNotification(
        ERROR,
        'Error fatal, El genero seleccionado no está disponible.'
      );
      return;
    }
  }
  return true;
}

export function mapShippingUsername(username) {
  return username.trim().split(' ').join('').toLowerCase();
}

export const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Select.Option value='57'>+57</Select.Option>
    </Select>
  </Form.Item>
);

export const genders = [
  {
    key: 'male',
    value: 'Hombre',
  },
  {
    key: 'female',
    value: 'Mujer',
  },
];
