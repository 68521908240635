import styled from 'styled-components';
import { Result } from 'antd';

export const ExpirationLinkWrap = styled.div`
  overflow: hidden;
  width: 100%;
  background: #134e5e;
  background: -webkit-linear-gradient(to right, #71b280, #134e5e);
  background: linear-gradient(to right, #71b280, #134e5e);
`;

export const ResultStyled = styled(Result)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 0;

  /* & .ant-result-icon > span {
    color: #2db7f5 !important;
  } */

  & .ant-result-icon {
    margin: 0;
  }

  & .ant-result-title {
    opacity: 0.9;
  }
  & .ant-result-subtitle {
    /* color: ; */
    /* color: #000; */
    /* opacity: 0.8; */
  }

  @media (min-width: 576px) {
    transform: scale(1.5);
  }
`;
