export const moduleKeys = {
  keyInit: 'inicio',
  keyProfile: 'perfil',
  keyWall: 'muro',
  keyBooking: 'reservas',
  keyResources: 'recursos',
  keyAssets: 'activos',
  keyUsers: 'usuarios',
  keyBulkUpload: 'bulk-upload',
  dynamicLinks: 'dynamic-links',
};
