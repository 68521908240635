import { TopBarContainer } from './Wraps';
import UploadExcelFile from './components/upload-file';
import ManageTemplate from './components/manage-templates';

function TopActions(props) {
  return (
    <TopBarContainer>
      <div className='actions__left-side'>
        <ManageTemplate {...props} />
      </div>
      <div className='actions__right-side'>
        <UploadExcelFile {...props} />
      </div>
    </TopBarContainer>
  );
}

export default TopActions;
