import styled from 'styled-components';

export const TopBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  & .actions__left-side {
  }

  & .actions__right-side {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    & > span {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
    }

    & div[class='ant-upload-list ant-upload-list-text'] {
      display: none;
    }
  }

  @media (min-width: 768px) {
    margin-top: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;

    & .actions__right-side {
      flex-direction: row;
      & div[class='ant-upload-list ant-upload-list-text'] {
        display: initial;
      }
    }
  }
`;
