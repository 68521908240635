const borders = {
  2: '0.2rem',
  4: '0.4rem',
  6: '0.6rem',
  8: '0.8rem',
  12: '1.2rem',
  14: '1.4rem',
  16: '1.6rem',
  18: '1.8rem',
  20: '2rem',

  24: '2.4rem',
  26: '2.6rem',
  28: '2.8rem',

  circle: '50%',
};

export default borders;
