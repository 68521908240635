import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const reducers = {};

const linksSlice = createSlice({
  name: 'links',
  initialState,
  reducers,
});

const { reducer } = linksSlice;

// SETTERS
// export {} = actions;
// GETTERS
export const selectLinksState = (state) => state.linksManagement;

export default reducer;
