export const preventDefault = (event) => {
  event.preventDefault();
};

export function generateCapitalLetter(value) {
  if (!value) throw new Error('value param forgotten');
  return value
    .trim()
    .split(' ')
    .map((name) => {
      return name[0].toUpperCase() + name.slice(1);
    })
    .join(' ');
}

export const getImageObject = (payload) => {
  const url = new URL(payload.post.image);
  const fileName = url.pathname.split('/').slice(-1)[0];
  return {
    uid: fileName,
    name: fileName,
    status: 'done',
    url: payload.post.image,
  };
};
