import { useState, useEffect } from 'react';
import 'moment/locale/es';
import moment from 'moment';
import es_ES from 'antd/lib/locale/es_ES';
import { IoCalendarOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  ConfigProvider,
  Card,
  Switch,
  message,
  Grid,
} from 'antd';
import { HttpSpinner, TopBarIcon } from 'components';
import { getAssets } from 'redux/index';
import { validateBooking, cresteBookings } from 'redux/index';
import {
  NewBookingContainer,
  CardWrap,
  InputNumberCustom,
  ButtonStyled,
  RangePickerStyled,
  DatePickerStyled,
  CareDaysAseet,
  CareDayAseetList,
} from './Wraps';
import { searchBooker } from 'redux/booking/booker-service';
import { selectProfileId } from 'redux/profiles/profiles-slice';
import { ADMINISTRATIVEROLES } from 'constants';

const initEvent = {
  asset: false,
  daysOfAttentionToThePublic: [],
  scheduleAvailable: null,
  schedule: null,
  selectedTime: [],
  validDays: [],
};

function NewBooking() {
  const navigate = useNavigate();
  const screens = Grid.useBreakpoint();
  const { Item } = Form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [addNewDate, setAddNewDate] = useState(false);
  const [validateValues, setValidateValues] = useState(initEvent);
  const [jointAsset, setJointAsset] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [residents, setResidents] = useState([]);
  const user = localStorage.getItem('bmF2aWdhdGlvbi10eXBl').slice(1, -1);
  const role = window.atob(user);
  const { asset, validDays, scheduleAvailable, daysOfAttentionToThePublic } =
    validateValues;

  const handlerAssets = async () => {
    setLoading(true);
    const assets = await getAssets('Activated');
    setJointAsset(assets);
    setLoading(false);
    if (role) {
      const id = selectProfileId();
      await searchBooker(id);
    }
  };

  useEffect(() => {
    handlerAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedPlace = async (value) => {
    const { id } = jointAsset.find((e) => e.name === value);
    const selectedAsset = await getAssets(id);
    const careDays = selectedAsset.schedule.map((e) => {
      return `${e.day} desdes las ${moment(e.startTime, 'H:m:s').format(
        'h:mm a'
      )}, hasta las ${moment(e.endingTime, 'H:m:s').format('h:mm a')}`;
    });
    setValidateValues({
      ...validateValues,
      asset: selectedAsset,
      daysOfAttentionToThePublic: careDays,
    });
  };

  const onReset = () => {
    form.resetFields();
    setValidateValues({
      ...validateValues,
      daysOfAttentionToThePublic: [],
    });
  };

  const formRules = {
    place: [
      {
        required: true,
        message: '¡Por favor selecciona el lugar a reservar!',
      },
      {
        type: 'string',
        message: '¡El valor seleccionado no es válido!',
      },
    ],
    date_range: [
      {
        required: true,
        message: '¡Por favor selecciona una fecha de inicio y de fin!',
      },
      ({ _getFieldValue }) => ({
        async validator(_, value) {
          if (value == null) return;

          const date1NoFormat = value[0];
          const date2NoFormat = value[1];

          if (!asset) {
            form.resetFields();
          } else {
            const currentDate = moment(new Date()).format('YYYY-MM-DD');

            const thisDateHasPassed =
              date1NoFormat.format('YYYY-MM-DD').slice(0, 10) < currentDate;
            const isBookingToday =
              currentDate === date1NoFormat.format('YYYY-MM-DD').slice(0, 10);

            if (thisDateHasPassed) {
              return Promise.reject(
                new Error(
                  'No es posible agendar en una fecha anterior a la fecha actual.'
                )
              );
            }

            if (isBookingToday) {
              return Promise.reject(
                new Error(
                  'No es posible agrandar en la fecha de hoy, Por favor recuerda que debes agendar con un día de anterioridad.'
                )
              );
            } else {
              const days = [];
              let diff = date2NoFormat.diff(date1NoFormat, 'days');

              const getDate = (dateP) => {
                const splittedDate = dateP.format('YYYY-MM-DD').split('-');
                return `${splittedDate.join('-')} 00:00:00`;
              };

              const date = getDate(date1NoFormat);

              days.push({ date, asset: asset.id });

              if (diff >= 1) {
                for (var i = 0; i < diff; i++) {
                  const addDay = date1NoFormat.add(1, 'd');
                  const date = getDate(addDay);
                  days.push({ date, asset: asset.id });
                }
              }
              const res = await validateBooking(days, 'validateDay');

              setValidateValues({
                ...validateValues,
                validDays: res.validDays,
              });

              if (res.ok === false) {
                const schedule = asset?.schedule
                  ?.map((item) => {
                    return item.day;
                  })
                  .join(' , ');
                if (schedule) {
                  return Promise.reject(
                    new Error(
                      `${res.message} recuerda que los dias de atención en ${asset.name} son los ${schedule}`
                    )
                  );
                }
              }
            }
          }
        },
      }),
    ],

    time_range: [
      {
        required: true,
        message: '¡Por favor selecciona una hora de inicio y fin!',
      },
      ({ _getFieldValue }) => ({
        async validator(_, value) {
          setValidateValues({ ...validateValues, scheduleAvailable: null });
          if (value == null) return;

          let results = [];
          let invalidDays = [];
          const startTime = value[0]._d.toTimeString().slice(0, 5);
          const endingTime = value[1]._d.toTimeString().slice(0, 5);

          for (var i = 0; i < validDays.length; i++) {
            const res = await validateBooking(
              {
                date: validDays[i],
                asset: asset.id,
                startTime,
                endingTime,
              },
              'validateHour'
            );

            if (res.ok) {
              setValidateValues({
                ...validateValues,
                selectedTime: [startTime, endingTime],
              });
            }
            if (res.ok === false && res.scheduleAvailable) {
              setValidateValues({
                ...validateValues,
                scheduleAvailable: res,
              });

              invalidDays.push(res.date);
            }
            if (!res.scheduleAvailable && res.ok === false) {
              results.push(res.officeHours);
            }
          }

          if (results.length >= 1) {
            return Promise.reject(
              new Error(
                `No es posible agendar en el horario ${moment(
                  startTime,
                  'h:mm a'
                ).format('h:mm a')} - ${moment(endingTime, 'h:mm a').format(
                  'h:mm a'
                )}, recuerda horario de atención`
              )
            );
          }
        },
      }),
    ],

    capacity: [
      {
        required: true,
        message: '¡Por favor ingresa la capacidad máxima del activo!',
      },
      {
        type: 'number',
        message: '¡El valor seleccionado no es válido!',
      },

      ({ _getFieldValue }) => ({
        async validator(_, value) {
          if (value !== null) {
            if (value > asset.capacity) {
              return Promise.reject(
                new Error(
                  `Solo se permiten como máximo ${asset.capacity} personas `
                )
              );
            }

            if (
              asset.multipleReservation === true &&
              value <= asset.capacity
            ) {
              for (var i = 0; i < validDays.length; i++) {
                const res = await validateBooking(
                  {
                    asset: asset.id,
                    attendees: value,
                    date: validDays[i],
                    startTime: validateValues.selectedTime[0],
                    endingTime: validateValues.selectedTime[1],
                  },
                  'validateCapacity'
                );

                if (res.ok === false) {
                  return Promise.reject(new Error(`${res.message} en el horario de ${moment(res.scheduleValidation[0],'h:mm a'
                  ).format('h:mm a')} a ${moment(res.scheduleValidation[1],'h:mm a'
                  ).format('h:mm a')}`));
                }
              }
            }
          }
        },
      }),
    ],

    bookerName: [
      {
        required: true,
        message:
          '¡Por favor escribe el nombre de la persona a quien se le asignará la reserva!',
      },
      {
        min: 8,
        message:
          'Ingresa el nombre y el apellido de la persona a quien se le asignará la reserva!',
      },
    ],
  };

  const onFinish = async (data) => {
    const { time_range_conflict, time_range, attendees, description, user } =
      data;

    const request = {
      asset: asset.id,
      attendees,
      description,
    };
    if (ADMINISTRATIVEROLES.includes(role)) {
      const resident = residents?.find((e) => e.value === user);
      request.bookerName = resident.text;
      if (resident.text !== resident.value) {
        request.booker = resident.value;
      }
    } else {
      request.bookerName = user;
    }
    const bookings = [];
    const bookingsConflict = [];
    if (time_range_conflict) {
      for (let i = 0; i < validDays.length; i++) {
        const date =
          validDays[i].length === 18
            ? `${validDays[i].slice(0, 8)}0${validDays[i].slice(8, 9)}`
            : validDays[i].slice(0, 10);
        request.date = date;
        if (date === scheduleAvailable.date.slice(0, 10)) {
          request.startTime = time_range_conflict[0]._d
            .toTimeString()
            .slice(0, 5);
          request.endingTime = time_range_conflict[1]._d
            .toTimeString()
            .slice(0, 5);
        } else {
          request.startTime = time_range[0]._d.toTimeString().slice(0, 5);
          request.endingTime = time_range[1]._d.toTimeString().slice(0, 5);
        }
        const res = await cresteBookings(request);

        if (res.ok) {
          bookings.push(res);
        }
      }
    } else {
      for (let i = 0; i < validDays.length; i++) {
        const date =
          validDays[i].length === 18
            ? `${validDays[i].slice(0, 8)}0${validDays[i].slice(8, 9)}`
            : validDays[i].slice(0, 10);
        request.date = date;
        request.startTime = time_range[0]._d.toTimeString().slice(0, 5);
        request.endingTime = time_range[1]._d.toTimeString().slice(0, 5);
        const res = await cresteBookings(request);

        if (res.ok) {
          bookings.push(res);
        } else {
          bookingsConflict.push(res);
        }
      }
    }
    if (bookings.length >= 1) {
      const bookingsConfirmed = bookings
        .map((item) => {
          return `el dia  ${item.date.slice(0, 10)}, desde ${
            item.startTime
          } hasta ${item.endingTime}, `;
        })
        .join(' , ');
      message.success(
        `Se ha generado exitosamente la reserva ${bookingsConfirmed} `
      );

      navigate(-1);
    }
    if (bookingsConflict.length >= 1) {
      message.error('Exite un Error verifica las reservas ');
    }
    onReset();
  };

  const handleSearch = async (newValue) => {
    if (newValue) {
      const res = await searchBooker(newValue);
      if (res) {
        const response = await res.json();

        if (response.length === 0 && newValue.length > 7) {
          const residents = [{ value: newValue, text: newValue }];
          setResidents(residents);
        } else {
          const residents = response.map((item) => {
            return { value: item.id, text: item.fullName };
          });
          setResidents(residents);
        }
      } else {
        setResidents([]);
      }
    } else {
      setResidents([]);
    }
  };
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const options = residents.map((d) => <Option key={d.value}>{d.text}</Option>);
  const changeSwitchNewHour = () => {
    addNewDate ? setAddNewDate(false) : setAddNewDate(true);
  };

  const topBarProps = {
    icon: screens.xs === true ? '' : <IoCalendarOutline size={40} />,
  };

  if (loading === true) return <HttpSpinner />;

  let titleResponsive = '';
  if (screens.xs === true) titleResponsive = 'Solicitud Reserva';
  else titleResponsive = 'Solicitud de Reserva';

  return (
    <>
      <TopBarIcon text={titleResponsive} {...topBarProps} />
      <NewBookingContainer>
        <Form
          onFinish={onFinish}
          autoComplete='off'
          size='large'
          autoSave='on'
          form={form}
        >
          <Row justify='center' gutter={8}>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              md={{ span: 11 }}
              lg={{ span: 9 }}
            >
              <Item
                name={'asset'}
                label='Seleccione lugar a reservar'
                tooltip='Por favor selecciona el lugar que deseas reservar.'
                rules={formRules.place}
                style={{
                  display: 'block',
                }}
              >
                <Select
                  placeholder='Seleccionar...'
                  onChange={selectedPlace}
                  allowClear
                >
                  {jointAsset.map((item) => (
                    <Option value={item.name} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Item>

              {daysOfAttentionToThePublic.length >= 1 && (
                <CareDaysAseet>
                  <span>{'Días y horario de atención :'}</span>
                  <CareDayAseetList>
                    {daysOfAttentionToThePublic.map((e, i) => (
                      <li key={i}>{e}</li>
                    ))}
                  </CareDayAseetList>
                </CareDaysAseet>
              )}
            </Col>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              md={{ span: 11 }}
              lg={{ span: 9 }}
            >
              <ConfigProvider locale={es_ES}>
                <Item
                  name={'date_range'}
                  label='Fecha'
                  tooltip='Por favor selecciona la fecha de inicio y fin.'
                  rules={formRules.date_range}
                  style={{
                    display: 'block',
                  }}
                >
                  <DatePickerStyled
                    placeholder={['Fecha inicio', 'Fecha fin']}
                  />
                </Item>
              </ConfigProvider>
            </Col>
          </Row>
          <Row justify='center' gutter={8}>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              md={{ span: 11 }}
              lg={{ span: 9 }}
            >
              <ConfigProvider locale={es_ES}>
                <Item
                  name={'time_range'}
                  label='Hora'
                  tooltip='Por favor selecciona la hora de inicio y finalización.'
                  rules={formRules.time_range}
                  style={{
                    display: 'block',
                  }}
                >
                  <RangePickerStyled
                    use12Hours
                    placeholder={['Hora Inicio', 'Hora fin']}
                  />
                </Item>
              </ConfigProvider>
              {/* AQUI REALIZAR VALIDACION EN CASO DE CONFLICTO  */}
              {validateValues.scheduleAvailable && (
                <>
                <span>
                  {`No es posible agendar en la fecha ${validateValues.scheduleAvailable.date.slice(
                    0,
                    10
                  )} , en el horario  ${moment(
                    validateValues.scheduleAvailable.schedule.startTime,
                    'h:mm a'
                  ).format('h:mm a')} a ${moment(
                    validateValues.scheduleAvailable.schedule.endingTime,
                    'h:mm a'
                  ).format('h:mm a')}, Puedes agendar en los siguientes horarios  `}
                     </span><br />
                     <CareDayAseetList>
                     {validateValues.scheduleAvailable.scheduleAvailable?.map((item, i) => (
                      <li key={i}>{`entre las ${moment(
                        item.startTime,
                        'h:mm a'
                      ).format('h:mm a')} y las ${moment(
                        item.endingTime,
                        'h:mm a'
                      ).format('h:mm a')}`}</li>
                    ))}
                     </CareDayAseetList>
                     <span>¿Desea cambiar el rango de hora en la fecha mencionada?</span>
                  <Switch
                    checked={addNewDate}
                    onChange={changeSwitchNewHour}
                    checkedChildren='Si'
                    unCheckedChildren='No'
                  />
                  <br />

                  <b>{`Recuerda que si tu selección es NO ${validDays > 1 ? 'los días' : 'el día'} se omitirán en la
                    reservación.`}
                  </b>
                </>
              )}

              {addNewDate && (
                <ConfigProvider locale={es_ES}>
                  <Item
                    name={'time_range_conflict'}
                    label='Hora'
                    tooltip='Por favor selecciona la hora de inicio y finalización.'
                    style={{
                      display: 'block',
                    }}
                  >
                    <RangePickerStyled
                      use12Hours
                      placeholder={['Hora Inicio', 'Hora fin']}
                    />
                  </Item>
                </ConfigProvider>
              )}
            </Col>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              md={{ span: 11 }}
              lg={{ span: 9 }}
            >
              <Item
                name={'attendees'}
                label='Cantidad de personas'
                tooltip='Por favor selecciona la cantidad exacta de personas.'
                rules={formRules.capacity}
                style={{
                  display: 'block',
                }}
              >
                <InputNumberCustom min={1} max={9999} placeholder='1' />
              </Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 18 }}
            >
              <Item
                name='user'
                label={
                  role === 'Optimus-Administrador' ||
                  role === 'Optimus-Operador'
                    ? 'Busca o escribe el nombre de la persona a quién se le aginará la reserva.'
                    : 'Nombre de la persona a quien se le asignará la reserva'
                }
                tooltip='Por favor escribe el nombre de quien reserva'
                rules={formRules.bookerName}
                style={{
                  display: 'block',
                }}
                hasFeedback
                required
              >
                {ADMINISTRATIVEROLES.includes(role) ? (
                  <Select
                    showSearch
                    value={value}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    notFoundContent={null}
                    placeholder='Nombre de la persona a quien se le asignará la reserva.'
                  >
                    {options}
                  </Select>
                ) : (
                  <Input placeholder='Nombre de la persona a quien se le asignará la reserva.' />
                )}
              </Item>
            </Col>
          </Row>

          <Row justify='center'>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 18 }}
            >
              <Item
                name={'description'}
                label='Descripción'
                tooltip='Por favor escribe el motivo de tú reserva.'
                hasFeedback
                style={{
                  display: 'block',
                }}
              >
                <TextArea
                  rows={4}
                  placeholder='Por favor escribe aquí.'
                  style={{ resize: 'none' }}
                  maxLength={250}
                />
              </Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col xs={{ span: 22 }} sm={{ span: 18 }}>
              <CardWrap id='myWrap'>
                <Card title='RECOMENDACIONES' style={{ textAlign: 'center' }}>
                  {asset ? asset.description : 'Ninguno'}
                </Card>
              </CardWrap>
            </Col>
          </Row>
          <Row justify='center'>
            <Col
              xs={{ span: 22 }}
              sm={{ span: 16 }}
              md={{ span: 12 }}
              xl={{ span: 8 }}
            >
              <ButtonStyled
                type='primary'
                htmlType='submit'
                size='large'
                block
                onClick={null}
              >
                Confirmar reserva
              </ButtonStyled>
            </Col>
          </Row>
        </Form>
      </NewBookingContainer>
    </>
  );
}

export default NewBooking;
