import { deleteUser, verifyUser } from 'redux/index';
import { openNotification } from 'helpers/notifications';
import { ERROR, SUCCESS } from 'constants';

export const notifyUserElimination = async (id, token, pagination, cb) => {
  const res = await deleteUser(id, token);
  if (res == null) {
    openNotification(
      ERROR,
      'Eliminación fallida, Por favor recarga la página e intenta nuevamente.'
    );
    return;
  }
  openNotification(SUCCESS, 'Eliminación satisfactoria.');
  cb(pagination);
};

export const notifyUserVerification = async (id, token, pagination, cb) => {
  const res = await verifyUser(id, token);
  if (res == null) {
    openNotification(
      ERROR,
      'Verificación fallida, Por favor recarga la página e intenta nuevamente.'
    );
    return;
  }
  openNotification(SUCCESS, 'Verificación satisfactoria.');
  cb(pagination);
};
