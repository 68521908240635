import PropTypes from 'prop-types';
import { useState } from 'react';
import { Tooltip, message } from 'antd';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { IoCopyOutline } from 'react-icons/io5';
import { UserModal, ActionsWrap } from 'components';
import { useNavigate } from 'react-router-dom';
import { confirmationModal } from 'helpers/confirmations';
import { deleteLink } from 'redux/index';
import LinksForm from '../form/LinksForm';

function Actions({ link, token, theme, fetchLinks }) {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dynamicLink = `${process.env.REACT_APP_HOST}/registro/${link.id}`;

  const toggleModal = () => {
    setIsModalVisible((preValue) => {
      const newStatus = !preValue;
      return newStatus;
    });
  };

  const onWatchLink = () => navigate(`/registro/${link.id}`);

  const onDelete = async () => {
    await deleteLink(link.id, token);
    fetchLinks();
  };

  const iconsStyles = (color, size) => {
    return { color: color, fontSize: size };
  };

  const handleCopyInClipboard = () =>
    navigator.permissions.query({ name: 'clipboard-write' }).then(
      (result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard.writeText(dynamicLink).then(
            () => {
              message.success('¡Enlace Copiado!');
            },
            () => {
              message.error(
                'Error al copiar el enlace, Por favor verifica que tu navegador tiene permisos para ejecutar esta operación.'
              );
            }
          );
        }
      },
      () => {
        message.error(
          'Acceso para copiar denegado, Por favor verifica y actívalo en tu navegador.'
        );
      }
    );

  return (
    <>
      <UserModal
        title='Editar Enlace'
        isModalVisible={isModalVisible}
        handleSaveModal={toggleModal}
        handleCancelModal={toggleModal}
        saveText={''}
        width='60rem'
      >
        <LinksForm
          token={token}
          fetchLinks={fetchLinks}
          projectId={link.id}
          isEdition={true}
          linkId={link.id}
        />
      </UserModal>

      <ActionsWrap>
        <Tooltip title='Copiar Enlace'>
          <span>
            <IoCopyOutline
              onClick={handleCopyInClipboard}
              style={{
                ...iconsStyles(theme.colors.grayTwo, '1.9rem'),
                cursor: 'pointer',
              }}
            />
          </span>
        </Tooltip>

        <Tooltip title='Ver'>
          <EyeOutlined
            onClick={onWatchLink}
            style={iconsStyles(theme.colors.limeSix, '2rem')}
          />
        </Tooltip>

        <Tooltip title='Editar Enlace'>
          <EditOutlined
            onClick={toggleModal}
            style={iconsStyles(theme.colors.yellowSix, '2rem')}
          />
        </Tooltip>
        <Tooltip title='Eliminar Enlace'>
          <DeleteOutlined
            onClick={() =>
              confirmationModal(
                null, // title
                null, // content
                null, // okText
                null, // okType
                null, // cancelText
                null, // Icon
                onDelete // callback
              )
            }
            style={{
              ...iconsStyles(theme.colors.redFive, '1.9rem'),
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </ActionsWrap>
    </>
  );
}
Actions.propTypes = {
  link: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  fetchLinks: PropTypes.func.isRequired,
};
export default Actions;
