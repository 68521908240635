export const days = [
    {
      day: "Lunes",
      startTime: "00:00",
      endingTime: "00:00",
    },
    {
      day: "Martes",
      startTime: "00:00",
      endingTime: "00:00",
    },
    {
      day: "Miércoles",
      startTime: "00:00",
      endingTime: "00:00",
    },
    {
      day: "Jueves",
      startTime: "00:00",
      endingTime: "00:00",
    },
    {
      day: "Viernes",
      startTime: "00:00",
      endingTime: "00:00",
    },
    {
      day: "Sábado",
      startTime: "00:00",
      endingTime: "00:00",
    },
    {
      day: "Domingo",
      startTime: "00:00",
      endingTime: "00:00",
    },
  ];