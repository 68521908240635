import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IconStyled } from '../Wraps';
import {
  DownloadOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { confirmationModal } from 'helpers/confirmations';
import { ActionsWrap } from './Wraps';
import {
  downloadErrorLogs,
  downloadFileUploaded,
  sendMassiveEmails,
} from 'redux/index';
import { ERROR, SUCCESS } from 'constants';
import { openNotification } from 'helpers/notifications';
import { readAndDonwloadFile } from 'helpers/file-reader';
import { useTheme } from 'styled-components';

function Actions({ log, onElimination, token }) {
  const theme = useTheme();
  const [isMakingLogReq, setIsMakingLogReq] = useState(false);
  const [isMakingDocReq, setIsMakingDocReq] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const blue = { color: theme.colors.blueSix };
  const green = { color: theme.colors.primaryNeon };
  const red = { color: theme.colors.redFive };
  const mail = {
    color: theme.colors.yellowSix,
  };
  const deletingParams = [
    null,
    null,
    null,
    null,
    null,
    null,
    () => onElimination(log.id),
  ];

  const handleLogDownload = async () => {
    setIsMakingLogReq(true);
    const res = await downloadErrorLogs(log.id, token);
    setIsMakingLogReq(false);
    if (res == null) {
      openNotification(
        ERROR,
        'Error de descarga, por favor vuelve a intentar.',
        IoCloudDownloadOutline
      );
      return;
    }

    openNotification(
      SUCCESS,
      'Registro descargado exitosamente.',
      IoCloudDownloadOutline
    );
    readAndDonwloadFile(res);
  };
  const handleFileDownload = async () => {
    setIsMakingDocReq(true);
    const res = await downloadFileUploaded(log.id, token);
    setIsMakingDocReq(false);
    if (res == null) {
      openNotification(
        ERROR,
        'Error de descarga, por favor vuelve a intentar.',
        IoCloudDownloadOutline
      );
      return;
    }
    openNotification(
      SUCCESS,
      'Documento descargado exitosamente.',
      IoCloudDownloadOutline
    );
    readAndDonwloadFile(res);
  };

  const handleEmailSends = async () => {
    setIsSendingEmail(true);
    openNotification(
      SUCCESS,
      'El envío de emails se está ejecutando en segundo plano, recuerda que este proceso puede tardar algunos minutos.'
    );
    const res = await sendMassiveEmails(log.id, token);
    setIsSendingEmail(false);
    if (res === false) {
      openNotification(
        ERROR,
        'Error fatal, fue imposible enviar los correos, por favor comunícate con soporte.',
        MailOutlined
      );
      return;
    }
    if (res?.statusCode === 400 || res?.statusCode === 404) {
      openNotification(ERROR, res.message);
      return;
    }
    openNotification(SUCCESS, res.message, MailOutlined);
  };

  return (
    <ActionsWrap>
      {isMakingLogReq === true ? (
        <LoadingOutlined style={blue} />
      ) : (
        <Tooltip title='Descargar registro de errores'>
          <IconStyled onClick={handleLogDownload}>
            <DownloadOutlined style={blue} />
          </IconStyled>
        </Tooltip>
      )}
      {isMakingDocReq === true ? (
        <LoadingOutlined style={green} />
      ) : (
        <Tooltip title='Descargar archivo cargado'>
          <IconStyled onClick={handleFileDownload}>
            <FileExcelOutlined style={green} />
          </IconStyled>
        </Tooltip>
      )}
      {isSendingEmail === true ? (
        <LoadingOutlined style={green} />
      ) : (
        <Tooltip title='Envíar correos de verificación'>
          <IconStyled onClick={handleEmailSends}>
            <MailOutlined style={mail} />
          </IconStyled>
        </Tooltip>
      )}

      <Tooltip title='Eliminar Carga'>
        <IconStyled onClick={() => confirmationModal(...deletingParams)}>
          <DeleteOutlined style={red} />
        </IconStyled>
      </Tooltip>
    </ActionsWrap>
  );
}

Actions.propTypes = {
  log: PropTypes.object.isRequired,
  onElimination: PropTypes.func.isRequired,
};

export default Actions;
