import styled from 'styled-components';
import { List } from 'antd';

export const ListItemStyled = styled(List.Item)`
  transition: all 300ms ease-in;
  & .item-info {
    font-weight: 500;
  }
  & .item-acctions {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    & .edition,
    & .elimination {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
