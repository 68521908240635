import { FloatBackground } from 'components';
import { ExpirationLinkWrap, ResultStyled } from '../successful-register/Wraps';

function LinkExpired() {
  return (
    <ExpirationLinkWrap>
      <FloatBackground />
      <ResultStyled
        status='403'
        title='¡Enlace Expirado!'
        subTitle='Lo sentimos, el enlace ya expiró.'
      />
    </ExpirationLinkWrap>
  );
}

export default LinkExpired;
