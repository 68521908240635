import { useState, useRef, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import {
  IoCarSportSharp,
  IoPawSharp,
  IoCloudUploadSharp,
} from 'react-icons/io5';
import ImgCrop from 'antd-img-crop';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Upload,
  Select,
  Button,
  Grid,
} from 'antd';
import { IoPeopleOutline } from 'react-icons/io5';
import { UserCreationContainer, FormItemStyled, VePeContainer } from './Wraps';
import {
  TopBarIcon,
  TagsContainer,
  TagStyled,
  ButtonStyled,
  ButtonLightStyled,
  ModalStyled,
  HttpSpinner,
} from 'components';
import PetForm from './pet';
import VehicleForm from './vehicle';
import { onPreview } from 'helpers/file-reader';
import { openNotification } from 'helpers/notifications';
import { preventDefault } from 'helpers/forms';
import {
  generateRandomId,
  generateCapitalLetter,
  mapShippingPhone,
  mapShippingMembers,
  mapShippingPets,
  mapShippingVisits,
  mapShippingVehicles,
  mapShippingDateOfBirth,
  hanldeShippingValidation,
  prefixSelector,
} from './helpers';
import { userRules, imgAccept, genders } from './form-rules';
import {
  uploadUserImageToS3,
  selectIsAuthenticated,
  createUser,
  getResidentialId,
} from 'redux/index';
import ListData from './modal-content';
import { ERROR, RESIDENT_MODULE, SUCCESS } from 'constants';

function CreateUser({ isPublicPage = null, onCustomSubmit = null }) {
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();
  const memberInputRef = useRef(null);
  const visitInputRef = useRef(null);
  const token = useSelector(selectIsAuthenticated);
  const projectId = useSelector(getResidentialId);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [members, setMembers] = useState([]);
  const [visists, setVisists] = useState([]);
  const [pets, setPets] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [isItemsModalVisible, setIsItemsModalVisible] = useState(false);
  const [isVehicleModalVisible, setIsVehicleModalVisible] = useState(false);
  const [isPetModalVisible, setIsPetModalVisible] = useState(false);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [currentList, setCurrentList] = useState('Vehiculos');
  const [editionItem, setEditionItem] = useState(null);
  const props = {
    name: 'dragger',
    limit: 1,
    multiple: false,
    maxCount: 1,
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const whiteList = ['image/jpeg', 'image/png'];
      if (!whiteList.includes(file.type)) return;
      if (fileList.length > 0) return;
      if (file.size / (1024 * 1024) > 1) {
        openNotification('error', 'La imagen debe ser menor a una 1 mega.');
        return;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    form.setFieldsValue({ prefix: '57' });
  }, [form]);

  const showItemsModal = (type) => {
    setCurrentList(type);
    setIsItemsModalVisible(true);
  };
  const handleItemModalCancel = () => setIsItemsModalVisible(false);

  const handleOpenVehicleModal = () => setIsVehicleModalVisible(true);
  const handleCloseVehicle = () => {
    setIsVehicleModalVisible(false);
    setEditionItem(null);
  };
  const handleOpenPetModal = () => setIsPetModalVisible(true);
  const handleClosePet = () => {
    setIsPetModalVisible(false);
    setEditionItem(null);
  };

  // Set Vehicles
  const handleVehicles = (vehicles) => {
    setVehicles((preValue) => [
      {
        id: generateRandomId(),
        ...vehicles,
      },
      ...preValue,
    ]);
    handleCloseVehicle();
  };
  const handleVehiclesEdition = (newVehicle) => {
    const updatedVehicles = vehicles.filter(
      (vehicle) => vehicle.id !== newVehicle.id
    );
    updatedVehicles.push(newVehicle);
    setVehicles(updatedVehicles);
    setEditionItem(null);
    handleCloseVehicle();
  };
  // Set pets
  const handlePets = (pets) => {
    setPets((preValue) => [{ id: generateRandomId(), ...pets }, ...preValue]);
    handleClosePet();
  };
  const handlePetsEdition = (newPet) => {
    const updatedPets = pets.filter((pets) => pets.id !== newPet.id);
    updatedPets.push(newPet);
    setPets(updatedPets);
    setEditionItem(null);
    handleClosePet();
  };

  // Edition
  const handleItemEdition = (item, type) => {
    handleItemModalCancel();
    if (type === 'vehiculos') handleOpenVehicleModal();
    else handleOpenPetModal();
    setEditionItem(item);
  };
  const handleItemElimination = (type, newData) => {
    if (type === 'vehiculos') setVehicles(newData);
    else setPets(newData);
  };

  // Member Handlers
  const handleMembers = () => {
    const memberValue = memberInputRef.current.input.value.trim();
    const cleanField = () => {
      form.setFieldsValue({
        members: '',
      });
    };
    if (memberValue.length < 6) {
      cleanField();
      return;
    }
    cleanField();
    const mappedValue = generateCapitalLetter(memberValue);
    setMembers((preValue) => [
      {
        id: generateRandomId(),
        value: mappedValue,
      },
      ...preValue,
    ]);
  };
  const handleDeleteMember = (id) => {
    setMembers((preValue) => {
      return preValue.filter((member) => member.id !== id);
    });
  };
  // Frequent visit Handlers
  const handleFrequentVisits = () => {
    const frequentVisit = visitInputRef.current.input.value.trim();
    const cleanField = () => {
      form.setFieldsValue({
        frequentVisits: '',
      });
    };
    if (frequentVisit.length < 6) {
      cleanField();
      return;
    }
    cleanField();
    const mappedValue = generateCapitalLetter(frequentVisit);
    setVisists((preValue) => [
      {
        id: generateRandomId(),
        value: mappedValue,
      },
      ...preValue,
    ]);
  };
  const handleDeleteFrequentVisit = (id) => {
    setVisists((preValue) => {
      return preValue.filter((visit) => visit.id !== id);
    });
  };

  const onReset = () => form.resetFields();

  // Submit
  const onFinish = async (data) => {
    const formData = { ...data };
    let payload = {};

    // validation
    const isValid = hanldeShippingValidation(formData);

    if (!isValid) return;

    // Mappers
    if (members.length > 0) payload['members'] = mapShippingMembers(members);

    if (pets.length > 0) payload['pets'] = mapShippingPets(pets);

    if (visists.length > 0)
      payload['frequentVisits'] = mapShippingVisits(visists);

    if (vehicles.length > 0)
      payload['vehicles'] = mapShippingVehicles(vehicles);

    if (formData.parking) payload['parkingNumber'] = formData.parking;

    if (formData.apartmentNumber)
      payload['apartmentNumber'] = formData.apartmentNumber;

    if (formData.gender) payload['gender'] = formData.gender;

    if (formData.dayOfBirthday)
      payload['dateOfBirth'] = mapShippingDateOfBirth(formData.dayOfBirthday);

    if (formData.address) payload['address'] = formData.address;

    // Upload Image to s3
    setIsMakingRequest(true);
    if (fileList.length > 0) {
      const iFormData = new FormData();
      iFormData.append('user-img', fileList[0]);
      const userImage = await uploadUserImageToS3(iFormData, token);
      if (userImage == null) {
        openNotification(
          ERROR,
          'Carga de imagen fallida, Por favor verifica que la imagen sea png, jpeg or jpg con un tamaño menor a 1 mega.'
        );
        setIsMakingRequest(false);
        return;
      }
      payload['image'] = userImage;
    }

    payload = {
      ...payload,
      userName: formData.userName,
      fullName: formData.fullName,
      email: formData.email.trim().toLowerCase(),
      password: generateRandomId(),
      phoneNumber: mapShippingPhone(formData.prefix, formData.cellphone),
      role: RESIDENT_MODULE,
      projectId,
      status: false,
    };

    // redirect the flow
    if (isPublicPage === true) {
      return onCustomSubmit(payload, onReset);
    }

    const user = await createUser(payload, token);
    setIsMakingRequest(false);
    if (user == null) {
      openNotification(
        ERROR,
        'Creación fallida, Por favor verifica el correo, telefono,y nombre de usuario e intenta nuevamente.'
      );
      return;
    }

    onReset();
    if (!isPublicPage) {
      openNotification(SUCCESS, 'El usuario fue creado exitosamente.');
      navigate(-1);
      return;
    }
  };

  let titleResponsive = '';
  if (screens.xs === true) titleResponsive = 'C. Usuario';
  else titleResponsive = 'Creación de Usuario';

  const containerProps = {};
  if (!isPublicPage) containerProps.marginCalc = '2rem';

  const blockItem = {
    display: 'block',
  };
  return (
    <>
      {isMakingRequest && !isPublicPage && <HttpSpinner />}

      <ModalStyled
        title={currentList === 'Vehiculos' ? 'Vehículos' : currentList}
        visible={isItemsModalVisible}
        onCancel={handleItemModalCancel}
        footer={null}
      >
        {currentList.toLocaleLowerCase() === 'vehiculos' ? (
          <ListData
            items={vehicles}
            type={currentList}
            onEdition={handleItemEdition}
            onElimination={handleItemElimination}
          />
        ) : (
          <ListData
            items={pets}
            type={currentList}
            onEdition={handleItemEdition}
            onElimination={handleItemElimination}
          />
        )}
      </ModalStyled>

      <ModalStyled
        title='Información del vehículo'
        visible={isVehicleModalVisible}
        onCancel={handleCloseVehicle}
        footer={null}
      >
        <VehicleForm
          edition={editionItem}
          onVehicles={handleVehicles}
          onEdition={handleVehiclesEdition}
        />
      </ModalStyled>

      <ModalStyled
        title='Información de la mascota'
        visible={isPetModalVisible}
        onCancel={handleClosePet}
        footer={null}
      >
        <PetForm
          edition={editionItem}
          onPets={handlePets}
          onEdition={handlePetsEdition}
        />
      </ModalStyled>

      {!isPublicPage && (
        <TopBarIcon
          text={titleResponsive}
          icon={<IoPeopleOutline size={40} />}
        />
      )}

      <UserCreationContainer {...containerProps}>
        <Row justify='center'>
          <Col xs={{ span: 22 }} md={{ span: 18 }} xl={{ span: 16 }}>
            <Form
              size='large'
              onFinish={onFinish}
              autoComplete='off'
              form={form}
            >
              <Row justify='center' gutter={[{ lg: 48 }, { xs: 16, lg: 16 }]}>
                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <Form.Item
                    label='Nombre completo'
                    name='fullName'
                    required
                    tooltip='Por favor escribe los nombres y apellidos de la persona.'
                    rules={userRules.fullName}
                    hasFeedback
                    style={blockItem}
                  >
                    <Input
                      placeholder='Nombres'
                      onPressEnter={preventDefault}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Dirección del usuario'
                    name='address'
                    tooltip='Por favor, Escribe la dirección de la persona a agregar.'
                    rules={userRules.address}
                    hasFeedback
                    style={blockItem}
                  >
                    <Input
                      placeholder='Nombre de usuario'
                      onPressEnter={preventDefault}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Fecha de nacimiento'
                    name='dayOfBirthday'
                    tooltip='Por favor seleccionar la fecha de nacimiento.'
                    hasFeedback
                    rules={userRules.dayOfBirthday}
                    style={blockItem}
                  >
                    <DatePicker
                      placeholder='Fecha'
                      style={{
                        display: 'block',
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Sexo'
                    name='gender'
                    tooltip='Por favor seleccionar el tipo de sexo.'
                    hasFeedback
                    style={blockItem}
                    rules={userRules.gender}
                  >
                    <Select placeholder='Seleccionar...'>
                      {genders.map((g) => (
                        <Select.Option value={g.key} key={g.key}>
                          {g.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label='Imagen'
                    tooltip='Selecciona una imagen de tipo png, jpeg o jpg.'
                    style={blockItem}
                  >
                    <ImgCrop rotate aspect={1}>
                      <Upload
                        {...props}
                        onPreview={onPreview}
                        accept={imgAccept}
                      >
                        <Button type='primary'>
                          <span>Cargar Imagen</span>
                          <UploadOutlined />
                        </Button>
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </Col>

                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <Form.Item
                    label='Nombre de usuario'
                    name='userName'
                    required
                    tooltip='Por favor, Escribe el nombre de usuario, por ejemplo: carlos-mario11'
                    rules={userRules.userName}
                    hasFeedback
                    style={blockItem}
                  >
                    <Input
                      placeholder='Nombre de usuario'
                      onPressEnter={preventDefault}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Email del usuario'
                    name='email'
                    required
                    tooltip='Por favor, Escribe el correo electronico del usuario a crear, recuerda que en este correo será enviado un correo de verificación para validar la cuenta.'
                    rules={userRules.email}
                    hasFeedback
                    style={blockItem}
                  >
                    <Input
                      placeholder='Email usuario'
                      onPressEnter={preventDefault}
                    />
                  </Form.Item>

                  <Form.Item
                    tooltip='Por favor ingresa un número celular, recuerda que este debe contener al menos 10 dígitos numéricos.'
                    name='cellphone'
                    label='Número celular'
                    rules={userRules.cellphone}
                    style={blockItem}
                    hasFeedback
                  >
                    <Input
                      addonBefore={prefixSelector}
                      placeholder='3122343578'
                      onPressEnter={preventDefault}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Área/Apartamento'
                    name='apartmentNumber'
                    hasFeedback
                    tooltip='Por favor escribe el Área/Número de apartamento según corresponda.'
                    style={blockItem}
                    rules={userRules.apartmentNumber}
                  >
                    <Input placeholder='A-1' onPressEnter={preventDefault} />
                  </Form.Item>

                  <Form.Item
                    label='Número de parqueadero'
                    name='parking'
                    hasFeedback
                    tooltip='Por favor escribe la identificación del lugar de aparcamiento.'
                    style={blockItem}
                    rules={userRules.parking}
                  >
                    <Input placeholder='B-2' onPressEnter={preventDefault} />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <FormItemStyled
                    label='Personas con las que vive'
                    tooltip='Por favor escribe los nombre y apellidos de las personas con las que vive.'
                    style={blockItem}
                  >
                    <Form.Item
                      name='members'
                      hasFeedback
                      rules={userRules.members}
                      noStyle
                    >
                      <Input
                        placeholder='Nombre del miembro'
                        ref={memberInputRef}
                        onPressEnter={preventDefault}
                      />
                    </Form.Item>
                    <Button
                      type='primary'
                      className='add-member'
                      onClick={handleMembers}
                    >
                      <BsFillPersonPlusFill size={22} />
                    </Button>
                  </FormItemStyled>

                  <TagsContainer>
                    {members.map((member) => (
                      <TagStyled
                        key={member.id}
                        closable
                        onClose={() => handleDeleteMember(member.id)}
                      >
                        {member.value}
                      </TagStyled>
                    ))}
                  </TagsContainer>
                </Col>

                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <FormItemStyled
                    label='Visitas frecuentes'
                    tooltip='Por favor escribe el nombre completo del visitante.'
                    style={blockItem}
                  >
                    <Form.Item
                      name='frequentVisits'
                      hasFeedback
                      rules={userRules.frequentVisits}
                      noStyle
                    >
                      <Input
                        placeholder='Nombre del visitante'
                        ref={visitInputRef}
                        onPressEnter={preventDefault}
                      />
                    </Form.Item>
                    <Button
                      type='primary'
                      className='add-member'
                      onClick={handleFrequentVisits}
                    >
                      <BsFillPersonPlusFill size={22} />
                    </Button>
                  </FormItemStyled>

                  <TagsContainer>
                    {visists.map((visit) => (
                      <TagStyled
                        key={visit.id}
                        closable
                        onClose={() => handleDeleteFrequentVisit(visit.id)}
                      >
                        {visit.value}
                      </TagStyled>
                    ))}
                  </TagsContainer>
                </Col>

                {/* <Col xs={{ span: 22 }}></Col> */}

                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <VePeContainer>
                    <span>
                      Total Vehículos: <strong>{vehicles.length}</strong>
                    </span>
                    <Button
                      type='primary'
                      size='middle'
                      onClick={() => showItemsModal('Vehiculos')}
                    >
                      <span>Ver Vehículos</span>
                      <span>
                        <IoCarSportSharp size={18} />
                      </span>
                    </Button>
                  </VePeContainer>
                </Col>
                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <VePeContainer>
                    <span>
                      Total Mascotas: <strong>{pets.length}</strong>
                    </span>
                    <Button
                      type='primary'
                      size='middle'
                      onClick={() => showItemsModal('Mascotas')}
                    >
                      <span>Ver Mascotas</span>
                      <span>
                        <IoPawSharp size={18} />
                      </span>
                    </Button>
                  </VePeContainer>
                </Col>

                {/* Add vehicle here */}
                <Col xs={{ span: 22 }} sm={{ span: 16 }} md={{ span: 9 }}>
                  <ButtonLightStyled
                    size='large'
                    block
                    htmlType='button'
                    onClick={handleOpenVehicleModal}
                  >
                    Agregar Vehículo
                  </ButtonLightStyled>
                </Col>

                {/* Add pet here */}
                <Col xs={{ span: 22 }} sm={{ span: 16 }} md={{ span: 9 }}>
                  <ButtonLightStyled
                    size='large'
                    block
                    htmlType='button'
                    onClick={handleOpenPetModal}
                  >
                    Agregar Mascota
                  </ButtonLightStyled>
                </Col>

                <Col
                  xs={{ span: 22 }}
                  sm={{ span: 16 }}
                  md={{ span: 11 }}
                  lg={{ span: 8 }}
                >
                  <ButtonStyled
                    type='primary'
                    size='large'
                    block
                    htmlType='submit'
                  >
                    Crear
                    <IoCloudUploadSharp size={20} />
                  </ButtonStyled>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </UserCreationContainer>
    </>
  );
}
export default CreateUser;
