import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IconStyled, ActionsWrap } from 'components';
import {
  EditOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { confirmationModal, executeAndUpdateTableRecords } from 'helpers';
import { verifyUser, updateUserData } from 'redux/index';

function Actions({
  user,
  onElimination,
  theme,
  pagination,
  users,
  fetchUsers,
}) {
  const navigate = useNavigate();
  const handleNavigate = () => navigate(`${user.profiles[0].id}/editar`);
  const getColor = (color) => theme.colors[color];

  const handleEmailVerification = async () => {
    await executeAndUpdateTableRecords(
      users,
      () =>
        verifyUser({
          userId: user.id,
          payload: { status: user.status === true ? false : true },
        }),
      pagination,
      {
        failed:
          'Verificación fallida, Por favor recarga la página e intenta nuevamente.',
        success:
          user.status === true
            ? 'Email anulado exitosamente.'
            : 'Email verificado exitosamente.',
      },
      fetchUsers,
      MailOutlined
    );
  };

  const emailVerifyOptions = [
    user.status === true
      ? '¿Está seguro de Anular la verificación del email?'
      : '¿Está seguro de Verificar el email del usuario?',
    user.status === true
      ? 'Por favor, Recuerda que un usuario con email sin verificar no podrá iniciar sesión.'
      : 'Por favor, Recuerda que verificar el email significa que apruebas la veracidad y existencia de este.',
    user.status === true ? 'Anular' : 'Verificar',
    user.status === true ? 'danger' : 'primary',
    null,
    <MailOutlined style={{ color: getColor('limeSix') }} />,
    () => handleEmailVerification(),
  ];

  const handleuserVerification = async () => {
    await executeAndUpdateTableRecords(
      users,
      () =>
        updateUserData({
          id: user.id,
          payload: { userStatus: 'activo' },
        }),
      pagination,
      {
        failed:
          'Verificación fallida, Por favor recarga la página e intenta nuevamente.',
        success: 'Verificación de usuario ejecutada exitosamente.',
      },
      fetchUsers,
      MailOutlined
    );
  };

  const verifyUserOptions = [
    '¿Está seguro de Verificar al usuario?',
    'Por favor, Recuerda que esto significa que los datos del usuario son verdaderos, por lo tanto, se prosigue con la verificación o activación en el sistema.',
    'Verificar',
    'primary',
    null,
    <CheckCircleOutlined style={{ color: getColor('primaryNeon') }} />,
    () => handleuserVerification(),
  ];

  const handleUserRejection = async () => {
    await executeAndUpdateTableRecords(
      users,
      () =>
        updateUserData({
          id: user.id,
          payload: { userStatus: 'rechazado' },
        }),
      pagination,
      {
        failed:
          'Rechazo fallido, Por favor recarga la página e intenta nuevamente.',
        success: 'Usuario rechazado exitosamente.',
      },
      fetchUsers,
      ExclamationCircleOutlined
    );
  };
  const rejectionOptions = [
    '¿Está seguro de Rechazar al usuario?',
    'Por favor, Recuerda que esto significa que los datos del usuario no son verdaderos, por lo tanto, se prosigue con el rechazo o desactivación en el sistema.',
    'Rechazar',
    'danger',
    null,
    <ExclamationCircleOutlined style={{ color: getColor('redFive') }} />,
    () => handleUserRejection(),
  ];

  const deletingParams = [
    null,
    null,
    null,
    null,
    null,
    <ExclamationCircleOutlined />,
    () => onElimination(user.id),
  ];

  return (
    <ActionsWrap>
      {user.userStatus === 'activo' ? (
        <Tooltip title='Rechazar/Desactivar Usuario'>
          <IconStyled onClick={() => confirmationModal(...rejectionOptions)}>
            <CloseCircleOutlined style={{ color: getColor('redFive') }} />
          </IconStyled>
        </Tooltip>
      ) : (
        <Tooltip title='Verificar/Activar Usuario'>
          <IconStyled onClick={() => confirmationModal(...verifyUserOptions)}>
            <CheckCircleOutlined style={{ color: getColor('primaryNeon') }} />
          </IconStyled>
        </Tooltip>
      )}

      <Tooltip title='Verificar/Anular Email'>
        <IconStyled onClick={() => confirmationModal(...emailVerifyOptions)}>
          <MailOutlined style={{ color: getColor('blueSix') }} />
        </IconStyled>
      </Tooltip>

      <Tooltip title='Editar Usuario'>
        <IconStyled onClick={handleNavigate}>
          <EditOutlined style={{ color: getColor('yellowSix') }} />
        </IconStyled>
      </Tooltip>

      <Tooltip title='Eliminar Usuario'>
        <IconStyled onClick={() => confirmationModal(...deletingParams)}>
          <DeleteOutlined style={{ color: getColor('redFive') }} />
        </IconStyled>
      </Tooltip>

      {/* {user.isVerified === false ? (
        <Tooltip title='Verificar Email'>
          <IconStyled onClick={() => confirmationModal(...confirmationParams)}>
            <CheckCircleOutlined style={{ color: getColor('limeSix') }} />
          </IconStyled>
        </Tooltip>
      ) : (
        <IconStyled style={{ cursor: 'not-allowed' }}>
          <CheckCircleOutlined style={{ color: 'gray' }} />
        </IconStyled>
      )} */}
    </ActionsWrap>
  );
}
Actions.propTypes = {
  user: PropTypes.object.isRequired,
  onElimination: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Actions;
