import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, message, Steps } from 'antd';
import {
  IoPersonSharp,
  IoShieldCheckmarkSharp,
  IoCheckmarkCircleSharp,
} from 'react-icons/io5';
import { SpaceYWrap, Title, ImgLogo } from './Wraps';
import { Logo, AutoScreenWrap, HttpSpinner } from 'components';
import {
  selectResetPasswordState,
  resetPasswordHttp,
  changePasswordHttp,
  cleanErroMessage,
  setReqStatus,
} from 'redux/index';
import { StepOneContent, StepTwoContent, StepThreeContent } from './Steps';

function PasswordReset() {
  const effectRan = useRef(false);
  const dispatch = useDispatch();
  const { step, stepError, errorMessage, isMakingRequest } = useSelector(
    selectResetPasswordState
  );
  const [form] = Form.useForm();
  const [formStepTwo] = Form.useForm();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (errorMessage) {
      dispatch(cleanErroMessage());
      message.error(errorMessage);
    }
    if (effectRan.current === false) {
      dispatch(setReqStatus({ status: false }));
      return () => {
        effectRan.current = true;
        dispatch(setReqStatus({ status: null }));
      };
    }
  }, [errorMessage, dispatch]);

  const onReset = useCallback(() => {
    form.resetFields();
    formStepTwo.resetFields();
    setFormData({});
  }, [form, formStepTwo]);

  const handleFinish = useCallback(
    (formData) => {
      setFormData(formData);
      dispatch(resetPasswordHttp({ userName: formData.email.trim() }));
    },
    [dispatch]
  );
  const handleFinishStepTwo = useCallback(
    (formData) => {
      const code = [
        formData.n1,
        formData.n2,
        formData.n3,
        formData.n4,
        formData.n5,
        formData.n6,
      ].join('');
      const payload = {
        code,
        userName: formData.email.trim(),
        password: formData.password.trim(),
        confirmation: formData.confirmation.trim(),
      };
      dispatch(changePasswordHttp(payload));
    },
    [dispatch]
  );

  const steps = useMemo(
    () => [
      {
        title: 'Ingresar Email',
        content: <StepOneContent form={form} onFinish={handleFinish} />,
        icon: <IoPersonSharp />,
        status: step === 0 && stepError ? 'error' : 'process',
      },
      {
        title: 'Ingresar código',
        content: (
          <StepTwoContent
            formData={formData}
            form={formStepTwo}
            onFinish={handleFinishStepTwo}
          />
        ),
        icon: <IoShieldCheckmarkSharp />,
        status:
          step === 1 && stepError ? 'error' : step >= 2 ? 'process' : 'wait',
      },
      {
        title: 'Hecho',
        content: <StepThreeContent onReset={onReset} />,
        icon: <IoCheckmarkCircleSharp />,
      },
    ],
    [
      form,
      formData,
      formStepTwo,
      handleFinish,
      handleFinishStepTwo,
      step,
      stepError,
      onReset,
    ]
  );

  if (isMakingRequest === true) return <HttpSpinner />;

  return (
    <AutoScreenWrap>
      <Row justify='center' align='center'>
        <Col
          xs={{ span: 20 }}
          sm={{ span: 18 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          xl={{ span: 6 }}
        >
          <SpaceYWrap>
            <ImgLogo>
              <Logo />
            </ImgLogo>
          </SpaceYWrap>
        </Col>
      </Row>
      <Row justify='center' align='center'>
        <Col xs={{ span: 20 }} sm={{ span: 18 }} md={{ span: 14 }}>
          <Title>Recuperar contraseña</Title>
          <Steps current={step}>
            {steps.map((item) => (
              <Steps.Step
                key={item.title}
                title={item.title}
                icon={item.icon}
                description={item.description ?? ''}
                status={item.status ?? ''}
              />
            ))}
          </Steps>
          <div className='steps-content'>{steps[step].content}</div>
        </Col>
      </Row>
    </AutoScreenWrap>
  );
}
export default PasswordReset;
