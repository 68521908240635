import { useState } from 'react';
import { Row, Col, Form, Input, Upload, Button, Grid } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import {
  createPost,
  uploadPostImageToS3,
  selectProfileId,
  selectIsAuthenticated,
  attachPost,
} from 'redux/index';
import { TopBarIcon, ButtonStyled, HttpSpinner } from 'components';
import { PostContainer } from '../Wraps';
import { formRules } from '../form-rules';
import { openNotification } from 'helpers/notifications';
import { ERROR, SUCCESS } from 'constants';

function PostFormContainer() {
  const screens = Grid.useBreakpoint();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const profileId = useSelector(selectProfileId);
  const token = useSelector(selectIsAuthenticated);
  const props = {
    name: 'dragger',
    limit: 1,
    multiple: false,
    maxCount: 1,
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const whiteList = ['image/jpeg', 'image/png'];
      if (!whiteList.includes(file.type)) return;
      if (fileList.length > 0) return;
      if (file.size / (1024 * 1024) > 1) {
        openNotification(ERROR, 'La imagen debe ser menor a una 1 mega.');
        return;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (loginData) => {
    if (fileList.length === 0) {
      openNotification(
        ERROR,
        'Por favor, Selecciona una imagen para la publicación.'
      );
      return;
    }

    setIsMakingRequest(true);
    // upload to s3
    const formData = new FormData();
    formData.append('post-img', fileList[0]);
    const imgLocation = await uploadPostImageToS3(formData, token);
    setIsMakingRequest(false);
    if (imgLocation == null) {
      openNotification(
        ERROR,
        'Carga de imagen fallida, Por favor verifica que la imagen sea png, jpeg or jpg con un tamaño menor a 1 mega.'
      );
      return;
    }
    const payload = {
      image: imgLocation,
      title: loginData.title,
      description: loginData.description,
      profileId,
    };
    const newPost = await createPost(payload, token);
    if (!newPost) {
      openNotification(
        ERROR,
        'Creación de publicación fallida, Por favor verifica los datos e intenta nuevamente.'
      );
      return;
    }
    dispatch(attachPost({ post: newPost }));
    openNotification(SUCCESS, 'Publicación creada exitosamente.');
    onReset();
    navigation(-1);
  };

  let titleResponsive = '';
  if (screens.xs === true) titleResponsive = 'C. Publicación';
  else titleResponsive = 'Crear Publicación';

  return (
    <>
      {isMakingRequest && <HttpSpinner />}

      <TopBarIcon
        text={titleResponsive}
        icon={<IoChatbubbleEllipsesOutline size={40} />}
      />
      <PostContainer>
        <Row justify='center' align='center'>
          <Col
            xs={{ span: 22 }}
            md={{ span: 18 }}
            lg={{ span: 12 }}
            xl={{ span: 10 }}
          >
            <Form
              size='large'
              form={form}
              onFinish={onFinish}
              autoSave='on'
              autoComplete='off'
            >
              <Form.Item
                label='Imagen de la publicación'
                hasFeedback
                required
                style={{ display: 'block' }}
                tooltip='Selecciona una imagen de tipo png, jpeg o jpg.'
              >
                <ImgCrop rotate aspect={2}>
                  <Upload
                    {...props}
                    onPreview={onPreview}
                    accept='.jpg, .jpeg, .png'
                  >
                    <Button>
                      <span>Cargar Imagen</span>
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </ImgCrop>
              </Form.Item>

              <Form.Item
                label='Título para la publicación'
                name='title'
                rules={formRules.title}
                style={{ display: 'block' }}
                tooltip='Recuerda que el titular de la publicación no puede ser mayor a 255 caracteres.'
              >
                <Input placeholder='Título de la publicación' maxLength={255} />
              </Form.Item>
              <Form.Item
                label='Descripción de la publicación'
                name='description'
                tooltip='Recuerda que la premisa de la publicación no puede ser mayor a 400 caracteres.'
                rules={formRules.description}
                style={{ display: 'block' }}
              >
                <Input.TextArea
                  rows={8}
                  placeholder='Premisa de la publicación'
                  style={{ resize: 'none' }}
                />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  xs: { span: 12 },
                  sm: { span: 16, offset: 4 },
                  lg: { span: 12, offset: 6 },
                }}
              >
                <ButtonStyled
                  type='primary'
                  size='large'
                  block
                  htmlType='submit'
                >
                  Crear Publicación
                </ButtonStyled>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </PostContainer>
    </>
  );
}

export default PostFormContainer;
