import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNotificationShow: false,
};

const reducers = {
  showNotification: (state) => {
    state.isNotificationShow = true;
  },
  resetNotification: (state) => {
    state.isNotificationShow = false;
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers,
});

const { actions, reducer } = notificationsSlice;

// ACTIONS
export const { showNotification, resetNotification } = actions;

// GETTERS
export const selectNotificationsState = (state) =>
  state.notificationsManagement;

export default reducer;
