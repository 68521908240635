import { useState, useRef } from 'react';
import { getProjects } from 'redux/index';
import ProjectsTable from '../tables/ProjectsTable';
import ProjectActions from '../tab-actions/ProjectActions';

function ProjectsTab({theme, token}) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const searching = useRef({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const fetchProjects = async (params = {}) => {
    setLoading(true);
    Object.assign(params, searching.current);

    const res = await getProjects(params);
    if (res == null) {
      setProjects([]);
      setLoading(false);
      return;
    }
    const { projects, total } = res;
    setProjects(projects);
    setPagination({
      current: params.page,
      pageSize: params.limit,
      total,
    });
    setLoading(false);
  };

  return (
    <>
      <ProjectActions
        token={token}
        fetchProjects={fetchProjects}
        searching={searching}
      />
      <ProjectsTable
        theme={theme}
        token={token}
        projects={projects}
        loading={loading}
        pagination={pagination}
        fetchProjects={fetchProjects}
      />
    </>
  );
}

export default ProjectsTab;
