import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { selectIsAuthenticated } from '../../../redux';
import {
  OPERATOR_MODULE,
  ADMIN_MODULE,
  RESIDENT_MODULE,
  SUPERUSER_MODULE,
} from '../../../constants';

function PrivateRoute({ children }) {
  const token = useSelector(selectIsAuthenticated);
  if (!token) return <Navigate replace={true} to='/login' />;
  const decodedToken = jwt_decode(token);
  if (decodedToken.role == null) return <Navigate replace={true} to='/login' />;
  const { role } = decodedToken;
  if (
    role === OPERATOR_MODULE ||
    role === ADMIN_MODULE ||
    role === RESIDENT_MODULE ||
    role === SUPERUSER_MODULE
  )
    return children;
  return <Navigate replace={true} to='/login' />;
}

export default PrivateRoute;
