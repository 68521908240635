import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import Actions from '../actions/Actions';
import { TableStyled, TagStyled } from 'components';

function UsersTable({
  theme,
  users,
  loading,
  pagination,
  fetchUsers,
  onElimination,
}) {
  const effectRan = useRef(false);

  const columns = [
    {
      title: 'Nombre y Apellidos',
      dataIndex: 'fullName',
      width: '16%',
      sorter: true,
      filterSearch: true,
      render: (value) => (
        <TagStyled color={theme.colors.blueSix}>{value}</TagStyled>
      ),
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      responsive: ['lg'],
      width: '16%',
      sorter: true,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      responsive: ['lg'],
      width: '16%',
    },
    {
      title: 'Email Verificado',
      dataIndex: 'status',
      responsive: ['lg'],
      width: '10%',
      sorter: true,
      render: (status) => {
        return (
          <>
            {status === true ? (
              <TagStyled color={theme.colors.primaryNeon}>Verificado</TagStyled>
            ) : (
              <TagStyled color={theme.colors.graySix}>Sin Verificar</TagStyled>
            )}
          </>
        );
      },
    },
    {
      title: 'Estado',
      dataIndex: 'userStatus',
      responsive: ['lg'],
      width: '10%',
      sorter: true,
      render: (value) => {
        const mappedValue = value[0].toUpperCase() + value.slice(1);
        switch (true) {
          case value === 'activo':
            return (
              <TagStyled color={theme.colors.primaryNeon}>
                {mappedValue}
              </TagStyled>
            );

          case value === 'rechazado':
            return (
              <TagStyled color={theme.colors.redFive}>{mappedValue}</TagStyled>
            );

          default:
            return (
              <TagStyled color={theme.colors.graySix}>{mappedValue}</TagStyled>
            );
        }
      },
    },
    {
      title: 'Acciones',
      key: 'operation',
      align: 'right',
      width: '5%',
      render: (user) => (
        <Actions
          user={user}
          users={users}
          onElimination={onElimination}
          theme={theme}
          pagination={pagination}
          fetchUsers={fetchUsers}
        />
      ),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    const { current: page, pageSize: limit } = newPagination;
    fetchUsers({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: page,
      limit,
    });
  };

  useEffect(() => {
    if (effectRan.current === false) {
      fetchUsers({
        limit: pagination.pageSize,
        page: pagination.current,
      });
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableStyled
      columns={columns}
      rowKey={(user) => user.id}
      dataSource={users}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      showSorterTooltip={false}
    />
  );
}

UsersTable.propTypes = {
  theme: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  onElimination: PropTypes.func.isRequired,
};

export default UsersTable;
