import React from 'react';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectIsAuthenticated } from '../../../redux';
import {
  ADMIN_MODULE,
  RESIDENT_MODULE,
  OPERATOR_MODULE,
  SUPERUSER_MODULE,
} from '../../../constants';

function PublicOrBlockedRoute({ children }) {
  const token = useSelector(selectIsAuthenticated);
  if (!token) return children;
  const decodedToken = jwt_decode(token);

  switch (true) {
    case decodedToken.role === ADMIN_MODULE:
    case decodedToken.role === OPERATOR_MODULE:
    case decodedToken.role === RESIDENT_MODULE:
    case decodedToken.role === SUPERUSER_MODULE:
      return <Navigate replace={true} to='/dashboard' />;
    default:
      return <Navigate replace={true} to='/login' />;
  }
}

export default PublicOrBlockedRoute;
