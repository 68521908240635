import { useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { uploadFileToS3, commitUsersBulkUpload } from 'redux/index';
import { openNotification } from 'helpers/notifications';
import { ERROR, SUCCESS } from 'constants';
import { prepareFormDataFile } from 'helpers/file-reader';

function UploadExcelFile({ profileId, token, theme, fetchData, pagination }) {
  const [fileList, setFileList] = useState([]);
  const [isMakingReq, setIsMakingReq] = useState(false);
  const props = {
    name: 'excel-file',
    limit: 1,
    multiples: false,
    maxCount: 1,
    accept: '.xlsx,.xls',
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const whiteList = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
      if (!whiteList.includes(file.type)) return;
      if (file.size / (1024 * 1024) > 5) {
        openNotification(ERROR, 'La documento debe ser menor a 5 megas.');
        return;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleSubmitFile = async () => {
    setFileList([]);
    const totalFIles = fileList.length;
    if (totalFIles !== 1) {
      openNotification(
        ERROR,
        'Error al cargar el documento, por favor recuerda que solo puedes cargar un documento a la vez.'
      );
      return;
    }
    setIsMakingReq(true);
    const formData = prepareFormDataFile(fileList[0], 'users-excel');
    const res = await uploadFileToS3(formData, token);
    if (res?.statusCode === 500) {
      openNotification(
        ERROR,
        'Error fatal al cargar el archivo, por favor verifica que el archivo subido concuerde con la plantilla.'
      );
      setIsMakingReq(false);
      return;
    }
    if (res?.statusCode === 400) {
      openNotification(ERROR, res.message);
      setIsMakingReq(false);
      return;
    }
    if (res?.location == null) {
      openNotification(
        ERROR,
        'Error al guardar el archivo, por favor intenta nuevamente.'
      );
      setIsMakingReq(false);
      return;
    }
    const { location } = res;
    const payload = {
      profileId,
      filePath: location,
    };
    const res2 = await commitUsersBulkUpload(payload, token);
    if (res2 == null) {
      openNotification(
        ERROR,
        'Error al cargar los usuarios, por favor verifica que el archivo subido concuerde con la plantilla.'
      );
      setIsMakingReq(false);
      return;
    }
    openNotification(SUCCESS, 'Carga masiva realizada exitosamente.');
    fetchData({
      limit: pagination.pageSize,
      page: pagination.current,
    });
    setIsMakingReq(false);
  };

  const customStyle = {
    backGround: theme.colors.redFive,
  };
  const customProps = {
    disabled: fileList.length !== 1 ? true : false,
  };
  const disableActions = {
    disabled: isMakingReq === true ? true : false,
  };
  const spinnerColor = {
    color: theme.colors.blueSky,
  };
  return (
    <>
      <Upload
        {...props}
        style={{ display: 'flex' }}
        noStyle
        {...disableActions}
      >
        <Button icon={<UploadOutlined />} size='large'>
          Cargar Archivo
        </Button>
      </Upload>

      <Button
        onClick={handleSubmitFile}
        type='primary'
        danger
        style={customStyle}
        {...customProps}
      >
        Cargar
      </Button>
      {isMakingReq && <LoadingOutlined style={spinnerColor} />}
    </>
  );
}

export default UploadExcelFile;
