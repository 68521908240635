import { Image } from 'antd';

function Logo() {
  return (
    <Image
      src='/assets/images/logo2.png'
      alt='Brand'
      width='auto'
      height='auto'
      style={{ maxWidth: '100%' }}
    />
  );
}

export default Logo;
