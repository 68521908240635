const baseUrl = process.env.REACT_APP_SERVICE_BOOKING



export const fetchWithToken = (request) => {
  const token =  localStorage.getItem('VE9LRU5fS0VZ').slice(1, -1);
  
  const { method, data, endpoint } = request;

  if (endpoint) {
    const url = `${baseUrl}/${endpoint}`;

    if (method === 'GET') {
      return fetch(url, {
        method,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } else {
      return fetch(url, {
        method,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
    }
  } else {
    if (method === 'GET') {
      return fetch(baseUrl, {
        method,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } else {
      return fetch(baseUrl, {
        method,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
    }
  }
};
