import { useState, useEffect } from 'react';
import ImgCrop from 'antd-img-crop';
import { CloudUploadOutlined } from '@ant-design/icons';
import { IoCloudUploadSharp } from 'react-icons/io5';
import { Form, Col, Row, Input, Select, DatePicker, Upload } from 'antd';
import { MdGroupAdd } from 'react-icons/md';
import { useTheme } from 'styled-components';
import {
  TableSearcher,
  TableActionButton,
  TopActionsWrap,
  UserModal,
  ButtonStyled,
} from 'components';
import {
  userRules,
  preventDefault,
  prefixSelector,
  genders,
  roles,
  getFriendlyRole,
  openNotification,
} from 'helpers';
import { ERROR } from 'constants';
import { uploadUserImageToS3, createJustUser } from 'redux/index';
import { SUCCESS } from 'constants';

function UserFormCreation({ fetchUsers }) {
  const [form] = Form.useForm();
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [fileList, setFileList] = useState([]);

  const imageProps = {
    name: 'user-image',
    limit: 1,
    multiple: false,
    maxCount: 1,
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const whiteList = ['image/jpeg', 'image/png'];
      if (!whiteList.includes(file.type)) return;
      if (fileList.length > 0) return;
      if (file.size / (1024 * 1024) > 1) {
        openNotification('error', 'La imagen debe ser menor a una 1 mega.');
        return;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    form.setFieldsValue({ prefix: '57' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (data) => {
    setIsMakingRequest(true);

    // Upload Image to s3
    setIsMakingRequest(true);
    if (fileList.length > 0) {
      const iFormData = new FormData();
      iFormData.append('user-img', fileList[0]);
      const userImage = await uploadUserImageToS3(iFormData, null);
      if (userImage == null) {
        openNotification(
          ERROR,
          'Carga de imagen fallida, Por favor verifica que la imagen sea png, jpeg or jpg con un tamaño menor a 1 mega.'
        );
        setIsMakingRequest(false);
        return;
      }
      data['image'] = userImage;
    }

    if (data.dateOfBirth) data.dateOfBirth = new Date(data.dateOfBirth);
    data.phoneNumber = `+${data.prefix} ${data.phoneNumber}`;

    delete data.prefix;

    data.status = false;
    data.isVerified = false;
    const res = await createJustUser({ payload: data });
    setIsMakingRequest(false);

    if (res.status === false) {
      openNotification(ERROR, res.msg);
      return;
    }

    openNotification(SUCCESS, 'Usuario creado exitosamente.');
    form.resetFields();
    setFileList([]);
    form.setFieldsValue({ prefix: '57' });
    fetchUsers();
  };

  return (
    <>
      <Form size='large' onFinish={onFinish} autoComplete='off' form={form}>
        <Row justify='center' gutter={[{ md: 48 }, 0]}>
          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <Form.Item
              label='Nombre completo'
              name='fullName'
              required
              tooltip='Por favor escribe los nombres y apellidos de la persona.'
              rules={userRules.fullName}
              style={{
                display: 'block',
              }}
            >
              <Input
                placeholder='Nombre completo'
                onPressEnter={preventDefault}
              />
            </Form.Item>

            <Form.Item
              label='Nombre de usuario'
              name='userName'
              required
              tooltip='Por favor, Escribe el nombre de usuario, por ejemplo: carlos-mario11'
              rules={userRules.userName}
              style={{
                display: 'block',
              }}
            >
              <Input
                placeholder='Nombre de usuario'
                onPressEnter={preventDefault}
              />
            </Form.Item>

            <Form.Item
              label='Email del usuario'
              name='email'
              required
              tooltip='Por favor, Escribe el correo electronico del usuario a crear, recuerda que en este correo será enviado un correo de verificación para validar la cuenta.'
              rules={userRules.email}
              style={{
                display: 'block',
              }}
            >
              <Input
                placeholder='example@gmail.com'
                onPressEnter={preventDefault}
              />
            </Form.Item>

            <Form.Item
              tooltip='Por favor ingresa un número celular, recuerda que este debe contener al menos 10 dígitos numéricos.'
              name='phoneNumber'
              label='Número celular'
              rules={userRules.cellphone}
              style={{ display: 'block' }}
            >
              <Input
                addonBefore={prefixSelector}
                placeholder='3122343578'
                onPressEnter={preventDefault}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <Form.Item
              label='Rol'
              name='role'
              tooltip='Por favor selecciona el rol del usuario a crear.'
              style={{
                display: 'block',
              }}
              rules={userRules.role}
            >
              <Select placeholder='Seleccionar...'>
                {roles.map((role, i) => (
                  <Select.Option value={role} key={i}>
                    {getFriendlyRole(role) === 'Administrador C.'
                      ? 'Administrador Conjunto'
                      : getFriendlyRole(role)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Fecha de nacimiento'
              name='dateOfBirth'
              tooltip='Por favor seleccionar la fecha de nacimiento.'
              rules={userRules.dayOfBirthday}
              style={{
                display: 'block',
              }}
            >
              <DatePicker
                placeholder='Fecha'
                style={{
                  display: 'block',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Sexo'
              name='gender'
              tooltip='Por favor seleccionar el tipo de sexo.'
              style={{
                display: 'block',
              }}
              rules={userRules.gender}
            >
              <Select placeholder='Seleccionar...'>
                {genders.map((g) => (
                  <Select.Option value={g.key} key={g.key}>
                    {g.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Imagen'
              tooltip='Selecciona una imagen de tipo png, jpeg o jpg.'
              style={{
                display: 'block',
              }}
            >
              <ImgCrop rotate aspect={1} rules={[{ required: true }]}>
                <Upload
                  listType='picture-card'
                  accept='.jpg, .jpeg, .png'
                  {...imageProps}
                >
                  <CloudUploadOutlined />
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>

          {/* Submit action */}
          <Col xs={{ span: 22 }} sm={{ span: 16 }} md={{ span: 11 }}>
            <ButtonStyled
              type='primary'
              size='large'
              block
              htmlType='submit'
              loading={isMakingRequest}
            >
              Crear Usuario
              <IoCloudUploadSharp size={20} />
            </ButtonStyled>
          </Col>
        </Row>
      </Form>
    </>
  );
}

function UserActions({ fetchUsers, searching }) {
  const theme = useTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => {
    setIsModalVisible((preValue) => !preValue);
  };

  const handleSearch = (formData) => {
    const { column, searching: searchingInput } = formData;

    if (
      !column ||
      column === undefined ||
      !searchingInput ||
      searchingInput === undefined
    ) {
      searching.current = {};
      return;
    }

    const newSearching = {
      searchingCol: column,
      searchingVal: searchingInput.trim().split(' ').join('-'),
    };

    searching.current = newSearching;
    fetchUsers({
      page: 1,
      limit: 10,
    });
  };

  const columns = [
    // the key must be match with the field in db
    { name: 'Nombres y Apellidos', key: 'fullName', type: 'string' },
    { name: 'Correo', key: 'email', type: 'string' },
    { name: 'Telefono', key: 'phoneNumber', type: 'string' },
  ];

  return (
    <>
      <UserModal
        title='Crear Usuario'
        isModalVisible={isModalVisible}
        handleSaveModal={toggleModal}
        handleCancelModal={toggleModal}
        saveText={''}
        children={<UserFormCreation fetchUsers={fetchUsers} />}
        width='70rem'
      />

      <TopActionsWrap>
        <TableActionButton
          onToggleModal={toggleModal}
          title='Crear nuevo Usuario'
        >
          <MdGroupAdd size={30} color={theme.colors.graySix} />
        </TableActionButton>

        <TableSearcher
          columns={columns}
          onSubmit={handleSearch}
          searching={searching}
          onFetch={fetchUsers}
        />
      </TopActionsWrap>
    </>
  );
}

export default UserActions;
