import React from 'react';
import PropTypes from 'prop-types';
import { ModalStyled } from './Wraps';

const UserModal = ({
  title,
  isModalVisible,
  handleSaveModal,
  handleCancelModal,
  saveText,
  children,
  ...props
}) => {
  return (
    <>
      <ModalStyled
        centered={true}
        okText={saveText}
        cancelText={true}
        title={title}
        visible={isModalVisible}
        onOk={handleSaveModal}
        onCancel={handleCancelModal}
        footer={null}
        {...props}
      >
        {children}
      </ModalStyled>
    </>
  );
};

UserModal.propTypes = {
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  handleSaveModal: PropTypes.func.isRequired,
  handleCancelModal: PropTypes.func.isRequired,
};

export default UserModal;
