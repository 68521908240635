const colors = {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  black: '#000',
  gray: '#333333',
  grayTwo: '#555555',
  white: '#fff',
  primary: '#006634',
  primaryHover: '#00522a',
  primaryLight: '#66a385',
  primaryNeon: '#52c41a',
  limeSix: '#66a80f',
  blueSky: ' #2db7f5',
  blueSix: '#1971c2',
  yellowSix: '#f08c00',
  redFive: '#e03131',
  graySix: '#bfbfbf',
  cianSix: '#15aabf',
  cianThree: '#66d9e8',
};

export default colors;
