export const resourceRules = {
  name: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      min: 4,
    },
    {
      max: 255,
    },
  ],
  link: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      type: 'url',
    },
  ],
};
