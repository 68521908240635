import { Form, Row, Col, Input } from 'antd';
import { ButtonStyled, InputStyled, FormItem } from './Wraps';
import { stepOneRules, stepTwoRules } from './form-rules';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPasswordState } from 'redux/index';

export function StepOneContent ({ form, onFinish }) {
  return (
    <Form
      autoComplete='off'
      size='large'
      autoSave='on'
      form={form}
      onFinish={onFinish}
    >
      <Row justify='center' style={{ marginTop: '1rem' }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 18 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
        >
          <Form.Item name='email' rules={stepOneRules.userName} hasFeedback>
            <InputStyled placeholder='Por favor ingresa usuario o email' />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 18 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
        >
          <Form.Item>
            <ButtonStyled type='primary' size='large' htmlType='submit' block>
              Enviar correo de recuperación
            </ButtonStyled>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export function StepTwoContent ({ formData, form, onFinish }) {
  const handleChange = (event) => {
    const digits = Array.from(event.target.value.trim()).slice(0);
    digits.forEach((digit, index) => {
      const input = {};
      input[`n${index + 1}`] = digit ?? '';
      form.setFieldsValue(input);
    });
  };
  return (
    <Form
      autoComplete='off'
      size='large'
      autoSave='on'
      form={form}
      onFinish={onFinish}
      initialValues={formData}
    >
      <Input.Group size='large'>
        <Row
          justify='center'
          gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}
          style={{ marginTop: '2rem' }}
        >
          {new Array(6).fill(null).map((_, index) => (
            <Col
              key={index}
              xs={{ span: 6 }}
              sm={{ span: 4 }}
              md={{ span: 4 }}
              lg={{ span: 3 }}
              xl={{ span: 2 }}
              align='center'
            >
              {index === 0 ? (
                <FormItem
                  name={`n${index + 1}`}
                  rules={stepTwoRules.customDigit}
                >
                  <Input onChange={handleChange} />
                </FormItem>
              ) : (
                <FormItem
                  name={`n${index + 1}`}
                  rules={stepTwoRules.customDigit}
                >
                  <Input maxLength={1} />
                </FormItem>
              )}
            </Col>
          ))}
        </Row>
      </Input.Group>
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 18 }}
          xl={{ span: 12 }}
        >
          <Form.Item name='email' hasFeedback>
            <InputStyled
              placeholder='Por favor ingresa tú usuario o email'
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 18 }}
          xl={{ span: 12 }}
        ></Col>
      </Row>
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 18 }}
          xl={{ span: 12 }}
        >
          <Form.Item name='password' rules={stepTwoRules.password} hasFeedback>
            <Input.Password placeholder='Por favor ingresa tú nueva contraseña' />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 18 }}
          xl={{ span: 12 }}
        >
          <Form.Item
            name='confirmation'
            dependencies={['password']}
            hasFeedback
            rules={stepTwoRules.confirmation}
          >
            <Input.Password placeholder='Por favor confirma tú contraseña' />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item>
            <ButtonStyled type='primary' size='large' htmlType='submit' block>
              Establecer nueva contraseña
            </ButtonStyled>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export function StepThreeContent ({ onReset }) {
  const dispatch = useDispatch();
  const handleRest = () => {
    onReset();
    dispatch(resetPasswordState());
  };
  return (
    <Row justify='center' style={{ marginTop: '1rem' }}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 18 }}
        md={{ span: 16 }}
        lg={{ span: 12 }}
        xl={{ span: 12 }}
      >
        <NavLink to='/login' onClick={handleRest}>
          <ButtonStyled type='primary' size='large' htmlType='submit' block>
            ¡Iniciar sesión!
          </ButtonStyled>
        </NavLink>
      </Col>
    </Row>
  );
}
