import { useState, useRef } from 'react';
import { Row, Col, Grid, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { IoPeopleOutline } from 'react-icons/io5';
import { getUsers, selectProjectId, selectIsAuthenticated } from 'redux/index';
import { TopBarIcon, GlobalContainer, TabWrap } from 'components';
import { notifyUserElimination, notifyUserVerification } from './helpers';
import UsersTable from './components/table/UsersTable';
import { ICON_SIZE } from 'constants';
import UsersActions from './components/tab-actions/UsersActions';

function UserManagement() {
  const theme = useTheme();
  const searching = useRef({});
  const screens = Grid.useBreakpoint();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const projectId = useSelector(selectProjectId);
  const token = useSelector(selectIsAuthenticated);

  const handleUserElimination = (id) => {
    let page;
    const totalUsers = users.length;
    if (totalUsers > 1) page = pagination.current;
    if (totalUsers === 1 && pagination.current === 1) page = pagination.current;
    if (totalUsers === 1 && pagination.current !== 1)
      page = pagination.current - 1;
    notifyUserElimination(
      id,
      token,
      {
        limit: pagination.pageSize,
        page,
        total: pagination.total,
      },
      fetchUsers
    );
  };

  const handleUserVerification = (id) => {
    notifyUserVerification(
      id,
      token,
      {
        limit: pagination.pageSize,
        page: pagination.current,
        total: pagination.total,
      },
      fetchUsers
    );
  };

  const fetchUsers = async (params = {}) => {
    setLoading(true);
    Object.assign(params, searching.current);
    const res = await getUsers(params, projectId, token);
    if (res == null) {
      setUsers([]);
      setLoading(false);
      return;
    }
    const { users, total } = res;
    setUsers(users);
    setPagination({
      current: params.page,
      pageSize: params.limit,
      total,
    });
    setLoading(false);
  };

  let titleResponsive = '';
  if (screens.xs === true) titleResponsive = 'G. Usuarios';
  else titleResponsive = 'Gestión de Usuarios';

  return (
    <>
      <TopBarIcon
        bgColor={theme.colors.primary}
        color={theme.colors.white}
        text={titleResponsive}
        icon={<IoPeopleOutline size={40} color={theme.colors.white} />}
      />
      <GlobalContainer>
        <Row justify='center' align='center' style={{ marginBottom: '2rem' }}>
          <Col
            xs={{ span: 20 }}
            sm={{ span: 20 }}
            md={{ span: 20 }}
            lg={{ span: 18 }}
          >
            <Tabs size='large' defaultActiveKey='1'>
              <Tabs.TabPane
                tab={
                  <TabWrap>
                    <IoPeopleOutline size={ICON_SIZE} />
                    Usuarios
                  </TabWrap>
                }
                key='1'
              >
                <UsersActions
                  theme={theme}
                  fetchUsers={fetchUsers}
                  searching={searching}
                />

                <UsersTable
                  theme={theme}
                  users={users}
                  loading={loading}
                  pagination={pagination}
                  fetchUsers={fetchUsers}
                  onElimination={handleUserElimination}
                  onVerification={handleUserVerification}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </GlobalContainer>
    </>
  );
}

export default UserManagement;
