import styled from 'styled-components';

export const BookingItemStyled = styled.li`
  & > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  border-bottom: 2px solid rgba(112, 112, 112, 0.1);
`;

export const BookingItemLeft = styled.div`
margin: 0.2em 0 ;
  span {
    font-size: 1.1rem;
    color: #707070;
  }
  p {
    font-weight: bold;
    margin: 0.3em 0 0.1em 0!important;
    color: #000;
  }
  div {
    font-weight: bold;
  }

  @media (min-width: 992px) {
    span {
      font-size: 1.2rem;
     
    }
  }
`;

export const BookingItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #000;

  & > span:first-child {
    font-weight: bold;
  }

  & > span:last-child {
    margin-top: 4px;
    & > svg {
      color: #000;
      stroke-width: 2;
    }
  }
`;
