import { optUsersService } from '../../services/ajax/opt-users-service';
const SERVICES_PATH = {
  getResources: '/resources/residential',
  deleteResource: '/resources/',
  createResource: '/resources',
  updateResource: '/resources/',
};

export async function getResources({ page, residentialId, token }) {
  try {
    const res = await optUsersService.get(
      `${SERVICES_PATH.getResources}/${residentialId}?page=${page}&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return {
      total: res.data[1],
      resources: res.data[0],
    };
  } catch {
    return {
      total: 0,
      resources: [],
    };
  }
}

export async function deleteResource(id, token) {
  try {
    await optUsersService.delete(SERVICES_PATH.deleteResource + `${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return true;
  } catch {
    return null;
  }
}

export async function createResource(payload, token) {
  try {
    const res = await optUsersService.post(
      SERVICES_PATH.createResource,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function updateResource({ id, payload, token }) {
  try {
    const res = await optUsersService.patch(
      SERVICES_PATH.updateResource + id,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}
