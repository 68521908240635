import { useState, useRef } from 'react';
import { Row, Col, Grid, Tabs } from 'antd';
import { useTheme } from 'styled-components';
import { IoPaperPlaneOutline, IoEnterOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { TopBarIcon, GlobalContainer, TabWrap } from 'components';
import LinksTable from './components/table/LinksTable';
import { selectIsAuthenticated, getLinks, selectProjectId } from 'redux/index';
import LinkActions from './components/tab-actions/LinkActions';
import { ICON_SIZE } from 'constants';

function DynamicLinksContainer() {
  const screens = Grid.useBreakpoint();
  const theme = useTheme();
  const searching = useRef({});
  const token = useSelector(selectIsAuthenticated);
  const projectId = useSelector(selectProjectId);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const fetchLinks = async (params = {}) => {
    params.projectId = projectId;
    Object.assign(params, searching.current);
    setLoading(true);
    const res = await getLinks(params, token);
    if (res == null) {
      setLinks([]);
      setLoading(false);
      return;
    }
    const { links, total } = res;
    setLinks(links);
    setPagination({
      current: params.page,
      pageSize: params.limit,
      total,
    });
    setLoading(false);
  };

  let titleResponsive = '';
  if (screens.xs === true || screens.sm === true) titleResponsive = 'Enlaces';
  if (screens.md === true) titleResponsive = 'Enlaces Dinámicos';

  return (
    <>
      <TopBarIcon
        bgColor={theme.colors.primary}
        color={theme.colors.white}
        text={titleResponsive}
        icon={
          <IoPaperPlaneOutline size={ICON_SIZE} color={theme.colors.white} />
        }
      />

      <GlobalContainer>
        <Row justify='center' align='center'>
          <Col
            xs={{ span: 20 }}
            sm={{ span: 20 }}
            md={{ span: 20 }}
            lg={{ span: 18 }}
          >
            <Tabs size='large' defaultActiveKey='1'>
              <Tabs.TabPane
                tab={
                  <TabWrap>
                    <IoEnterOutline size={ICON_SIZE} />
                    Enlaces de Registro
                  </TabWrap>
                }
                key='1'
              >
                <LinkActions
                  token={token}
                  fetchLinks={fetchLinks}
                  theme={theme}
                  projectId={projectId}
                  searching={searching}
                />
                <LinksTable
                  theme={theme}
                  token={token}
                  links={links}
                  loading={loading}
                  pagination={pagination}
                  fetchLinks={fetchLinks}
                  projectId={projectId}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </GlobalContainer>
    </>
  );
}

export default DynamicLinksContainer;
