import { useSelector } from 'react-redux';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { selectUserRole, selectProjectId } from './redux';
import {
  RESIDENT_MODULE,
  PROFILE,
  USER_EDITION,
  ADMIN_MODULE,
  SUPERUSER_MODULE,
} from 'constants';
import {
  DashBoardLayout,
  PrivateRoute,
  PublicOrBlockedRoute,
} from 'components';
import {
  Login,
  ProfileWall,
  Resources,
  Booking,
  NewBooking,
  Assets,
  CreateAsset,
  UserManagement,
  CreateUser,
  PasswordReset,
  HomeContainer,
  PostFormContainer,
  ProfileWallDetail,
  Dashboard,
  EditFormContainer,
  EditUser,
  UsersBulkUpload,
  Projects,
  DynamicLinksContainer,
  RegisterContainer,
  Profiles,
} from 'containers';

function App() {
  const userRole = useSelector(selectUserRole);
  const project = useSelector(selectProjectId);
  return (
    <Routes>
      <Route
        index
        path='/login'
        element={
          <PublicOrBlockedRoute>
            <Login />
          </PublicOrBlockedRoute>
        }
      />
      <Route
        path='/restablecer-contrasena'
        exact
        element={
          <PublicOrBlockedRoute>
            <PasswordReset />
          </PublicOrBlockedRoute>
        }
      />
      <Route path='/registro/:uuid' element={<RegisterContainer />} />
      <Route
        path='/dashboard'
        element={
          <PrivateRoute>
            <DashBoardLayout />
          </PrivateRoute>
        }
      >
        <Route path='' element={<Dashboard />}></Route>
        {userRole === SUPERUSER_MODULE && (
          <>
            <Route path='proyectos' element={<Projects />} />
            <Route path='enlaces' element={<DynamicLinksContainer />} />
            <Route path=':uuid/perfiles' element={<Profiles />} />
          </>
        )}

        {project && (
          <>
            <Route path='inicio' element={<HomeContainer />} />
            <Route path='perfil/:uuid' element={<EditUser type={PROFILE} />} />
            {/* admin - operator - resident */}
            <Route path='recursos' element={<Resources />} />
            <Route path='muro' element={<Outlet />}>
              <Route path='' element={<ProfileWall />} />
              {/* superuser - admin - operator  */}
              {userRole !== RESIDENT_MODULE && (
                <>
                  <Route path='crear' element={<PostFormContainer />} />
                  <Route path=':uuid/editar' element={<EditFormContainer />} />
                </>
              )}
              <Route path=':uuid/detalle' element={<ProfileWallDetail />} />
            </Route>
            {/* superuser - admin - operator */}
            {userRole !== RESIDENT_MODULE && (
              <>
                <Route path='activos' element={<Outlet />}>
                  <Route path='' element={<Assets />} />
                  <Route path='crear' element={<CreateAsset />} />
                  <Route path=':uuid' element={<CreateAsset />} />
                  <Route path=':uuid/editar' element={<CreateAsset />} />
                </Route>
                {/* admin - operator */}
                <Route path='usuarios' element={<Outlet />}>
                  <Route path='' element={<UserManagement />} />
                  <Route path='crear' element={<CreateUser />} />
                  <Route
                    path=':uuid/editar'
                    element={<EditUser type={USER_EDITION} />}
                  />
                </Route>
              </>
            )}
            {(userRole === SUPERUSER_MODULE || userRole === ADMIN_MODULE) && (
              <Route path='cargas-masivas' element={<Outlet />}>
                <Route path='usuarios' element={<UsersBulkUpload />} />
              </Route>
            )}
            {/* superuser - admin - operator - resident  */}
            <Route path='reservas' element={<Outlet />}>
              <Route path='' element={<Booking />} />
              <Route path='nueva' element={<NewBooking />} />
              {/* <Route
                path=':uuid/detalle'
                element={<p>Reservas detalle🛠️🏗️</p>}
              /> */}
            </Route>
          </>
        )}
        <Route path='*' element={<Navigate to='/dashboard' replace={true} />} />
      </Route>
      <Route path='*' element={<Navigate to='/login' replace={true} />} />
    </Routes>
  );
}
export default App;
