import styled from 'styled-components';

export const MovileContainerStyled = styled.div`
  height: calc(
    100% - 22% - ${(props) => props.marginCalc || '0rem'}
  ); /* 22% = 12% top bar && 10% bottom navigation */
  padding: 1.6rem 2.4rem;
  overflow-y: scroll;
  scroll-behavior: smooth;

  @media (min-width: 992px) {
    height: calc(100% - 12% - ${(props) => props.marginCalc || '0rem'});
  }
`;

export const GlobalContainer = styled(MovileContainerStyled)`
  height: calc(100% - 22% - ${(props) => props.marginCalc || '0rem'});

  @media (min-width: 992px) {
    height: calc(100% - 12% - ${(props) => props.marginCalc || '0rem'});
  }
`;
