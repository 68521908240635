export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const onPreview = async (file) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

export const readAndDonwloadFile = (res) => {
  const url = URL.createObjectURL(new Blob([res]));
  const link = document.createElement('a');
  link.href = url;
  const extention = res.type === 'text/plain' ? 'txt' : 'xlsx';
  link.setAttribute('download', `${new Date().getTime()}.${extention}`);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.remove();
  }, 1000);
};

export const prepareFormDataFile = (file, key) => {
  const formData = new FormData();
  if (!file) return formData;
  formData.set(key, file);
  return formData;
};
