import { useState } from 'react';
import { MdPostAdd } from 'react-icons/md';
import { useTheme } from 'styled-components';
import CreationModalContent from './components/CreationModalContent';
import {
  UserModal,
  TableSearcher,
  TableActionButton,
  TopActionsWrap,
} from 'components';

function ProjectActions({ token, fetchProjects, searching }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => setIsModalVisible((preValue) => !preValue);
  const theme = useTheme();

  const handleSearch = (formData) => {
    const { column, searching: searchingInput } = formData;

    if (
      !column ||
      column === undefined ||
      !searchingInput ||
      searchingInput === undefined
    ) {
      searching.current = {};
      return;
    }

    const newSearching = {
      searchingCol: column,
      searchingVal: searchingInput.trim().split(' ').join('-'),
    };

    searching.current = newSearching;
    fetchProjects({
      page: 1,
      limit: 10,
    });
  };

  const columns = [{ name: 'Nombre', key: 'name', type: 'string' }];

  return (
    <>
      <UserModal
        title='Crear Proyectos de Odoo'
        isModalVisible={isModalVisible}
        handleSaveModal={toggleModal}
        handleCancelModal={toggleModal}
        saveText={''}
      >
        <CreationModalContent token={token} fetchProjects={fetchProjects} />
      </UserModal>
      <TopActionsWrap>
        <TableActionButton
          onToggleModal={toggleModal}
          title='Crear nuevo Proyecto'
        >
          <MdPostAdd size={30} color={theme.colors.graySix} />
        </TableActionButton>

        <TableSearcher
          columns={columns}
          onSubmit={handleSearch}
          searching={searching}
          onFetch={fetchProjects}
          defaultValues={{}}
        />
      </TopActionsWrap>
    </>
  );
}

export default ProjectActions;
