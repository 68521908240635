import { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  TableSearcher,
  TableActionButton,
  TopActionsWrap,
  UserModal,
} from 'components';
import { IoAttach } from 'react-icons/io5';
import { BsJournalText } from 'react-icons/bs';
import EditUserData from './components/EditUserData';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOneUser,
  selectUsersState,
  setUserReqStatus,
  getAllOptProjects,
} from 'redux/index';
import { useParams } from 'react-router-dom';
import AttachProject from './components/AttachProject';

function ProfilesActions({ fetchProfiles, searching }) {
  const theme = useTheme();
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const [projects, setProjects] = useState([]);
  const [isRequestingProjects, setIsRequestingProjects] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);

  const { user, isRequestingUser } = useSelector(selectUsersState);

  const fetchUser = () => dispatch(getOneUser({ id: uuid }));

  const toggleModal = () => {
    setIsModalVisible((preValue) => {
      const newStatus = !preValue;
      if (newStatus === false) {
        dispatch(setUserReqStatus({}));
      } else {
        fetchUser();
      }
      return newStatus;
    });
  };

  const fetchProjects = async () => {
    setIsRequestingProjects(true);
    const res = await getAllOptProjects({ userId: uuid });
    setIsRequestingProjects(false);
    if (res == null) {
      setProjects([]);
    } else {
      setProjects(res);
    }
  };

  const toggleProjectModal = () => {
    setIsProjectModalVisible((preValue) => {
      const newStatus = !preValue;
      if (newStatus === false) {
        setProjects([]);
      } else {
        new Promise(async () => {
          await fetchProjects();
        });
      }
      return newStatus;
    });
  };

  const handleSearch = (formData) => {
    const { column, searching: searchingInput } = formData;

    if (
      !column ||
      column === undefined ||
      !searchingInput ||
      searchingInput === undefined
    ) {
      searching.current = {};
      return;
    }

    const newSearching = {
      searchingCol: column,
      searchingVal: searchingInput.trim().split(' ').join('-'),
    };

    searching.current = newSearching;
    fetchProfiles({
      page: 1,
      limit: 10,
    });
  };

  const columns = [
    // the key must be match with the field in db
    { name: 'Proyecto', key: 'project', type: 'string' },
    { name: 'Dirección', key: 'address', type: 'string' },
  ];

  return (
    <>
      <UserModal
        title='Editar Datos de Usuario'
        isModalVisible={isModalVisible}
        handleSaveModal={toggleModal}
        handleCancelModal={toggleModal}
        saveText={''}
        children={
          <EditUserData user={user} isRequestingUser={isRequestingUser} />
        }
        width={'80rem'}
      />

      <UserModal
        title='Asociar Proyecto'
        isModalVisible={isProjectModalVisible}
        handleSaveModal={toggleProjectModal}
        handleCancelModal={toggleProjectModal}
        saveText={''}
        children={
          <AttachProject
            projects={projects}
            isRequesting={isRequestingProjects}
            userId={uuid}
            onFetch={fetchProfiles}
            onFetchProjects={fetchProjects}
          />
        }
      />

      <TopActionsWrap>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <TableActionButton
            onToggleModal={toggleModal}
            title='Editar Datos del Usuario'
          >
            <BsJournalText size={28} color={theme.colors.graySix} />
          </TableActionButton>

          <TableActionButton
            title='Asociar Proyecto'
            onToggleModal={toggleProjectModal}
          >
            <IoAttach size={28} color={theme.colors.graySix} />
          </TableActionButton>
        </div>

        <TableSearcher
          columns={columns}
          onSubmit={handleSearch}
          searching={searching}
          onFetch={fetchProfiles}
        />
      </TopActionsWrap>
    </>
  );
}

export default ProfilesActions;
