import React from 'react';
import { memo } from 'react';
import { FixedWrap, ButtonStyled } from './Wraps';
import { Tooltip } from 'antd';
import { FaBars } from 'react-icons/fa';
import PropTypes from 'prop-types';

function OpenMenu({ onOpen, visible }) {
  const toolTip = visible ? 'Cerrar menú' : 'Abrir menú';
  return (
    <FixedWrap onClick={() => onOpen()}>
      <Tooltip placement='left' title={toolTip}>
        <ButtonStyled size='large' shape='circle'>
          <FaBars size={28} />
        </ButtonStyled>
      </Tooltip>
    </FixedWrap>
  );
}

OpenMenu.propTypes = {
  onOpen: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default memo(OpenMenu);
