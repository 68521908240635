import { Button } from 'antd';
import { FloatBackground } from 'components';
import { useNavigate } from 'react-router-dom';
import { ExpirationLinkWrap, ResultStyled } from './Wraps';

function SuccessfulRegistration({
  loginRoute = '/login',
  openGmailRoute = 'https://mail.google.com/mail/u/0/#inbox',
}) {
  const navigate = useNavigate();

  const gotoLogin = () => navigate(loginRoute);
  return (
    <ExpirationLinkWrap>
      <FloatBackground />
      <ResultStyled
        status='success'
        title='¡Registro Realizado Exitosamente!'
        subTitle='Por favor verifica tu correo y comienza a disfrutar de nuestros beneficios.'
        extra={[
          <Button type='primary' key='login' onClick={gotoLogin}>
            Iniciar Sesión
          </Button>,

          <a
            href={openGmailRoute}
            target='_blank'
            rel='noreferrer'
            key='goto-email'
          >
            <Button key='gmail'>Verificar Correo</Button>
          </a>,
        ]}
      />
    </ExpirationLinkWrap>
  );
}

export default SuccessfulRegistration;
