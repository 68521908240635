import styled from 'styled-components';

export const TopBarIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  width: 100%;
  background-color: ${(props) => props.bgColor || '#fff'};
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: auto;
`;

export const ContentWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & > h1 {
    color: ${(props) => props.color || '#000'};
    font-size: 2.6rem;
    margin: 0;
  }
`;

export const GoBackWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 4%;
  left: 10%;
  z-index: 10;
  cursor: pointer;

  & > span {
    font-weight: bold;
  }
`;
