import { optUsersService } from '../../services';
import { setSignIn, setReqError } from './signin-slice';
const SERVICE_PATH = {
  signIn: '/auth/login',
};

export function singIn (attrs) {
  return async (dispatch) => {
    try {
      const res = await optUsersService.post(SERVICE_PATH.signIn, attrs);
      const authData = getAuthData(res.data);
      dispatch(setSignIn(authData));
    } catch {
      dispatch(setReqError({ isBadReq: true }));
    }
  };
}

export function getAuthData (payload) {
  return {
    token: payload?.token || null,
    ttl: payload?.ttl || 0,
    role: payload?.role || null,
  };
}
