import styled from 'styled-components';

export const AutoScreenWrap = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const CustomAutoScreenWrap = styled(AutoScreenWrap)`
  overflow-y: scroll;
`;
