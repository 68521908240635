import { List } from 'antd';
import { ListItemStyled } from './Wraps';
import { IoPencilSharp, IoCloseSharp } from 'react-icons/io5';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

function ListData({ items, type, onEdition, onElimination }) {
  const theme = useTheme();
  const currentType = type.toLowerCase();

  const handleEdition = (item) => {
    onEdition(item, currentType);
  };

  const handleElimination = (id) => {
    const filterItem = items.filter((item) => item.id !== id);
    onElimination(currentType, filterItem);
  };

  return (
    <List
      size='small'
      header={null}
      footer={null}
      bordered
      dataSource={items}
      renderItem={(item) => (
        <ListItemStyled>
          {currentType === 'vehiculos' ? (
            <>
              <span className='item-info'>
                {item.license} / {item.carModel}
              </span>
              <div className='item-acctions'>
                <div className='edition' onClick={() => handleEdition(item)}>
                  <span>Editar</span>
                  <span>
                    <IoPencilSharp color={theme.colors.yellowSix} />
                  </span>
                </div>
                <div
                  className='elimination'
                  onClick={() => handleElimination(item.id)}
                >
                  <span>Eliminar</span>
                  <span>
                    <IoCloseSharp color={theme.colors.redFive} />
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <span className='item-info'>
                {item.petName} / {item.dogBreed}
              </span>
              <div className='item-acctions'>
                <div className='edition' onClick={() => handleEdition(item)}>
                  <span>Editar</span>
                  <span>
                    <IoPencilSharp color={theme.colors.yellowSix} />
                  </span>
                </div>
                <div
                  className='elimination'
                  onClick={() => handleElimination(item.id)}
                >
                  <span>Eliminar</span>
                  <span>
                    <IoCloseSharp color={theme.colors.redFive} />
                  </span>
                </div>
              </div>
            </>
          )}
        </ListItemStyled>
      )}
    />
  );
}

ListData.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onEdition: PropTypes.func.isRequired,
};
export default ListData;
