import { createSlice } from '@reduxjs/toolkit';
import { PROJECT_KEY, PROFILE_KEY } from '../../constants';

const initialState = {
  profiles: [],
  profile: null,
  isResidentialSet: false,
  homeInfo: null,
  isMakingRequest: true,
  profilesByUser: [],
  isGettingProfilesByUser: false,
  profileWithRelations: null,
  isGettingProfileWithRelations: false,
};

const reducers = {
  setProfiles: (state, action) => {
    const { profiles } = action.payload;
    state.profiles = profiles;

    if (profiles.length > 0) {
      localStorage.setItem(
        PROJECT_KEY,
        JSON.stringify(profiles[0]['project'].id)
      );
      localStorage.setItem(PROFILE_KEY, JSON.stringify(profiles[0].profileId));
    }
  },
  saveResidential: (state, action) => {
    const { residentialId, profileId } = action.payload;
    localStorage.setItem(PROJECT_KEY, JSON.stringify(residentialId));
    localStorage.setItem(PROFILE_KEY, JSON.stringify(profileId));
    state.isResidentialSet = true;
  },
  cleanResidential: (state, _action) => {
    state.isResidentialSet = false;
  },
  setHomeInfo: (state, action) => {
    state.homeInfo = action.payload.info;
  },
  setRequestProgress: (state, action) => {
    state.isMakingRequest = action.payload;
  },
  setProfilesByUser: (state, action) => {
    state.profilesByUser = action.payload?.profilesByUser ?? [];
  },
  setProfilesByUserRequest: (state, action) => {
    state.isGettingProfilesByUser = action.payload.status;
  },
  setProfileWithRelations: (state, action) => {
    state.profileWithRelations = action.payload?.data ?? null;
  },
  setProfileWithRelationsRequest: (state, action) => {
    state.isGettingProfileWithRelations = action.payload.status;
  },
};

const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers,
});
const { actions, reducer } = profilesSlice;

// ACTIONS
export const {
  setProfiles,
  saveResidential,
  cleanResidential,
  setHomeInfo,
  setRequestProgress,
  setProfilesByUser,
  setProfilesByUserRequest,
  setProfileWithRelations,
  setProfileWithRelationsRequest,
} = actions;
// GETTERS
export const selectProfilesState = (state) => state.profilesManagement;
export const selectHomeInfo = (state) => state.profilesManagement.homeInfo;
export const selectProjectId = () => {
  try {
    const profile = localStorage.getItem(PROJECT_KEY);
    if (!profile) return null;
    return JSON.parse(profile);
  } catch {
    return null;
  }
};
export const selectProfileId = () => {
  try {
    const profile = localStorage.getItem(PROFILE_KEY);
    if (!profile) return null;
    return JSON.parse(profile);
  } catch {
    return null;
  }
};
export function getResidentialId() {
  try {
    const residentialId = localStorage.getItem(PROJECT_KEY);
    if (!residentialId) return null;
    const mapped = JSON.parse(residentialId);
    return mapped;
  } catch {
    return null;
  }
}
export const selectProfilesByUser = (state) =>
  state.profilesManagement.profilesByUser;
export const selectIsGettingProfilesByUser = (state) =>
  state.profilesManagement.isGettingProfilesByUser;
export const selectProfileWithRelations = (state) =>
  state.profilesManagement.profileWithRelations;
export const selectIsGettingProfilesWithRelations = (state) =>
  state.profilesManagement.isGettingProfileWithRelations;

export default reducer;
