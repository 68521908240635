import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { SizeWrap, SpaceYWrap } from './Wraps';
import { TopBarIcon, Logo, LogOutCard } from 'components';

function MainHomeModule({ theme, isHome, onLogOut, children }) {
  return (
    <>
      <TopBarIcon
        text='Inicio'
        bgColor={theme.colors.primary}
        color={theme.colors.white}
        noActions={false}
        isHome={isHome}
      />
      <Row justify='center' align='center'>
        <Col
          xs={{ span: 20 }}
          sm={{ span: 18 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          xl={{ span: 6 }}
        >
          <SpaceYWrap>
            <Logo />
          </SpaceYWrap>
        </Col>
      </Row>
      <SizeWrap>
        <Row justify='center' align='center'>
          <Col
            xs={{ span: 20 }}
            md={{ span: 20 }}
            lg={{ span: 18 }}
            xl={{ span: 16 }}
            xxl={{ span: 12 }}
          >
            <Row
              justify='center'
              align='center'
              gutter={[16, 16]}
              style={{ height: 'inherit' }}
            >
              {children}
              <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }}>
                <LogOutCard onClick={onLogOut} />
              </Col>
            </Row>
          </Col>
        </Row>
      </SizeWrap>
    </>
  );
}

MainHomeModule.propTypes = {
  theme: PropTypes.object.isRequired,
  isHome: PropTypes.bool.isRequired,
  onLogOut: PropTypes.func.isRequired,
};
export default MainHomeModule;
