export const formRules = {
  username: [
    { transform: (value) => value.trim() },
    {
      required: true,
      message: '¡Por favor ingresa tú nombre de usuario o email!',
    },
    {
      whitespace: true,
      message: '¡El nombre de usuario no puede estar vacío!',
    },
    {
      min: 8,
      message: '¡El nombre de usuario debe tener minimo 8 caracteres!',
    },
    {
      max: 80,
      message: '¡El nombre de usuario debe tener máximo 50 caracteres!',
    },
  ],
  password: [
    { transform: (value) => value.trim() },
    {
      required: true,
      message: '¡Por favor ingresa tú contraseña!',
    },
    {
      min: 8,
      message: '¡La contraseña debe tener minima 8 caracteres!',
    },
    {
      max: 80,
      message: 'La contraseña debe tener maximo 80 caracteres!',
    },
    {
      whitespace: true,
      message: '¡La contraseña no puede estar vacía!',
    },
  ],
};
