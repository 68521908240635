import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* 1rm = 10px */
  scrollbar-width: auto;
  scrollbar-color: #cbcbcb #616161;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

.home-user-profile-image {
    object-fit: cover;
    object-position: center;
  }

*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: #fff;
}

/* @media (min-width: 992px) {
  *::-webkit-scrollbar {
    width: 1rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
  }

  *::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 1rem;
  }
} */

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: ${(props) => props.theme.colors.primaryNeon};
}

*::selection {
    background: ${(props) => props.theme.colors.primaryNeon} !important;
  }
`;

export default GlobalStyles;
