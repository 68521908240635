import styles from './FloatBackground.module.css';

function FloatBackground() {
  return (
    <ul className={styles.background}>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  );
}

export default FloatBackground;
