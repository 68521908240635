import styled from 'styled-components';

export const TopBarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12vh;
  width: 120%;
  transform: translateX(-9%);
  background-color: #006634;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;

  & > h1 {
    font-size: 4.2rem;
    color: #fff;
  }
`;
