import styled from 'styled-components';
import { Typography } from 'antd';

export const AdministratorHomeWrap = styled.div``;

export const SizeWrap = styled.div`
  margin: 3rem auto;
  height: 100%;
  /* @media (min-width: 992px) {
    & > div {
      height: 100%;

      & > div {
        height: 22rem;
      }
    }
  } */
`;

export const SpaceYWrap = styled.div`
  margin: 4rem auto 0 auto;
  text-align: center;
  display: none;

  @media (min-width: 992px) {
    display: block;
    /* margin: 6rem auto 0 auto; */
  }
`;

export const Container = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const TitleStyled = styled(Typography.Title)`
  color: #707070;
  text-align: center;
  margin: 2rem 0 0 0;
`;

export const BottomBarStyled = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  height: 16vh;
  width: 100%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.1);
`;
