import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { LinksContainer } from './Wraps';
import { Tooltip, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { IoLinkOutline } from 'react-icons/io5';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from 'styled-components';
import { TopBarIcon, ModalStyled, HttpSpinner } from 'components';
import {
  getResources,
  selectProjectId,
  selectIsAuthenticated,
  selectUserRole,
  deleteResource,
} from 'redux/index';
import { CircleBtnStyled } from '../profile-wall/Wraps';
import ResourceModalContent from './modal-content';
import { RESIDENT_MODULE, ERROR, SUCCESS } from 'constants';
import ResourceItem from './resource-item';
import { openNotification } from 'helpers/notifications';

function Resources() {
  const effectRan = useRef(false);
  const { colors } = useTheme();
  const [page, setPage] = useState(0);
  const role = useSelector(selectUserRole);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMakingRequest, setIsMakingReq] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [resources, setRecources] = useState([]);
  const [edition, setEdition] = useState(null);
  const token = useSelector(selectIsAuthenticated);
  const residentialId = useSelector(selectProjectId);
  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => {
    setEdition(null);
    setIsModalVisible(false);
  };

  const handleRequest = async () => {
    setIsMakingReq(true);
    const { resources } = await getResources({
      page,
      residentialId,
      token,
    });
    if (resources.length === 0 || resources.length < 20) setHasMore(false);
    else setHasMore(true);
    setRecources(resources);
    setIsMakingReq(false);
  };
  useEffect(() => {
    if (effectRan.current === false) {
      handleRequest();
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isMakingRequest) return <HttpSpinner />;

  const handleCreation = (newResource) => {
    setRecources([newResource, ...resources]);
    handleCancel();
  };

  const handleDelete = async (id) => {
    const isDeleted = await deleteResource(id, token);
    if (isDeleted == null) {
      openNotification(
        ERROR,
        'Error al tratar de eliminar el recurso, Por favor intenta de nuevo.'
      );
      return;
    }
    setRecources((preValue) =>
      preValue.filter((resource) => resource.id !== id)
    );
    openNotification(SUCCESS, 'Recurso eliminado exitosamente.');
  };

  const handleEdit = (payload) => {
    showModal();
    setEdition(payload);
  };

  const handleUpdate = (updatedResource) => {
    handleCancel();
    const updatedResources = resources.map((resource) => {
      if (resource.id === updatedResource.id)
        return {
          id: updatedResource.id,
          name: updatedResource.name,
          link: updatedResource.link,
        };
      return resource;
    });
    setRecources(updatedResources);
  };

  const fetchData = async () => {
    const currentPage = page + 1;
    const { resources } = await getResources({
      page: currentPage,
      residentialId,
      token,
    });
    if (resources.length === 0 || resources.length < 20) setHasMore(false);
    else setHasMore(true);
    setPage(currentPage);
    setRecources((preValue) => [...preValue, ...resources]);
  };

  const allResources =
    resources.length === 0 ? (
      <p style={{ textAlign: 'center' }}>
        Sin recursos, por favor comienza agregando una.
      </p>
    ) : (
      <InfiniteScroll
        dataLength={resources.length}
        next={fetchData}
        hasMore={hasMore}
        scrollableTarget='scrollableDiv'
        endMessage={<h4>No hay más recursos para mostrar.</h4>}
        style={{ overflow: 'hidden' }}
      >
        <>
          {resources.map((resource, i) => (
            <ResourceItem
              key={i}
              id={resource.id}
              name={resource.name}
              link={resource.link}
              role={role}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          ))}
        </>
      </InfiniteScroll>
    );

  return (
    <>
      {role !== RESIDENT_MODULE && (
        <>
          <Tooltip
            placement='left'
            title='Crear recurso'
            style={{ transition: 'all 300ms' }}
            onClick={showModal}
          >
            <CircleBtnStyled
              type='primary'
              shape='circle'
              icon={<PlusOutlined />}
              size={28}
            />
          </Tooltip>
        </>
      )}

      <ModalStyled
        footer={null}
        title='Registrar nuevo recurso'
        onOk={handleOk}
        onCancel={handleCancel}
        visible={isModalVisible}
      >
        <ResourceModalContent
          onCreation={handleCreation}
          token={token}
          edition={edition}
          onUpdate={handleUpdate}
        />
      </ModalStyled>

      <TopBarIcon
        text='Recursos'
        bgColor={colors.primary}
        color={colors.white}
        icon={<IoLinkOutline size={50} color={colors.white} />}
      />
      <LinksContainer marginCalc='2rem' id='scrollableDiv'>
        <Row justify='center' align='center'>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18 }}
            md={{ span: 14 }}
            lg={{ span: 10 }}
          >
            {allResources}
          </Col>
        </Row>
      </LinksContainer>
    </>
  );
}

export default Resources;
