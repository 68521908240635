import { TableActionButton, TableSearcher, TopActionsWrap } from 'components';
import { IoPersonAddOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

function UsersActions({ fetchUsers, theme, searching }) {
  const navigate = useNavigate();

  const handleSearch = (formData) => {
    const { column, searching: searchingInput } = formData;

    if (
      !column ||
      column === undefined ||
      !searchingInput ||
      searchingInput === undefined
    ) {
      searching.current = {};
      return;
    }

    const newSearching = {
      searchingCol: column,
      searchingVal: searchingInput.trim().split(' ').join('-'),
    };

    searching.current = newSearching;
    fetchUsers({
      page: 1,
      limit: 10,
    });
  };

  const columns = [
    // the key must be match with the field in db
    { name: 'Nombres y Apellidos', key: 'fullName', type: 'string' },
    { name: 'Correo', key: 'email', type: 'string' },
    { name: 'Telefono', key: 'phoneNumber', type: 'string' },
  ];

  const onCreation = () => navigate('crear');

  return (
    <>
      <TopActionsWrap>
        <TableActionButton title='Crear nuevo usuario' onClick={onCreation}>
          <IoPersonAddOutline size={24} color={theme.colors.graySix} />
        </TableActionButton>

        <TableSearcher
          columns={columns}
          onSubmit={handleSearch}
          searching={searching}
          onFetch={fetchUsers}
          defaultValues={{}}
        />
      </TopActionsWrap>
    </>
  );
}

export default UsersActions;
