import styled from 'styled-components';
import { Card } from 'antd';

export const CardSideFront = styled(Card)`
  width: 100%;
  border-radius: 2.6rem;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.11);
  transition: all 0.8s;
  position: relative;
  z-index: 1000;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  & img {
    object-fit: cover;
    object-position: center;
  }
`;
