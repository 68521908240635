import styled from 'styled-components';
import { MovileContainerStyled } from '../../../components';
import { InputNumber, Button } from 'antd';

export const NewBookingContainer = styled(MovileContainerStyled)`
  margin-top: 0;
`;

export const DatePickerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const InputNumberCustom = styled(InputNumber)`
  width: 100%;
`;

export const CardWrap = styled.div`
  div {
    background-color: #006634;
    color: #fff;
    padding: 1rem 2.8rem 2.4rem 2.8rem;

    & div:last-child {
      padding: 0;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  border-radius: 12px;
  margin: 2rem auto;
`;

export const SubTitleStyled = styled.h2`
  margin-top: 2rem;
  font-size: 2.4rem;
  text-align: center;
  color: #707070;

  @media (min-width: 992px) {
    margin-top: 3rem;
    font-size: 2.8rem;
  }
`;
