import { fetchWithToken } from './assets-helper';
import { getResidentialId } from '../profiles/profiles-slice';
//const wordpoint = getResidentialId();

export async function getAssets (asset) {
  const wordpoint = getResidentialId();
  const endpoint = asset ? `${wordpoint}/${asset}` : wordpoint;
  try {
    const res = await fetchWithToken({
      endpoint,
      method: 'GET',
    });
    const data = await res.json();

    return data;
  } catch (error) {
    console.log(error?.data?.message);
  }
}

export async function assetUpload (payload) {
  try {
    const res = await fetchWithToken({
      endpoint: 'upload',
      data: payload,
      method: 'POST',
    });
    const data = await res.json();

    return data;
  } catch (error) {
    console.log(error);
    console.log(error?.data?.message);
  }
}

export async function updateAsset (payload) {
  try {
    const res = await fetchWithToken({
      data: payload,
      method: 'PUT',
    });
    const data = await res.json();

    return data;
  } catch (error) {
    console.log(error?.data?.message);
  }
}

export async function cresteAsset (payload) {
  const wordpoint = getResidentialId();
  payload.workPoint = wordpoint;

  try {
    const res = await fetchWithToken({ data: payload, method: 'POST' });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error?.data?.message);
  }
}

export async function deleteAsset ({ uuid, wordpoint }) {
  try {
    const res = await fetchWithToken({ endpoint: uuid, method: 'DELETE' });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error?.data?.message);
  }
}

export async function assetUpdateState (payload) {
  try {
    await fetchWithToken({
      endpoint: 'updateStatus',
      data: payload,
      method: 'PUT',
    });
    return true;
  } catch {
    return null;
  }
}
