import es_ES from 'antd/lib/locale/es_ES';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import App from './App';
import GlobalStyles from './services/styled-components/global-styles';
import theme from './services/styled-components/theme';

if (
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'prod'
) {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ConfigProvider locale={es_ES}>
            <App />
          </ConfigProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
