import {
  validateUniqueEmail,
  validateUniqueUsername,
  validateUniquePhone,
} from 'redux/index';
import { getToken } from 'helpers/localstorage';
import { mapShippingUsername } from '../helpers';

export const userEditionRules = {
  userName: [
    { transform: (value) => mapShippingUsername(value) },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 8,
    },
    {
      max: 100,
    },
    (form) => ({
      async validator(_, value) {
        const valueLen = value?.length;
        if (valueLen < 8) return Promise.reject();
        if (valueLen > 100) return Promise.reject();
        const userId = form.getFieldValue('userId');
        const mappedValue = mapShippingUsername(value);
        form.setFieldsValue({
          userName: mappedValue,
        });
        const res = await validateUniqueUsername(mappedValue, getToken());
        if (res.available === false) {
          if (res.id === userId) return Promise.resolve();
          return Promise.reject(new Error('El nombre de usuario esta en uso.'));
        }
        return Promise.resolve();
      },
    }),
  ],
  email: [
    { transform: (value) => value.trim() },
    {
      type: 'email',
    },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 100,
    },
    (form) => ({
      async validator(_, value) {
        const valueLen = value?.length;
        if (valueLen < 6) return Promise.resolve();
        if (valueLen > 150) return Promise.resolve();
        // eslint-disable-next-line no-useless-escape
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value))
          return Promise.reject();
        const userId = form.getFieldValue('userId');
        const res = await validateUniqueEmail(value.trim(), getToken());
        if (res.available === false) {
          if (res.id === userId) return Promise.resolve();
          return Promise.reject(new Error('El email ya está en uso.'));
        }
        return Promise.resolve();
      },
    }),
  ],
  cellphone: [
    { transform: (value) => value.trim() },
    {
      type: 'string',
      required: true,
      pattern: /3[0-9]{9}$/gm,
      message: 'El Numero Celular debe contender 10 números',
    },
    (form) => ({
      async validator(_, value) {
        const regex = /3[0-9]{9}$/gm;
        if (value == null || value === '' || !regex.test(value)) return;

        const res = await validateUniquePhone(value, getToken());
        if (res === null)
          return Promise.reject(
            new Error(
              'Error al verificar el número, por favor comunícate con soporte.'
            )
          );
        const userId = form.getFieldValue('userId');
        if (res.available === false) {
          if (res.id === userId) return Promise.resolve();
          return Promise.reject(
            new Error(
              'El número celular ya está en uso, por favor ingresa un número distinto.'
            )
          );
        }

        return Promise.resolve();
      },
    }),
  ],
};
