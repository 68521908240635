import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Typography, Grid } from 'antd';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { TopBarIcon, HttpSpinner } from 'components';
import { PostContainer } from '../Wraps';
import {
  CreatedByContainer,
  PostHeaderContainer,
  SheetBorder,
  CustomColumn,
} from './Wraps';
import { getPost } from 'redux/index';

function ProfileWallDetail() {
  const screens = Grid.useBreakpoint();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const handleRequest = useCallback(async () => {
    setIsMakingRequest(true);
    const post = await getPost(uuid);
    if (!post) {
      navigate(-1);
      return;
    }
    setPost(post);
    setIsMakingRequest(false);
  }, [uuid, navigate]);
  useEffect(() => {
    handleRequest();
    return () => setIsMakingRequest(false);
  }, [handleRequest]);
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  if (isMakingRequest || !post) return <HttpSpinner />;
  const date = new Date(post.post.createdAt);
  const createAt = `${date.getDate()} - ${
    months[date.getMonth()]
  } - ${date.getFullYear()}`;

  const noUserImage = '/assets/images/no-img.png';

  let titleResponsive = '';
  if (screens.xs === true) titleResponsive = 'V. Publicación';
  else titleResponsive = 'Ver Publicación';

  return (
    <>
      <TopBarIcon
        text={titleResponsive}
        icon={<IoChatbubbleEllipsesOutline size={40} />}
      />
      <PostContainer>
        <Row align='center' justify='center'>
          <CustomColumn
            xs={{ span: 24 }}
            sm={{ span: 20 }}
            lg={{ span: 16 }}
            xl={{ span: 12 }}
            xxl={{ span: 10 }}
          >
            <SheetBorder>
              <CreatedByContainer>
                <span className='img-container'>
                  <img
                    src={post.user.image ?? noUserImage}
                    alt={post.user.name}
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                </span>
                <span className='user-data-container'>
                  <p className='user-name'>{post.user.name}</p>
                  <p className='published'>{createAt}</p>
                </span>
              </CreatedByContainer>
              <PostHeaderContainer>
                <Typography.Title level={2} className='main-title'>
                  {post.post.title}
                </Typography.Title>
                <div className='img-container'>
                  <img src={post.post.image} alt={post.post.title} />
                </div>
                <div className='paragraph-container'>
                  <p
                    style={{
                      wordBreak: 'break-all',
                      lineHeight: '3rem',
                    }}
                  >
                    {post.post.description}
                  </p>
                </div>
              </PostHeaderContainer>
            </SheetBorder>
          </CustomColumn>
        </Row>
      </PostContainer>
    </>
  );
}
export default ProfileWallDetail;
