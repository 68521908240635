import { NavLink } from 'react-router-dom';
import {
  IoCalendar,
  IoPeopleSharp,
  IoChatbubbleEllipsesSharp,
  IoLinkSharp,
  IoIdCard,
  IoCashSharp,
  IoHome,
  IoDocumentAttach,
  IoPaperPlaneSharp,
} from 'react-icons/io5';
import { moduleKeys } from './constants';
const {
  keyInit,
  keyProfile,
  keyWall,
  keyBooking,
  keyResources,
  keyAssets,
  keyUsers,
  keyBulkUpload,
  dynamicLinks,
} = moduleKeys;

export function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const allModules = [
  getItem(
    'Inicio',
    keyInit,
    <NavLink to='inicio'>
      <IoHome color='#fff' size={24} />
    </NavLink>
  ),
  getItem(
    'Perfil',
    keyProfile,
    <NavLink to='#'>
      <IoIdCard color='#fff' size={24} />
    </NavLink>
  ),
  getItem(
    'Muro',
    keyWall,
    <NavLink to='muro'>
      <IoChatbubbleEllipsesSharp color='#fff' size={24} />
    </NavLink>
  ),
  getItem(
    'Reservas',
    keyBooking,
    <NavLink to='reservas'>
      <IoCalendar color='#fff' size={24} />
    </NavLink>
  ),
  getItem(
    'Recursos',
    keyResources,
    <NavLink to='recursos'>
      <IoLinkSharp color='#fff' size={24} />
    </NavLink>
  ),
  getItem(
    'Gestión de activos',
    keyAssets,
    <NavLink to='activos'>
      <IoCashSharp color='#fff' size={24} />)
    </NavLink>
  ),
  getItem(
    'Gestión de usuarios',
    keyUsers,
    <NavLink to='usuarios'>
      <IoPeopleSharp color='#fff' size={24} />
    </NavLink>
  ),
  getItem(
    'Carga masiva de usuarios',
    keyBulkUpload,
    <NavLink to='cargas-masivas/usuarios'>
      <IoDocumentAttach color='#fff' size={24} />
    </NavLink>
  ),
  getItem(
    'Enlaces Dinámicos',
    dynamicLinks,
    <NavLink to='enlaces'>
      <IoPaperPlaneSharp color='#fff' size={24} />
    </NavLink>
  ),
];

export const superAdminModules = [
  keyInit,
  keyProfile,
  keyWall,
  keyBooking,
  keyResources,
  keyAssets,
  keyUsers,
  keyBulkUpload,
  dynamicLinks,
];

export const adminModules = [
  keyInit,
  keyProfile,
  keyWall,
  keyBooking,
  keyResources,
  keyAssets,
  keyUsers,
  keyBulkUpload,
];
export const residentModules = [
  keyInit,
  keyProfile,
  keyWall,
  keyBooking,
  keyResources,
];
export const operatorModules = [
  keyInit,
  keyProfile,
  keyWall,
  keyBooking,
  keyResources,
];
