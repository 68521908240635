export const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

export function logoutUser() {
  localStorage.clear();
  window.location.reload();
}
