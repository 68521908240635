import styled from 'styled-components';
import CountryPhoneInput from 'antd-country-phone-input';
import { MovileContainerStyled } from 'components';
import { InputNumber, Form } from 'antd';

export const UserCreationContainer = styled(MovileContainerStyled)`
  padding: 0;
  overflow-x: hidden;
  height: calc(100% - 11rem - ${(props) => props.marginCalc || '0rem'});
  margin-top: ${(props) => props.marginCalc || '0'};
  overflow-y: ${(props) => (props.marginCalc ? 'scroll' : 'hidden')};
`;

export const DatePickerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const InputNumberCustom = styled(InputNumber)`
  width: 100%;
`;

export const CardWrap = styled.div`
  div {
    background-color: #006634;
    color: #fff;
    padding: 1rem 2.8rem 2.4rem 2.8rem;

    & div:last-child {
      padding: 0;
    }
  }
`;

export const SubTitleStyled = styled.h2`
  margin-top: 2rem;
  font-size: 2.4rem;
  text-align: center;
  color: #707070;

  @media (min-width: 992px) {
    margin-top: 3rem;
    font-size: 2.8rem;
  }
`;

export const CountryPhoneInputStyled = styled(CountryPhoneInput)`
  height: 3.8rem;
`;

export const FormItemStyled = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const VePeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`;
