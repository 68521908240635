import { useEffect } from 'react';
import { Form, Col, Row, DatePicker, Input } from 'antd';
import { ButtonStyled } from 'components';
import { petForm } from './form-rules';
import { preventDefault } from 'helpers/forms';
import PropTypes from 'prop-types';

function PetForm({ onPets, onEdition, edition }) {
  const [form] = Form.useForm();
  const onFinish = (data) => {
    if (edition == null) onPets(data);
    else onEdition({ id: edition?.id, ...data });
    onReset();
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFill = () => {
    form.setFieldsValue({
      petColor: edition?.petColor,
      dogBreed: edition?.dogBreed,
      petName: edition?.petName,
      petBirthday: edition?.petBirthday,
    });
  };

  useEffect(() => {
    if (edition != null) onFill();
    return () => {
      onReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edition]);

  return (
    <Form size='large' onFinish={onFinish} autoComplete='off' form={form}>
      <Row justify='center' align='center' gutter={[16, {}]}>
        <Col xs={{ span: 22 }} sm={{ span: 11 }}>
          <Form.Item
            label='Color'
            name='petColor'
            required
            tooltip='Por favor escribe el color de la mascota.'
            style={{
              display: 'block',
            }}
            hasFeedback
            rules={petForm.vehicleColor}
          >
            <Input
              placeholder='Escribe aquí...'
              onPressEnter={preventDefault}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 22 }} sm={{ span: 11 }}>
          <Form.Item
            label='Raza de la mascota'
            name='dogBreed'
            tooltip='Por favor escribe la raza de la mascota.'
            style={{
              display: 'block',
            }}
            hasFeedback
            rules={petForm.dogBreed}
          >
            <Input
              placeholder='Escribe aquí...'
              onPressEnter={preventDefault}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center' align='center'>
        <Col xs={{ span: 22 }}>
          <Form.Item
            label='Nombre'
            name='petName'
            required
            tooltip='Por favor escribe el nombre de la mascota.'
            style={{
              display: 'block',
            }}
            hasFeedback
            rules={petForm.petName}
          >
            <Input
              placeholder='Escribe aquí...'
              onPressEnter={preventDefault}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 22 }}>
          <Form.Item
            label='Fecha de nacimiento'
            name='petBirthday'
            tooltip='Por favor seleccionar el año de nacimiento.'
            style={{
              display: 'block',
            }}
            hasFeedback
            rules={petForm.petBirthday}
          >
            <DatePicker
              onChange={null}
              style={{
                display: 'block',
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={{ span: 16 }}>
          <ButtonStyled type='primary' size='large' htmlType='submit' block>
            Agregar Mascota
          </ButtonStyled>
        </Col>
      </Row>
    </Form>
  );
}

PetForm.propTypes = {
  onPets: PropTypes.func.isRequired,
  onEdition: PropTypes.func.isRequired,
  edition: PropTypes.object,
};

export default PetForm;
