import { useRef, useState } from 'react';
import UserActions from '../tab-actions/UserActions';
import UsersTable from '../tables/UsersTable';
import { getAllUsers } from 'redux/index';

function UsersTab({ theme, token }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const searching = useRef({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const fetchUsers = async (params = {}) => {
    setLoading(true);
    Object.assign(params, searching.current);

    const res = await getAllUsers(params);

    if (res == null) {
      setUsers([]);
      setLoading(false);
      return;
    }
    const { users, total } = res;
    setUsers(users);
    setPagination({
      current: params.page,
      pageSize: params.limit,
      total,
    });
    setLoading(false);
  };

  return (
    <>
      <UserActions fetchUsers={fetchUsers} searching={searching} />
      <UsersTable
        theme={theme}
        token={token}
        users={users}
        loading={loading}
        pagination={pagination}
        fetchUsers={fetchUsers}
      />
    </>
  );
}

export default UsersTab;
