import { useState } from 'react';
import LinksForm from '../form/LinksForm';
import {
  UserModal,
  TableActionButton,
  TableSearcher,
  TopActionsWrap,
} from 'components';
import { MdAddLink } from 'react-icons/md';

function LinkActions({ token, fetchLinks, theme, projectId, searching }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => setIsModalVisible((preValue) => !preValue);

  const handleSearch = (formData) => {
    const { column, searching: searchingInput } = formData;

    if (
      !column ||
      column === undefined ||
      !searchingInput ||
      searchingInput === undefined
    ) {
      searching.current = {};
      return;
    }

    const newSearching = {
      searchingCol: column,
      searchingVal: searchingInput.trim().split(' ').join('-'),
    };

    searching.current = newSearching;
    fetchLinks({
      page: 1,
      limit: 10,
    });
  };

  const columns = [{ name: 'Nombre', key: 'name', type: 'string' }];

  return (
    <>
      <UserModal
        title='Crear Enlace'
        isModalVisible={isModalVisible}
        handleSaveModal={toggleModal}
        handleCancelModal={toggleModal}
        saveText={''}
        width='60rem'
      >
        <LinksForm
          token={token}
          fetchLinks={fetchLinks}
          projectId={projectId}
        />
      </UserModal>
      <TopActionsWrap>
        <TableActionButton
          onToggleModal={toggleModal}
          title='Crear nuevo enlace'
        >
          <MdAddLink size={30} color={theme.colors.graySix} />
        </TableActionButton>
        <TableSearcher
          columns={columns}
          onSubmit={handleSearch}
          searching={searching}
          onFetch={fetchLinks}
          defaultValues={{ column: 'name' }}
        />
      </TopActionsWrap>
    </>
  );
}

export default LinkActions;
