import { TopBarIconWrap, ContentWrap, GoBackWrap } from './Wraps';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function TopBarIcon({
  icon,
  text,
  bgColor = null,
  color = null,
  noActions = null,
  isHome = null,
  className = null,
}) {
  const navigate = useNavigate();

  const handlePrePage = () => {
    if (isHome === true) navigate('/dashboard');
    else navigate(-1);
  };

  return (
    <TopBarIconWrap bgColor={bgColor} className={className}>
      <ContentWrap color={color}>
        <span>{icon}</span>
        <h1>{text}</h1>
      </ContentWrap>

      {!noActions && (
        <GoBackWrap onClick={handlePrePage}>
          <svg
            stroke={color ? color : '#000'}
            fill={color ? color : '#000'}
            strokeWidth='0'
            viewBox='0 0 512 512'
            width='30'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='48'
              d='M328 112L184 256l144 144'
            ></path>
          </svg>
          {/* <span>Volver</span> */}
        </GoBackWrap>
      )}
    </TopBarIconWrap>
  );
}
TopBarIcon.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  noActions: PropTypes.bool,
  isHome: PropTypes.bool,
};
export default TopBarIcon;
