import styled from 'styled-components';
import { TopBarIcon } from 'components';

export const TopBarIconStyled = styled(TopBarIcon)`
  box-shadow: none;
`;

export const LinkContainer = styled.div`
  overflow: hidden;
`;
