import { Tooltip } from 'antd';
import {
  EditOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  MailOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { IconStyled, ActionsWrap } from 'components';
import { confirmationModal } from 'helpers/confirmations';
import { deleteUser, verifyUser, updateUserData } from 'redux/index';
import { executeAndUpdateTableRecords } from 'helpers/tables';
import { useNavigate } from 'react-router-dom';

function UserActions({ theme, token, user, users, fetchUsers, pagination }) {
  const navigate = useNavigate();
  const getColor = (color) => theme.colors[color];

  const onDeleteUser = async () => {
    await executeAndUpdateTableRecords(
      users,
      () => deleteUser(user.id, token),
      pagination,
      {
        // failed: '',
        success: '',
      },
      fetchUsers,
      DeleteOutlined
    );
  };

  const deleteUserParams = [
    null, // title
    null, // content
    null, // okText
    null, // okType
    null, // cancelText
    null, // Icon
    onDeleteUser, // callback
  ];

  const iconsStyles = (color, size) => {
    return { color: color, fontSize: size };
  };

  const handleProfilesManagement = () =>
    navigate(`/dashboard/${user.id}/perfiles`);

  const handleEmailVerification = async () => {
    await executeAndUpdateTableRecords(
      users,
      () =>
        verifyUser({
          userId: user.id,
          payload: { status: user.status === true ? false : true },
        }),
      pagination,
      {
        failed:
          'Verificación fallida, Por favor recarga la página e intenta nuevamente.',
        success:
          user.status === true
            ? 'Email anulado exitosamente.'
            : 'Email verificado exitosamente.',
      },
      fetchUsers,
      MailOutlined
    );
  };

  const emailVerifyOptions = [
    user.status === true
      ? '¿Está seguro de Anular la verificación del email?'
      : '¿Está seguro de Verificar el email del usuario?',
    user.status === true
      ? 'Por favor, Recuerda que un usuario con email sin verificar no podrá iniciar sesión.'
      : 'Por favor, Recuerda que verificar el email significa que apruebas la veracidad y existencia de este.',
    user.status === true ? 'Anular' : 'Verificar',
    user.status === true ? 'danger' : 'primary',
    null,
    <MailOutlined style={{ color: getColor('limeSix') }} />,
    () => handleEmailVerification(),
  ];

  const handleuserVerification = async () => {
    await executeAndUpdateTableRecords(
      users,
      () =>
        updateUserData({
          id: user.id,
          payload: { userStatus: 'activo' },
        }),
      pagination,
      {
        failed:
          'Verificación fallida, Por favor recarga la página e intenta nuevamente.',
        success: 'Verificación de usuario ejecutada exitosamente.',
      },
      fetchUsers,
      MailOutlined
    );
  };

  const verifyUserOptions = [
    '¿Está seguro de Verificar al usuario?',
    'Por favor, Recuerda que esto significa que los datos del usuario son verdaderos, por lo tanto, se prosigue con la verificación o activación en el sistema.',
    'Verificar',
    'primary',
    null,
    <CheckCircleOutlined style={{ color: getColor('primaryNeon') }} />,
    () => handleuserVerification(),
  ];

  const handleUserRejection = async () => {
    await executeAndUpdateTableRecords(
      users,
      () =>
        updateUserData({
          id: user.id,
          payload: { userStatus: 'rechazado' },
        }),
      pagination,
      {
        failed:
          'Rechazo fallido, Por favor recarga la página e intenta nuevamente.',
        success: 'Usuario rechazado exitosamente.',
      },
      fetchUsers,
      ExclamationCircleOutlined
    );
  };
  const rejectionOptions = [
    '¿Está seguro de Rechazar al usuario?',
    'Por favor, Recuerda que esto significa que los datos del usuario no son verdaderos, por lo tanto, se prosigue con el rechazo o desactivación en el sistema.',
    'Rechazar',
    'danger',
    null,
    <ExclamationCircleOutlined style={{ color: getColor('redFive') }} />,
    () => handleUserRejection(),
  ];

  return (
    <>
      <ActionsWrap>
        {user.userStatus === 'activo' ? (
          <Tooltip title='Rechazar/Desactivar Usuario'>
            <IconStyled onClick={() => confirmationModal(...rejectionOptions)}>
              <CloseCircleOutlined style={{ color: getColor('redFive') }} />
            </IconStyled>
          </Tooltip>
        ) : (
          <Tooltip title='Verificar/Activar Usuario'>
            <IconStyled onClick={() => confirmationModal(...verifyUserOptions)}>
              <CheckCircleOutlined style={{ color: getColor('primaryNeon') }} />
            </IconStyled>
          </Tooltip>
        )}

        <Tooltip title='Verificar/Anular Email'>
          <IconStyled onClick={() => confirmationModal(...emailVerifyOptions)}>
            <MailOutlined style={{ color: getColor('blueSix') }} />
          </IconStyled>
        </Tooltip>

        <Tooltip title='Gestionar Perfiles'>
          <IconStyled onClick={handleProfilesManagement}>
            <EditOutlined
              style={{ ...iconsStyles(theme.colors.yellowSix, '2rem') }}
            />
          </IconStyled>
        </Tooltip>

        <Tooltip title='Eliminar Usuario'>
          <DeleteOutlined
            onClick={() => confirmationModal(...deleteUserParams)}
            style={{
              ...iconsStyles(theme.colors.redFive, '1.9rem'),
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </ActionsWrap>
    </>
  );
}

export default UserActions;
