export function mapOdooUsers(users) {
  const mappedUsers = users.map((user) => {
    const userData = {
      id: user.id,
      name: user.name.toLowerCase().trim(),
      email: user.email.trim(),
      active: true,
    };

    // street
    if (user.street && user.street !== false) userData.street = user.street;

    // set image
    if (user.image_1920 && user.image_1920 != null)
      userData.image = String(user.image_1920).trim();

    // set personal identification
    if (user.vat) userData.vat = user.vat.trim();

    // set mobile
    if (user.mobile !== false && user.mobile !== '0') {
      const mappedNumber = String(user.mobile)
        .trim()
        .split(' ')[0]
        .slice(0, 10);

      userData.mobile = `+57 ${mappedNumber}`;
    }

    // set gender
    if (user.gender && user.gender != null) userData.gender = user.gender;

    // set birthday
    if (user.birthday && user.birthday != null)
      userData.birthday = new Date(user.birthday);

    return userData;
  });

  return mappedUsers;
}

export function getOdooProjectDto(payload) {
  const projectData = {
    odooId: payload.id,
    status: true,
  };

  // set name
  if (payload.name) projectData.name = payload.name;

  // set start date
  if (payload.date_start !== false)
    projectData.startDate = new Date(payload.date_start).toISOString();

  // set end data
  if (payload.date !== false)
    projectData.endDate = new Date(payload.date).toISOString();

  return projectData;
}
