import styled from 'styled-components';
import { Button, Input } from 'antd';

export const FullScreenWrap = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const SpaceYWrap = styled.div`
  margin: 5rem auto;
  text-align: center;
`;

export const InputWrap = styled.div`
  & > input,
  & > span input {
    border-radius: 0.8rem;
  }
`;

export const ButtonStyled = styled(Button)`
  border-radius: 8px;
  margin-top: 3rem;
  padding: 0rem 1rem;
`;

export const InputStyled = styled(Input)`
  border-radius: 8px;
  padding: 0.8rem 1rem;
`;

export const PasswordStyled = styled(Input.Password)`
  border-radius: 8px;
  padding: 0.8rem 1rem;
`;
