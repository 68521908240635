import styled from 'styled-components';
import { MovileContainerStyled } from 'components';
import { Button, Tag } from 'antd';

export const BulkUploadContainer = styled(MovileContainerStyled)`
  height: calc(100% - 22% - ${(props) => props.marginCalc || '0rem'});
  @media (min-width: 992px) {
    height: calc(100% - 12% - ${(props) => props.marginCalc || '0rem'});
  }
`;
export const ButtonStyled = styled(Button)`
  border-radius: 12px;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
export const IconStyled = styled.div`
  cursor: pointer;
  font-size: 2rem;
  color: ${(props) => props.color};
  transition: all 300ms;
  &:hover {
    transform: scale(1.3);
  }

  @media (min-width: 992px) {
    margin-right: 10rem;
  }
`;
export const TagStyled = styled(Tag)`
  font-weight: bold;
  font-size: 1.4rem;
  opacity: 0.9;
  transition: all 300ms;
  &:hover {
    transform: scale(1.2);
  }
`;

export const TagDateStyled = styled(TagStyled)`
  opacity: 1;
  &:hover {
    transform: none;
  }
`;
