import {
  validateUniqueEmail,
  validateUniqueUsername,
  validateUniquePhone,
} from 'redux/index';
import { getToken } from 'helpers/localstorage';
import { mapShippingUsername } from './helpers';

export const userRules = {
  fullName: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 4,
    },
    {
      max: 150,
    },
  ],
  userName: [
    { transform: (value) => mapShippingUsername(value) },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 8,
    },
    {
      max: 100,
    },
    (form) => ({
      async validator(_, value) {
        const valueLen = value ? value.trim().length : 0;
        if (valueLen < 8) return Promise.reject();
        if (valueLen > 100) return Promise.reject();
        const mappedValue = mapShippingUsername(value);
        form.setFieldsValue({
          userName: mappedValue,
        });
        const res = await validateUniqueUsername(mappedValue, getToken());
        if (res.available === false)
          return Promise.reject(new Error('El nombre de usuario esta en uso.'));
        return Promise.resolve();
      },
    }),
  ],
  address: [
    { transform: (value) => value.trim() },
    {
      required: false,
    },
    {
      whitespace: true,
    },
    {
      min: 2,
    },
    {
      max: 255,
    },
  ],
  email: [
    { transform: (value) => value.trim() },
    {
      type: 'email',
    },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 150,
    },
    ({ _getFieldValue }) => ({
      async validator(_, value) {
        const valueLen = value ? value.trim().length : 0;
        if (valueLen < 6) return Promise.reject();
        if (valueLen > 150) return Promise.reject();
        // eslint-disable-next-line no-useless-escape
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value))
          return Promise.reject();
        const res = await validateUniqueEmail(value, getToken());
        if (res.available === false)
          return Promise.reject(new Error('El email ya está en uso.'));
        return Promise.resolve();
      },
    }),
  ],
  cellphone: [
    { transform: (value) => value.trim() },
    {
      type: 'string',
      required: true,
      pattern: /3[0-9]{9}$/gm,
      message: 'El Numero Celular debe contender 10 números',
    },
    ({ _getFieldValue }) => ({
      async validator(_, value) {
        const regex = /3[0-9]{9}$/gm;
        if (value == null || value === '' || !regex.test(value)) return;

        const res = await validateUniquePhone(value, getToken());
        if (res === null)
          return Promise.reject(
            new Error(
              'Error al verificar el número, por favor comunícate con soporte.'
            )
          );

        if (res.available === false)
          return Promise.reject(
            new Error(
              'El número celular ya está en uso, por favor ingresa un número distinto.'
            )
          );
        return Promise.resolve();
      },
    }),
  ],
  dayOfBirthday: [
    { transform: (value) => value.trim() },
    {
      type: 'date',
    },
  ],
  gender: [{ transform: (value) => value.trim() }],
  role: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
  ],
  apartmentNumber: [
    { transform: (value) => value.trim() },
    {
      whitespace: true,
    },
    {
      min: 1,
    },
    {
      max: 50,
    },
    {
      type: 'string',
    },
  ],
  parking: [
    { transform: (value) => value.trim() },
    {
      whitespace: true,
    },
    {
      min: 1,
    },
    {
      max: 15,
    },
    {
      type: 'string',
    },
  ],
  members: [
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 255,
    },
    {
      type: 'string',
    },
  ],
  frequentVisits: [
    {
      type: 'string',
    },
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 255,
    },
  ],
};

export const imgAccept = '.jpg, .jpeg, .png';
export const gender = ['male', 'female'];
export const genders = [
  {
    key: 'male',
    value: 'Hombre',
  },
  {
    key: 'female',
    value: 'Mujer',
  },
];
