import React from 'react';
import { TopBarWrap } from './Wraps';

function TopBar({ name = null }) {
  return (
    <TopBarWrap>
      <h1>{name ? name : ''}</h1>
    </TopBarWrap>
  );
}
export default TopBar;
