import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import CreateUser from 'containers/administrator/create-user';
import { HttpSpinner, SuccessfulRegistration, LinkExpired } from 'components';
import { validateLink, createUserFromPublicLink } from 'redux/index';
import { withCustomProps } from 'hocs';
import { useTheme } from 'styled-components';
import { Divider } from 'antd';
import { IoEnterOutline } from 'react-icons/io5';
import { TopBarIconStyled, LinkContainer } from './Wraps';
import { openNotification } from 'helpers/notifications';
import { ERROR } from 'constants';

const CreateUserWithValidation = withCustomProps(CreateUser); // HOC

function RegisterContainer() {
  const effectRan = useRef(false);
  const theme = useTheme();
  const { uuid } = useParams();
  const [isValid, setIsValid] = useState(null);
  const [link, setLink] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(null); // null

  const handleLinkValidation = async () => {
    const res = await validateLink(uuid);
    if (res == null) {
      setIsValid(false);
      return;
    }
    if (res.expired === true) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
    setLink(res.link);
  };

  useEffect(() => {
    if (effectRan.current === false) {
      handleLinkValidation();
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isValid === null) return <HttpSpinner />;
  if (isValid === false) return <LinkExpired />;
  if (isSuccessful === true) return <SuccessfulRegistration />;

  const handleSubmit = async (data, resetFormCb) => {
    setIsRequesting(true);
    const user = await createUserFromPublicLink({
      ...data,
      linkId: link.id,
    });
    setIsRequesting(false);
    if (user == null) {
      openNotification(
        ERROR,
        'Creación fallida, Por favor verifica el correo, telefono,y nombre de usuario e intenta nuevamente.'
      );
      return;
    }
    setIsSuccessful(true);
    resetFormCb();
  };

  return (
    <LinkContainer>
      {isRequesting && <HttpSpinner />}
      <TopBarIconStyled
        bgColor={theme.colors.primary}
        color={theme.colors.white}
        text='Formulario de Registro'
        noActions={true}
        icon={<IoEnterOutline size={40} color={theme.colors.white} />}
      />
      <Divider />
      <CreateUserWithValidation
        isPublicPage={true}
        onCustomSubmit={handleSubmit}
      />
      <Divider />
    </LinkContainer>
  );
}

export default RegisterContainer;
