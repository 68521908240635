import PropTypes from 'prop-types';
import { UserInfoContainer, UserInfoContentWrap, ImgContainer } from './Wraps';

function UserInfo({
  userImage,
  userFullName,
  residentialName,
  profileAddress,
}) {
  const altImg = `${userFullName}, ${residentialName}, ${profileAddress}`;
  return (
    <UserInfoContainer>
      <ImgContainer>
        <img
          src={userImage ? userImage : '/assets/images/no-img.png'}
          alt={altImg}
          className='home-user-profile-image'
        />
      </ImgContainer>

      <UserInfoContentWrap>
        <p>{userFullName}</p>
        <span>{residentialName}</span>
        {profileAddress && <span>{profileAddress}</span>}
      </UserInfoContentWrap>
    </UserInfoContainer>
  );
}

UserInfo.propTypes = {
  userImage: PropTypes.string,
  userFullName: PropTypes.string.isRequired,
  residentialName: PropTypes.string.isRequired,
  profileAddress: PropTypes.string.isRequired,
};
export default UserInfo;
