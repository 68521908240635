import { createSlice } from '@reduxjs/toolkit';
import { days } from '../../containers/administrator/assets/assets-constants';
const initialState = {
  weekdays: [
    {
      day: 'Lunes',
      startTime: '00:00',
      endingTime: '00:00',
    },
    {
      day: 'Martes',
      startTime: '00:00',
      endingTime: '00:00',
    },
    {
      day: 'Miércoles',
      startTime: '00:00',
      endingTime: '00:00',
    },
    {
      day: 'Jueves',
      startTime: '00:00',
      endingTime: '00:00',
    },
    {
      day: 'Viernes',
      startTime: '00:00',
      endingTime: '00:00',
    },
    {
      day: 'Sábado',
      startTime: '00:00',
      endingTime: '00:00',
    },
    {
      day: 'Domingo',
      startTime: '00:00',
      endingTime: '00:00',
    },
  ],
  jointAsset: [],
  selectedAsset: {},
  selectedDays: [],
  messageEvent: {},
  reservations: [],
  invalidDays: [],
  validDays: [],
  scheduleAvailable: {},
};

const reducers = {
  setAssets: (state, action) => {
    state.jointAsset = action.payload;
  },

  setAsset: (state, action) => {
    const schedule = days.map((item) => {
      const signedDay = action.payload.schedule.find((e) => e.day === item.day);
      if (signedDay) {
        return {
          day: signedDay.day,
          startTime: signedDay.startTime,
          endingTime: signedDay.endingTime,
        };
      } else {
        return item;
      }
    });

    let selectedDays = action.payload.schedule.map((item) => {
      return item.day;
    });

    // eslint-disable-next-line no-useless-computed-key
    state.selectedAsset = { ...action.payload, ['schedule']: schedule };
    state.selectedDays = selectedDays;
  },

  setWeekdays: (state, action) => {
    state.weekdays = action.payload;
  },

  setOnReset: (state, action) => {
    state.weekdays = action.payload;
    state.selectedAsset = {};
    state.selectedDays = [];
  },

  setMessageEvent: (state, action) => {
    state.messageEvent = action.payload;
  },

  setReservation: (state, action) => {
    state.reservations = action.payload;
  },
  setValidDays: (state, action) => {
    if (!state.validDays.includes(action.payload)) {
      state.validDays = [...state.validDays, action.payload];
    }
  },
  setInvalidDays: (state, action) => {
    if (!state.invalidDays.includes(action.payload)) {
      state.invalidDays = [...state.invalidDays, action.payload];
    }
  },
  setSchedule: (state, action) => {
    state.scheduleAvailable = action.payload;
  },
};

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers,
});

const { actions, reducer } = assetSlice;
// ACTIONS
export const {
  setAssets,
  setAsset,
  setWeekdays,
  setOnReset,
  setMessageEvent,
  setReservation,
  setValidDays,
  setInvalidDays,
  setSchedule,
} = actions;
// GETTERS
export const selectAssetsState = (state) => state.asset;

export default reducer;
