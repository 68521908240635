import { PlusOutlined } from '@ant-design/icons';
import { AddItem } from 'components';
import { SUPERUSER_MODULE } from 'constants';

function ProjectsManagementBtn({ role, navigate }) {
  const onGoToProject = () => navigate('proyectos');

  if (role !== SUPERUSER_MODULE) return null;

  return (
    <AddItem
      toolTip={{
        placement: 'bottom',
        title:
          'Recuerda que aquí podrás gestionar todos los proyectos y usuarios provenientes de Odoo y Optimus.',
        style: { transition: 'all 300ms' },
      }}
      title='Gestión de proyectos'
      btn={{
        type: 'default',
        shape: 'rounded',
        icon: <PlusOutlined />,
        size: 32,
        onClick: onGoToProject,
      }}
    />
  );
}

export default ProjectsManagementBtn;
