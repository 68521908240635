import styled from 'styled-components';
import { InputNumber, Button, TimePicker, DatePicker } from 'antd';
import { MovileContainerStyled } from 'components';

const { RangePicker } = TimePicker;
const { RangePicker: RangePickerDate } = DatePicker;

export const NewBookingContainer = styled(MovileContainerStyled)`
  margin-top: 0;

  @media (min-width: 992px) {
    margin-top: 3rem;
  }
`;

export const DatePickerWrap = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InputNumberCustom = styled(InputNumber)`
  width: 100%;
`;

export const CardWrap = styled.div`
  div {
    background-color: #006634;
    color: #fff;
    padding: 1rem 2.8rem 2.4rem 2.8rem;

    & div:last-child {
      padding: 0;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  border-radius: 12px;
  margin: 2rem auto;
`;

export const RangePickerStyled = styled(RangePicker)`
  width: 100%;
`;

export const DatePickerStyled = styled(RangePickerDate)`
  width: 100%;
`;

export const CareDaysAseet = styled.div`
margin: 12px 15px;
font-weight: bold;
`;

export const CareDayAseetList= styled.ul`
margin: 5px 0 0 -9px !important;
`;
