import styled from 'styled-components';

export const ProfileTopBarWrap = styled.div`
  background-color: #006634;
  box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 16rem;
  border-bottom-left-radius: 7rem;
  border-bottom-right-radius: 7rem;
  margin-bottom: 6rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  padding: 1rem 0 0 0;
  position: relative;

  & > h1 {
    width: 100%;
    color: #fff;
    text-align: center;
  }

  & > span:first-child {
    position: absolute;
    left: 5%;
  }
`;

export const BodyContainer = styled.div`
  height: 100%;
  z-index: 10;
  position: relative;

  display: flex;

  & img {
    width: 130px;
    height: 130px;
    text-align: center;
    border-radius: 50%;

    position: absolute;
    left: 50%;
    top: 2%;
    transform: translateX(-50%);
  }

  & > span {
    padding: 0.6rem;
    border-radius: 50%;
    border: 3px solid #707070;
    background-color: #fff;

    position: absolute;
    left: 50%;
    bottom: 2%;
    transform: translate(50%, -50%);
  }
`;
