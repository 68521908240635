import { setWildcard } from 'helpers';
import { optUsersService } from 'services/ajax/opt-users-service';
import { setUser, setUserReqStatus } from './users-slice';

const SERVICES_PATH = {
  getUser: '/users/',
  getOneUser: '/users/:id',
  getAllUsers: '/users',
  updateUser: '/users/',
  deleteUser: '/users/',
  uploadFile: `/users/upload`,
  createUser: '/auth/signup',
  createUserFromPublicLink: '/users/public',
  validateUsername: '/users/validate/username',
  validateEmail: '/users/validate/email',
  validatePhone: '/users/validate/phone',
  getRoles: '/roles/optimus',
  getUserByResidential: '/users/residential/',
  verifyUser: '/users/verify/',
  updateUserData: '/users/:id/update',
  createJustUser: '/users/create/just-user',
};

export async function getUsers(params, residentialId, token) {
  try {
    const res = await optUsersService.get(
      SERVICES_PATH.getUserByResidential + residentialId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      }
    );

    return res.data;
  } catch {
    return null;
  }
}

export async function updateUser(uuid, payload, token) {
  try {
    await optUsersService.put(SERVICES_PATH.updateUser + uuid, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return true;
  } catch {
    return null;
  }
}

export async function deleteUser(id, token) {
  try {
    await optUsersService.delete(SERVICES_PATH.deleteUser + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return true;
  } catch {
    return null;
  }
}

export async function createUser(payload, token) {
  try {
    const res = await optUsersService.post(SERVICES_PATH.createUser, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function createUserFromPublicLink(payload) {
  try {
    const res = await optUsersService.post(
      SERVICES_PATH.createUserFromPublicLink,
      payload
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function uploadUserImageToS3(formData, token = null) {
  try {
    const res = await optUsersService.post(SERVICES_PATH.uploadFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data.location;
  } catch {
    return null;
  }
}

export async function validateUniqueUsername(userName, token) {
  try {
    const res = await optUsersService.post(
      SERVICES_PATH.validateUsername,
      {
        userName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function validateUniqueEmail(email, token) {
  try {
    const res = await optUsersService.post(
      SERVICES_PATH.validateEmail,
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function validateUniquePhone(phone, token) {
  try {
    const res = await optUsersService.post(
      SERVICES_PATH.validatePhone,
      {
        phoneNumber: phone,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function getRoles(token) {
  try {
    const res = await optUsersService.get(SERVICES_PATH.getRoles, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch {
    return [];
  }
}

export async function verifyUser({ userId, payload }) {
  try {
    await optUsersService.post(SERVICES_PATH.verifyUser + userId, payload);
    return true;
  } catch {
    return null;
  }
}

export async function getAllUsers(params) {
  try {
    const res = await optUsersService.get(SERVICES_PATH.getAllUsers, {
      params: params,
    });

    return res.data;
  } catch {
    return null;
  }
}

export function getOneUser({ id }) {
  return async (dispatch) => {
    try {
      dispatch(setUserReqStatus({ status: true }));
      const url = setWildcard(SERVICES_PATH.getOneUser, id);

      const res = await optUsersService.get(url);
      dispatch(setUser({ user: res.data }));
      dispatch(setUserReqStatus({ status: false }));
    } catch {
      dispatch(setUserReqStatus({ status: false }));
      return null;
    }
  };
}

export async function updateUserData({ id, payload }) {
  try {
    const url = setWildcard(SERVICES_PATH.updateUserData, id);
    const res = await optUsersService.put(url, payload);
    return res.data;
  } catch {
    return null;
  }
}

export async function createJustUser({ payload }) {
  try {
    await optUsersService.post(SERVICES_PATH.createJustUser, payload);
    return { status: true };
  } catch (error) {
    if (error?.response.status === 500)
      return {
        status: false,
        msg: 'Error de petición, Por favor verifica que los servicios estén activos.',
      };
    return { status: false, msg: error?.response?.data?.message ?? '' };
  }
}
