import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import Actions from '../actions/Actions';
import { TableStyled, TagStyled } from 'components';

function ProjectsTable({
  theme,
  token,
  projects,
  loading,
  pagination,
  fetchProjects,
}) {
  const effectRan = useRef(false);

  const columns = [
    {
      title: 'Odoo Id',
      dataIndex: 'odooId',
      sorter: true,
      responsive: ['lg'],
      render: (value) => (
        <TagStyled color={theme.colors.blueSix}>{value}</TagStyled>
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      sorter: true,
      render: (value) => (
        <TagStyled color={theme.colors.blueSky}>{value}</TagStyled>
      ),
    },
    {
      title: 'Usuarios Adjuntos',
      dataIndex: 'totalUsers',
      sorter: false,
      render: (value) => (
        <TagStyled color={theme.colors.primaryNeon}>{value}</TagStyled>
      ),
    },
    {
      title: 'Fecha de Inicio',
      dataIndex: 'startDate',
      responsive: ['lg'],
      sorter: true,
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'endDate',
      sorter: true,
    },
    {
      title: 'Acciones',
      key: 'operation',
      align: 'center',
      render: (project) => (
        <Actions
          project={project}
          token={token}
          theme={theme}
          fetchProjects={fetchProjects}
        />
      ),
    },
  ];

  const handleTableChange = (newPagination, _filters, sorter) => {
    const { current: page, pageSize: limit } = newPagination;
    fetchProjects({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: page,
      limit,
    });
  };

  useEffect(() => {
    if (effectRan.current === false) {
      fetchProjects({
        limit: pagination.pageSize,
        page: pagination.current,
      });
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableStyled
      columns={columns}
      rowKey={(project) => project.odooId}
      dataSource={projects}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      showSorterTooltip={false}
    />
  );
}

ProjectsTable.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default ProjectsTable;
