import moment from 'moment';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Input, DatePicker, TimePicker } from 'antd';
import { IoReaderOutline } from 'react-icons/io5';
import { MdAddLink } from 'react-icons/md';
import { ERROR, SUCCESS } from 'constants';
import { openNotification } from 'helpers/notifications';
import { createLink, getLink, updateLink } from 'redux/index';

function LinksForm({
  token,
  fetchLinks,
  projectId,
  linkId = false,
  isEdition = false,
}) {
  const effectRan = useRef(false);
  const [form] = Form.useForm();
  const [isRequesting, setIsRequestiong] = useState(false);

  const messages = {
    post: {
      creationError:
        'Error al crear el enlace, Por favor verifica los datos e intenta nuevamente.',
      success: 'Enlace creado exitosamente.',
    },
    put: {
      upgradeError:
        'Error al actualizar el enlace, Por favor verifica los datos e intenta nuevamente.',
      success: 'Enlace actualizado exitosamente.',
    },

    submit: {
      post: 'Crear Enlace',
      put: 'Editar Enalce',
    },
  };

  const fetchEditionLink = async () => {
    const link = await getLink(linkId, token);
    if (link == null) {
      return;
    }
    form.setFieldsValue({
      name: link.name,
      expirationTime: moment(link.expirationTime),
      expirationDate: moment(link.expirationTime),
    });
  };

  useEffect(() => {
    if (effectRan.current === false) {
      if (isEdition) fetchEditionLink();
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledDate = (current) => {
    if (current.endOf('day') < moment().endOf('day')) return true;
    if (current.endOf('day') > moment().endOf('day')) return false;
    return false;
  };

  // submit
  const onFinish = async (data) => {
    setIsRequestiong(true);
    const { expirationTime, expirationDate, name } = data;
    const formattedDate = expirationDate.local().format('YYYY-MM-DD');

    // console.log('expirationDate: ', expirationDate);
    // console.log(
    //   'expirationTime UTC: ',
    //   expirationTime.utc().format('HH:mm:ss')
    // );
    // console.log(
    //   'expirationTime LOCAL: ',
    //   expirationTime.local().format('HH:mm:ss')
    // );

    const payload = {
      name,
    };

    if (expirationTime) {
      const time = expirationTime.local().format('HH:mm:ss');
      payload.expirationTime = new Date(`${formattedDate} ${time}`);
    } else {
      payload.expirationTime = new Date(`${formattedDate} 00:00:00`);
    }

    let res;

    if (!isEdition) {
      payload.projectId = projectId;
      res = await createLink(payload, token);
    } else res = await updateLink(linkId, payload, token);
    setIsRequestiong(false);

    if (res == null) {
      openNotification(
        ERROR,
        isEdition ? messages.put.upgradeError : messages.post.creationError,
        IoReaderOutline
      );
      return;
    }

    fetchLinks();
    openNotification(
      SUCCESS,
      isEdition ? messages.put.success : messages.post.success,
      MdAddLink
    );

    if (!isEdition) form.resetFields();

    fetchLinks();
  };

  return (
    <Row justify='center' align='center' gutter={[0, 16]}>
      <Col xs={{ span: 18 }}>
        <Form
          size='large'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            tooltip='Por favor ingresa un nombre para el enlace, por ejemplo: Hospital-c21'
            name='name'
            label='Nombre del Enlace'
            style={{ display: 'block' }}
            rules={[{ required: true }]}
          >
            <Input placeholder='Escribe aquí...' />
          </Form.Item>
          <Row justify='space-between' align='center' gutter={[0, 0]}>
            <Col>
              <Form.Item
                tooltip='Por favor ingresa la fecha de expiración del enlace.'
                name='expirationDate'
                label='Fecha de expiración'
                rules={[
                  {
                    type: 'object',
                    required: true,
                  },
                ]}
              >
                <DatePicker allowClear disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                tooltip='Por favor ingresa el tiempo de expiración del enlace.'
                name='expirationTime'
                label='Tiempo de Expiración'
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <TimePicker />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            wrapperCol={{
              xs: { span: 12 },
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <Button type='default' htmlType='submit' loading={isRequesting}>
              {isEdition ? messages.submit.put : messages.submit.post}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

LinksForm.propTypes = {
  token: PropTypes.string.isRequired,
  fetchLinks: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  linkId: PropTypes.string,
  isEdition: PropTypes.bool,
};

export default LinksForm;
