import { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, TitleStyled, BottomBarStyled } from './Wraps';
import { TopBarIcon, Logo, LogOutCard, UserInfo } from 'components';
import {
  selectHomeInfo,
  selectIsAuthenticated,
  getProfileDataByResidential,
} from 'redux/index';
import UserInfoSkeleton from './user-info-skeleton';

function Operator({ isHome, residentialId, onLogOut, children }) {
  const dispatch = useDispatch();
  const homeInfo = useSelector(selectHomeInfo);
  const token = useSelector(selectIsAuthenticated);
  useEffect(() => {
    dispatch(getProfileDataByResidential(residentialId, token));
  }, [dispatch, residentialId, token]);

  return (
    <>
      <TopBarIcon text='Inicio' noActions={false} isHome={isHome} />
      <Container>
        <Row justify='center' align='center'>
          <Col xs={{ span: 24 }}>
            <TitleStyled level={2}>Bienvenido!</TitleStyled>
          </Col>
          <Col xs={{ span: 24 }}>
            {homeInfo != null ? (
              <UserInfo
                userImage={homeInfo.userImage ?? ''}
                userFullName={homeInfo.userFullName ?? ''}
                residentialName={homeInfo.residentialName ?? ''}
                profileAddress={homeInfo.profileAddress ?? ''}
              />
            ) : (
              <UserInfoSkeleton />
            )}
          </Col>
        </Row>
        <Row justify='center' align='center'>
          <Col
            xs={{ span: 20 }}
            md={{ span: 20 }}
            lg={{ span: 18 }}
            xl={{ span: 16 }}
            xxl={{ span: 12 }}
            id='custom-col-by-cristian'
          >
            <Row
              justify='center'
              align='center'
              gutter={[16, 16]}
              style={{ height: 'inherit' }}
            >
              {children}
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
              >
                <LogOutCard onClick={onLogOut} />
              </Col>
            </Row>
          </Col>
        </Row>

        <BottomBarStyled>
          <Logo />
        </BottomBarStyled>
      </Container>
    </>
  );
}

Operator.propTypes = {
  isHome: PropTypes.bool.isRequired,
  residentialId: PropTypes.string.isRequired,
  onLogOut: PropTypes.func.isRequired,
};

export default Operator;
