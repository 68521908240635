import { ButtonStyled } from './Wraps';
import { Tooltip } from 'antd';

function AddItem(props) {
  return (
    <Tooltip {...props.toolTip}>
      <ButtonStyled {...props.btn}>{props.title}</ButtonStyled>
    </Tooltip>
  );
}

export default AddItem;
