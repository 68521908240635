import { useMemo, memo } from 'react';
import { useTheme } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { IoIdCard } from 'react-icons/io5';
import { DrawerStyled, MenuStyled } from './Wraps';
import {
  allModules,
  superAdminModules,
  adminModules,
  residentModules,
  operatorModules,
  getItem,
} from './routes';
import { RESIDENT_MODULE, OPERATOR_MODULE, ADMIN_MODULE } from 'constants';
import { selectUserRole, setLogOut, selectProjectId } from 'redux/index';
import { moduleKeys } from './constants';

function DesktopNavigation({ visible, onClose }) {
  const role = useSelector(selectUserRole);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const residentialId = useSelector(selectProjectId);
  const handleLogOut = () => {
    dispatch(setLogOut());
    navigate('/login', { replace: true });
  };
  const logOut = getItem(
    'Cerrar sesión',
    'log-out',
    <NavLink to='#' onClick={handleLogOut}>
      <FaSignOutAlt color='#fff' size={24} />
    </NavLink>
  );
  const profile = getItem(
    'Perfil',
    moduleKeys.keyProfile,
    <NavLink to={`perfil/${residentialId}`}>
      <IoIdCard color='#fff' size={24} />
    </NavLink>
  );

  const menuItems = useMemo(() => {
    let modules;
    switch (true) {
      case role === OPERATOR_MODULE:
        modules = allModules.filter((module) => {
          let filteredModule;
          operatorModules.forEach((adminM) => {
            if (module.key === adminM) filteredModule = module;
          });
          return filteredModule;
        });
        break;
      case role === RESIDENT_MODULE:
        modules = allModules.filter((module) => {
          let filteredModule;
          residentModules.forEach((adminM) => {
            if (module.key === adminM) filteredModule = module;
          });
          return filteredModule;
        });
        break;
      case role === ADMIN_MODULE:
        modules = allModules.filter((module) => {
          let filteredModule;
          adminModules.forEach((adminM) => {
            if (module.key === adminM) filteredModule = module;
          });
          return filteredModule;
        });
        break;
      default:
        modules = allModules.filter((module) => {
          let filteredModule;
          superAdminModules.forEach((adminM) => {
            if (module.key === adminM) filteredModule = module;
          });
          return filteredModule;
        });
        break;
    }

    const profileIndex = modules.findIndex(
      (value) => value.key === moduleKeys.keyProfile
    );
    if (profileIndex !== -1) modules[profileIndex] = profile;
    modules.push(logOut);
    return modules;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <>
      <DrawerStyled
        placement={'right'}
        closable={false}
        visible={visible}
        onClose={onClose}
        key={'right'}
        width='auto'
      >
        <MenuStyled
          mode='inline'
          theme='dark'
          inlineCollapsed={true}
          items={menuItems}
          bg={theme.colors.primary}
        />
      </DrawerStyled>
    </>
  );
}

export default memo(DesktopNavigation);
