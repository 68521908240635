import { useEffect, useRef, useState } from 'react';
import { Row, Col, Select, Form, Button } from 'antd';
import { IoReaderOutline } from 'react-icons/io5';
import { ERROR, SUCCESS } from 'constants';
import { openNotification } from 'helpers/notifications';
import { getOdooProjectDto } from 'helpers/odoo';
import { getOdooProjects, createOdooProject } from 'redux/index';

function CreationModalContent({ token, fetchProjects }) {
  const [form] = Form.useForm();
  const effectRan = useRef(false);
  const [projects, setProjects] = useState([]);
  const [isRequesting, setIsRequestiong] = useState(false);

  const fetchOdooProjects = async () => {
    const res = await getOdooProjects(token);
    if (res == null) {
      return;
    }
    setProjects(res);
  };

  useEffect(() => {
    if (effectRan.current === false) {
      fetchOdooProjects();
      return () => {
        effectRan.current = true;
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterOption = (input, option) =>
    option.children.toLowerCase().includes(input.toLowerCase());

  // submit
  const onFinish = async (data) => {
    const { odooProject } = data;
    setIsRequestiong(true);

    const project = projects.find((project) => project.id === odooProject);
    const payload = getOdooProjectDto(project);

    const res = await createOdooProject(payload, token);
    setIsRequestiong(false);

    if (res == null) {
      openNotification(
        ERROR,
        'Error al crear el proyecto, Por favor verifica que el proyecto en Odoo contenga los siguientes campos no nulos: id, name, active, date_start, date.',
        IoReaderOutline
      );
      return;
    }

    fetchOdooProjects();
    fetchProjects();
    openNotification(
      SUCCESS,
      'Proyecto creado con satisfacción.',
      IoReaderOutline
    );

    form.resetFields();
  };

  return (
    <Row justify='center' align='center' gutter={[0, 16]}>
      <Col xs={{ span: 18 }}>
        <Form
          size='large'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            name='odooProject'
            label='Proyectos de Odoo'
            style={{ display: 'block' }}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder='Buscar Proyecto'
              optionFilterProp='children'
              filterOption={handleFilterOption}
              // filterSort={handleFilterSort}
            >
              {projects.map((project, i) => (
                <Select.Option value={project.id} key={project.id}>
                  {`${project.id}-${project.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 12 },
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <Button type='default' htmlType='submit' loading={isRequesting}>
              Crear Proyecto
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default CreationModalContent;
