import styled from 'styled-components';
import { BodyContainer } from 'components/layout/top-bars/profile-top-bar/Wraps';

export const SkeletonContainer = styled.div`
  position: relative;
  height: auto;

  @media (min-width: 768px) {
    margin-top: 2rem;
  }
  @media (min-width: 992px) {
    margin-top: 4rem;
  }
`;

export const SkeletonImgContainer = styled(BodyContainer)`
  height: 16rem;
`;

export const SkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  & .skeleton-fullname {
    width: 35rem;
  }

  & .skeleton-residential {
    width: 20rem;
  }
  & .skeleton-address {
    width: 10rem;
  }

  & .skeleton-residential,
  & .skeleton-address,
  & .skeleton-fullname {
    & span {
      width: 100% !important;
    }
  }
`;
