import { useState } from 'react';
import { Row, Col, Select, Form, Button, Switch } from 'antd';
import { IoAttach } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { openNotification } from 'helpers/notifications';
import { ERROR, SUCCESS, ADMIN_MODULE, SUPERUSER_MODULE } from 'constants';
import { attachOdooUsersToProject, selectIsRequesting } from 'redux/index';
import { roles } from '../../constans';
import { mapOdooUsers } from 'helpers/odoo';

function AssociationModalContent({
  project,
  // users,
  // optUsers,
  onFetchOpt,
  onFetchProjects,
  // odoo
  odooUsersTopLevel,
  onSetOdooUsers,
  // optimus
  optimusUsersTopLevel,
  setOptimusUsers,
}) {
  const [form] = Form.useForm();
  const [userTypes, setUserTypes] = useState(false);
  const { isRequestingOdoo, isRequestingOptimus } =
    useSelector(selectIsRequesting);
  const [isRequesting, setIsRequestiong] = useState(false);

  const fetchAndResetAll = () => {
    // onFetchOpt();
    resetForm();
    onFetchProjects();
  };

  const handleFilterOption = (input, option) =>
    option.children.toLowerCase().includes(input.toLowerCase());

  const resetForm = () => form.resetFields();

  const handlwSwitchUsers = (checked) => {
    setUserTypes(checked);
    resetForm();
  };

  // submit
  const onFinish = async (data) => {
    const projectId = project.id;
    const { odooUsers, optUsers: optimusUsers, role } = data;
    setIsRequestiong(true);

    const payload = {
      projectId,
    };

    if (userTypes === true) {
      // optimus
      const filteredOptUsers = optimusUsersTopLevel.filter((optUser) =>
        optimusUsers.includes(optUser.id)
      );
      payload.users = filteredOptUsers.map((optUser) => optUser.id);
      payload.userTypes = 'optimus';
    } else {
      // odoo
      const filteredOdooUsers = odooUsersTopLevel.filter((user) =>
        odooUsers.includes(user.id)
      );
      payload.users = mapOdooUsers(filteredOdooUsers);
      payload.userTypes = 'odoo';
      payload.role = role;
    }

    const res = await attachOdooUsersToProject(payload);
    setIsRequestiong(false);

    if (res == null) {
      openNotification(
        ERROR,
        'Error al asociar los usuarios, por favor verifica en Odoo que los campos; nombre, identificación, correo, dirección y estado no sean nulos, o contengan valores erróneos.',
        IoAttach
      );
      return;
    }

    openNotification(
      SUCCESS,
      'Los usuarios seleccionados fueron asociados con exito.',
      IoAttach
    );
    fetchAndResetAll();

    // delete users from memory
    const createdUsers =
      userTypes === true ? payload.users : payload.users.map((user) => user.id);

    const kindOfUsers =
      userTypes === true ? optimusUsersTopLevel : odooUsersTopLevel;
    const filteredUsers = kindOfUsers.filter((user) => {
      return !createdUsers.includes(user.id);
    });

    if (userTypes === true) {
      setOptimusUsers(filteredUsers); // optimus
    } else {
      onSetOdooUsers(filteredUsers); // odoo
    }
  };

  return (
    <Row justify='center' align='center' gutter={[0, 0]}>
      <Col xs={{ span: 18 }}>
        <Form
          size='large'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 0 0 0',
            }}
            wrapperCol={{
              span: 4,
              offset: 20,
            }}
          >
            <Switch
              checkedChildren='Optimus'
              unCheckedChildren='Odoo'
              onChange={handlwSwitchUsers}
            />
          </Form.Item>

          {userTypes === false && (
            <Form.Item
              tooltip='Recuerda que todos los usuarios seleccionados a continuación serán adjuntados a este proyecto.'
              name='odooUsers'
              label='Usuarios de Odoo'
              style={{ display: 'block' }}
              rules={[{ required: true }]}
            >
              <Select
                loading={isRequestingOdoo}
                disabled={isRequestingOdoo}
                mode='multiple'
                style={{ width: '100%' }}
                showSearch
                placeholder='Buscar y Seleccionar'
                optionFilterProp='children'
                filterOption={handleFilterOption}
              >
                {odooUsersTopLevel.map((user, i) => (
                  <Select.Option value={user.id} key={user.id}>
                    {`${user.email}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {userTypes === true && (
            <Form.Item
              tooltip='Recuerda que todos los usuarios seleccionados a continuación serán adjuntados a este proyecto.'
              name='optUsers'
              label='Usuarios de Optimus'
              style={{ display: 'block' }}
              rules={[{ required: true }]}
            >
              <Select
                loading={isRequestingOptimus}
                disabled={isRequestingOptimus}
                mode='multiple'
                style={{ width: '100%' }}
                showSearch
                placeholder='Buscar y Seleccionar'
                optionFilterProp='children'
                filterOption={handleFilterOption}
              >
                {optimusUsersTopLevel.map((user, i) => (
                  <Select.Option value={user.id} key={user.id}>
                    {`${user.email}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {userTypes !== true && (
            <Form.Item
              tooltip='Recuerda que este será el rol del los usuarios a asociar.'
              name='role'
              label='Role'
              style={{ display: 'block' }}
              rules={[{ required: true, type: 'string' }]}
            >
              <Select style={{ width: '100%' }} placeholder='Seleccionar role'>
                {roles.map((role, i) => (
                  <Select.Option value={role.key} key={role.key}>
                    {`${
                      role.key === ADMIN_MODULE
                        ? 'Administrador De Conjunto'
                        : role.key === SUPERUSER_MODULE
                        ? 'Super Usuario'
                        : role.name
                    }`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            wrapperCol={{
              xs: { span: 12 },
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <Button type='default' htmlType='submit' loading={isRequesting}>
              Asociar Usuarios
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default AssociationModalContent;
