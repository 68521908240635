import styled from 'styled-components';
import { BodyContainer } from '../../../components/layout/top-bars/profile-top-bar/Wraps';

export const UserInfoContainer = styled.div`
  position: relative;
  height: auto;

  @media (min-width: 768px) {
    margin-top: 2rem;
  }
  @media (min-width: 992px) {
    margin-top: 4rem;
  }
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ImgContainer = styled(BodyContainer)`
  height: 16rem;
`;

export const UserInfoContentWrap = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 2rem;
  color: #707070;
  & > p {
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
  }
  & > span {
    display: block;
  }
`;
