import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 0,
  errorMessage: null,
  stepError: null,
  isMakingRequest: null,
};
const reducers = {
  setStep: (state, action) => {
    const { step } = action.payload;
    state.step = step;
    state.errorMessage = null;
    state.stepError = null;
    state.isMakingRequest = null;
  },
  setErrorMessage: (state, action) => {
    const { errorMessage } = action.payload;
    state.errorMessage = errorMessage;
  },
  setStepError: (state, action) => {
    state.stepError = true;
  },
  cleanErroMessage: (state, _action) => {
    state.errorMessage = null;
  },
  resetPasswordState: (state, _action) => {
    state.step = 0;
    state.errorMessage = null;
    state.stepError = null;
  },
  setReqStatus: (state, action) => {
    state.isMakingRequest = action.payload.status;
  },
};
const resetPasswordSlice = createSlice({
  name: 'reset-password',
  initialState,
  reducers,
});
const { actions, reducer } = resetPasswordSlice;
// ACTIONS
export const {
  setStep,
  setStepError,
  setErrorMessage,
  cleanErroMessage,
  resetPasswordState,
  setReqStatus,
} = actions;
// GETTERS
export const selectResetPasswordState = (state) => state.resetPassword;
export default reducer;
