import { optUsersService } from '../../services/ajax/opt-users-service';
import { setPosts, setIsDeleted, setIsMakingRequest } from './posts-slice';
import {
  showNotification,
  resetNotification,
} from '../notifications/notifications-slice';
import { getToken } from '../../helpers/localstorage';
const PATH_SERVICES = {
  getPosts: '/profile-walls/residential',
  getPost: '/profile-walls/',
  deletePost: '/profile-walls/',
  createPost: '/profile-walls',
  updatePost: '/profile-walls/',
  uploadImage: '/profile-walls/upload',
};

export function getPosts({ page, residentialId }) {
  return async (dispatch) => {
    try {
      dispatch(setIsMakingRequest(true));
      const res = await optUsersService.get(
        `${PATH_SERVICES.getPosts}/${residentialId}?page=${page}&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      dispatch(setPosts({ posts: res.data, page }));
    } catch {
    } finally {
      dispatch(setIsMakingRequest(false));
    }
  };
}

export async function getPost(id) {
  try {
    const res = await optUsersService.get(PATH_SERVICES.getPost + `${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res.data;
  } catch {
    return null;
  }
}

export function deletePost(id) {
  return async (dispatch) => {
    try {
      await optUsersService.delete(PATH_SERVICES.deletePost + `${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      dispatch(setIsDeleted({ status: true, id }));
      dispatch(showNotification());
      setTimeout(() => {
        dispatch(resetNotification());
      }, 100);
    } catch {
      dispatch(setIsDeleted({ status: false, id }));
    }
  };
}

export async function createPost(payload, token) {
  try {
    const res = await optUsersService.post(PATH_SERVICES.createPost, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function updatePost(uuid, payload, token) {
  try {
    const res = await optUsersService.patch(
      PATH_SERVICES.updatePost + uuid,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export const uploadPostImageToS3 = async (formData, token) => {
  try {
    const res = await optUsersService.post(
      PATH_SERVICES.uploadImage,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data ? res.data.location : null;
  } catch {
    return null;
  }
};
