import styled from 'styled-components';
import { Drawer, Menu } from 'antd';

export const DrawerStyled = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }
`;

export const MenuStyled = styled(Menu)`
  height: 100%;
  background: ${(props) => props.bg || 'white'} !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  transition: all 300ms ease-out;

  & > li {
    &:hover > a > svg {
      transform: scale(1.2);
    }
  }
`;
