import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoCashOutline } from 'react-icons/io5';
import { Row, Col, Table, Tooltip, Modal, message, Grid } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { days } from './assets-constants';
import { TopBarIcon, IconStyled } from 'components';
import { ActionsWrap, AssetsContainer, ButtonStyled } from './Wraps';
import { getAssets, deleteAsset } from 'redux/index';
import { setOnReset } from 'redux/index';

function Assets() {
  const { confirm } = Modal;
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [jointAsset, setJointAsset] = useState({ assets: [], primeAssets: [] });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
  });
  const pages = (jointAsset.length * 1) / 8;

  const handlerAssets = async (option, record) => {
    setLoading(true);

    switch (option) {
      case 'find':
        const assets = await getAssets();
        setJointAsset({ assets: assets, primeAssets: assets });
        break;

      case 'remove':
        const res = await deleteAsset({ uuid: record });
        if (res.ok) {
          const assets = await getAssets();
          setJointAsset({ assets: assets, primeAssets: assets });
          message.success(res.message);
        }
        break;

      default:
        break;
    }

    setLoading(false);
  };
  useEffect(() => {
    handlerAssets('find');
  }, []);

  const handleEditAsset = (asset) => {
    // dispatch(setAsset(asset));
    console.log(asset);
    navigate(asset.id + '/editar');
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Esta seguro de eliminar el activo?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Una vez se realice se elimine el activo ya no se podrá activar nuevamente',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        handlerAssets('remove', record);
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      filterSearch: true,
      sorter: true,
      width: 'auto',
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      filters: [
        {
          text: 'Activo',
          value: 'Activo',
        },
        {
          text: 'Inactivo',
          value: 'Inactivo',
        },
      ],
      responsive: ['md'],
      width: 'auto',
    },

    {
      title: 'Acciones',
      key: 'operation',
      align: 'right',
      width: '15%',
      render: (_, record) => (
        <>
          <Row justify='end'>
            <Col span={5} style={{ marginRight: '0.8rem' }}>
              <Tooltip title='Editar activo'>
                <IconStyled color={theme.colors.blueSix}>
                  <EditOutlined
                    style={{ color: '#f08c00' }}
                    onClick={() => handleEditAsset(record)}
                  />
                </IconStyled>
              </Tooltip>
            </Col>
            <Col span={5}>
              <Tooltip title='Eliminar activo'>
                <IconStyled
                  style={{ color: '#e03131' }}
                  color={theme.colors.redFive}
                  onClick={() => showDeleteConfirm(record.id)}
                >
                  <DeleteOutlined />
                </IconStyled>
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const handleCreateAsset = () => {
    dispatch(setOnReset(days));
    navigate('crear');
  };

  const handleTableChange = (filters, sorter) => {
    if (sorter.state.length === 1) {
      const assets = jointAsset.primeAssets.filter(
        (e) => e.state === sorter.state[0]
      );
      setJointAsset({ ...jointAsset, assets });
    } else {
      handlerAssets('find');
    }

    pages === pagination.current
      ? setPagination({ ...pagination, current: pagination.current - 1 })
      : setPagination({ ...pagination, current: pagination.current + 1 });
  };

  let titleResponsive = '';
  if (screens.xs === true) titleResponsive = 'G. Activos';
  else titleResponsive = 'Gestión de Activos';

  return (
    <>
      <TopBarIcon text={titleResponsive} icon={<IoCashOutline size={40} />} />
      <AssetsContainer>
        <Row justify='center'>
          <Col xs={{ span: 22 }} sm={{ span: 18 }} lg={{ span: 18 }}>
            <Row justify='end'>
              <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 4 }}>
                <ActionsWrap>
                  <ButtonStyled
                    type='primary'
                    size='large'
                    block
                    htmlType='button'
                    onClick={handleCreateAsset}
                  >
                    Crear
                  </ButtonStyled>
                </ActionsWrap>
              </Col>
            </Row>
            <br />
            <Row
              gutter={[
                0,
                {
                  xs: 16,
                  sm: 16,
                  md: 32,
                },
              ]}
            >
              <Col xs={{ span: 24 }}>
                <Table
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={jointAsset.assets}
                  pagination={pagination}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </AssetsContainer>
    </>
  );
}

export default Assets;
