import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AdminNav, OperatorNav, ResidentNav, SuperAdminNav } from './routes';
import {
  OPERATOR_MODULE,
  RESIDENT_MODULE,
  ADMIN_MODULE,
} from '../../../../constants';
import { selectUserRole, selectProjectId, setLogOut } from '../../../../redux';

function MobileNavigation() {
  const role = useSelector(selectUserRole);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const residentialId = useSelector(selectProjectId);
  const handleLogOut = () => {
    dispatch(setLogOut());
    navigate('/login', { replace: true });
  };

  switch (true) {
    case role === OPERATOR_MODULE:
      return <OperatorNav uuid={residentialId} onLogOut={handleLogOut} />;
    case role === RESIDENT_MODULE:
      return <ResidentNav uuid={residentialId} onLogOut={handleLogOut} />;
    case role === ADMIN_MODULE:
      return <AdminNav uuid={residentialId} onLogOut={handleLogOut} />;
    default:
      return <SuperAdminNav uuid={residentialId} onLogOut={handleLogOut} />;
  }
}
export default memo(MobileNavigation);
