import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  users: [],
  optUsers: [],
  isRequestingOdoo: false,
  isRequestingOptimus: false,
};

const reducers = {
  setProjects: (state, action) => {
    const { projects } = action.payload;
    state.projects = projects;
  },
  setUsers: (state, action) => {
    state.users = action.payload.users;
  },
  setRequest: (state, action) => {
    const { status, type } = action.payload;
    if (type === 'odoo') state.isRequestingOdoo = status;
    else state.isRequestingOptimus = status;
  },
  setOptUsers: (state, action) => {
    state.optUsers = action.payload.optUsers;
  },
  clearProjectsState: (_state, _action) => {
    return initialState;
  },
  clearModalContent: (state, action) => {
    state.users = [];
    state.optUsers = [];
    state.isRequestingOdoo = false;
    state.isRequestingOptimus = false;
  },
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers,
});

const { actions, reducer } = projectsSlice;

// SETTERS
export const {
  setProjects,
  setUsers,
  setRequest,
  clearProjectsState,
  setOptUsers,
  clearModalContent,
} = actions;

// GETTERS
export const selectProjectsState = (state) => state.projectManagement;
export const selectProjects = (state) => state.projectManagement.projects;
export const selectUsers = (state) => state.projectManagement.users;
export const selectIsRequesting = (state) => {
  const currentState = state.projectManagement;
  return {
    isRequestingOdoo: currentState.isRequestingOdoo,
    isRequestingOptimus: currentState.isRequestingOptimus,
  };
};
export const selectOptUsers = (state) => state.projectManagement.optUsers;

export default reducer;
