import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReqLoadSpinner, Home } from '../../components';
import { setLogOut, selectUserRole, selectProjectId } from '../../redux';
import { OPERATOR_MODULE } from 'constants';
import MainHomeModule from './AdminResident';
import Operator from './Operator';

function HomeContainer() {
  const isHome = true;
  const [module, setModule] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const residentialId = useSelector(selectProjectId);

  const getRole = useCallback(() => {
    setTimeout(() => {
      setModule(userRole);
    }, 900);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLogOut = () => {
    dispatch(setLogOut());
    navigate('/login', { replace: true });
  };
  useEffect(() => {
    if (!module) {
      getRole();
    }
  }, [module, getRole]);
  if (!module) return <ReqLoadSpinner />;
  const homeOptions = <Home role={userRole} residential={residentialId} />;
  return (
    <>
      {module !== OPERATOR_MODULE && (
        <MainHomeModule theme={theme} isHome={isHome} onLogOut={handleLogOut}>
          {homeOptions}
        </MainHomeModule>
      )}
      {module === OPERATOR_MODULE && (
        <Operator
          isHome={isHome}
          residentialId={residentialId}
          onLogOut={handleLogOut}
        >
          {homeOptions}
        </Operator>
      )}
    </>
  );
}

export default HomeContainer;
