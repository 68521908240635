import React from 'react';
import { NavLink } from 'react-router-dom';
import { OptionContentWrap } from './Wraps';
import PropTypes from 'prop-types';

function NavigationCard({ iconName = null, text, to, children }) {
  const path = `/assets/images/${iconName}.svg`;

  return (
    // center here.
    <NavLink to={to}>
      <OptionContentWrap>
        <div>
          {iconName && <img src={path} alt={iconName} width='50' />}
          {children}
        </div>
        <p>{text}</p>
      </OptionContentWrap>
    </NavLink>
  );
}

NavigationCard.prototype = {
  iconName: PropTypes.string,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavigationCard;
