import { TOKEN_KEY } from 'constants';

export function getToken () {
  try {
    const rawToken = localStorage.getItem(TOKEN_KEY);
    if (!rawToken) return null;
    return JSON.parse(rawToken);
  } catch {
    return null;
  }
}
