import { memo } from 'react';
import { Card, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CardSideFront } from './Wraps';
import { Link, useNavigate } from 'react-router-dom';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { deletePost } from '../../../redux';
import { RESIDENT_MODULE } from '../../../constants';

function PostCard({ id, title, description, image, role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const commitElimination = () => {
    dispatch(deletePost(id));
  };

  const handleWatchDetail = (event) => {
    event.stopPropagation();
    navigate(`${id}/detalle`, { state: { edit: false } });
  };

  const confirm = () => {
    Modal.confirm({
      title: '¿Esta seguro de eliminar esta publicación?',
      icon: <ExclamationCircleOutlined />,
      content: 'Recuerda que esta acción no tiene regresión',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      onOk: commitElimination,
    });
  };
  const actions =
    role !== RESIDENT_MODULE
      ? [
          <Link to={`${id}/editar`} state={{ edit: true }}>
            <FaPencilAlt key='edit' style={{ fontSize: '1.6rem' }} />
          </Link>,
          <FaTimes
            key='delete'
            onClick={confirm}
            style={{ fontSize: '1.6rem' }}
          />,
        ]
      : [];

  return (
    <>
      <CardSideFront
        cover={<img alt='example' src={image} height='200' />}
        actions={actions}
      >
        <Card.Meta
          title={title}
          description={description}
          onClick={handleWatchDetail}
          style={{ cursor: 'pointer' }}
        />
      </CardSideFront>
    </>
  );
}

export default memo(PostCard);
