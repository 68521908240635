import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, message, Modal } from 'antd';
import { IoCalendarOutline } from 'react-icons/io5';
import {
  BookingContainer,
  ButtonStyled,
  ListStyled,
  ImageStyled,
} from './Wraps';
import {
  TopBarIcon,
  BookingItem,
  BookingSubtitle,
  HttpSpinner,
} from 'components';
import { getBooking, updateStateBookings } from 'redux/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';

function Booking() {
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [bookings, setBookings] = useState([]);
  const [isMakingReq, setIsMakingReq] = useState(true);

  const bookingManager = async () => {
    const data = await getBooking();
    setIsMakingReq(true);
    setBookings(data);
    setIsMakingReq(false);
  };

  useEffect(() => {
    if (effectRan.current === false) {
      bookingManager();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const updateBooking = async (state, id, user) => {
    if (user === 'admin') {
      if (state === 'Confirmada') {
        const response = await updateStateBookings(`Reject/${id}`);

        if (response.ok) {
          setBookings([]);
          message.success(response.message);
          await bookingManager();
        } else {
          message.error(
            'No fue posible realizar la acción inténtelo nuevamente'
          );
        }
      } else {
        const response = await updateStateBookings(`Confirm/${id}`);
        if (response.ok) {
          setBookings([]);
          message.success(response.message);
          await bookingManager();
        } else {
          setBookings([]);
          message.error(`${response.message === 'EL activo fue removido.' ? response.message : 'No es posible confirmar la reserva, ya que el horario de la reserva entra en conflicto con una reserva ya activa, cree una nueva reserva.'}`);
          await bookingManager();
        }
      }
    }

    if (state === 'Confirmada' && user === 'resident') {
      const response = await updateStateBookings(`Cancel/${id}`);

      if (response.ok) {
        setBookings([]);
        message.success(response.message);
        await bookingManager();
      } else {
        message.error('No fue posible realizar la acción inténtelo nuevamente');
      }
    }

    if (state === 'cancel-action') {
      setBookings([]);
      await bookingManager();
    }
  };

  const adminHandler = async (state, id, user) => {
    confirm({
      title:
        user === 'admin'
          ? state === 'Confirmada'
            ? 'Esta seguro de rechazar la reserva?'
            : 'Esta seguro de confirmar la reserva?'
          : 'Esta seguro de cancelar la reserva?',
      icon: <ExclamationCircleOutlined />,
      content:
        user === 'admin'
          ? state === 'Confirmada'
            ? 'Con esta acción rechazará la reserva y no estará activa para el usuario.'
            : 'Con esta acción confirmará la reserva y estará activa para el usuario.'
          : 'Al momento de cancelar la reserva ya no podrás actívala nuevamente',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        updateBooking(state, id, user);
      },

      onCancel() {
        updateBooking('cancel-action');
      },
    });
  };

  const handleNewBooking = () => {
    navigate('nueva'); // relative path
  };
  if (isMakingReq) return <HttpSpinner />;
  return (
    <>
      <TopBarIcon text='Reservas' icon={<IoCalendarOutline size={40} />} />
      <BookingContainer>
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 14, offset: 5 }}
            xl={{ span: 12, offset: 6 }}
            style={{ textAlign: 'center' }}
          >
            <ImageStyled
              src='/assets/images/booking-img.svg'
              alt='Aquí puedes observar todas tus reservaciones'
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 16, offset: 4 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 8, offset: 8 }}
            xl={{ span: 6, offset: 9 }}
          >
            <ButtonStyled
              type='primary'
              size='large'
              block
              htmlType='button'
              onClick={handleNewBooking}
            >
              Nueva reserva
            </ButtonStyled>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 14, offset: 5 }}
            xl={{ span: 12, offset: 6 }}
          >
            <BookingSubtitle>Historial</BookingSubtitle>
            <ListStyled
              dataSource={bookings}
              renderItem={(_item, i) => (
                <BookingItem
                  id={_item.id}
                  date={_item.date.slice(0, 10)}
                  startTime={_item.startTime}
                  endingTime={_item.endingTime}
                  attendees={_item.attendees}
                  type={_item.asset.name}
                  status={_item.state}
                  bookerName={_item.bookerName}
                  adminHandler={() => {
                    adminHandler(_item.state, _item.id, 'admin');
                  }}
                  residentHandler={() => {
                    adminHandler(_item.state, _item.id, 'resident');
                  }}
                />
              )}
            />
          </Col>
        </Row>
      </BookingContainer>
    </>
  );
}

export default Booking;
