import styled from 'styled-components';
import { Table } from 'antd';

export const TableStyled = styled(Table)`
  overflow-x: scroll;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    overflow-x: hidden;
  }
`;
