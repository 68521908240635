import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IoEarth } from 'react-icons/io5';
import { useTheme } from 'styled-components';
import { IconStyled, ResourceItemStyled } from './Wraps';
import { RESIDENT_MODULE } from 'constants';
import { confirmationModal } from 'helpers/confirmations';

function ResourceItem({ id, name, link, role, onDelete, onEdit }) {
  const theme = useTheme();

  const deletingParams = [
    null,
    null,
    null,
    null,
    null,
    null,
    () => onDelete(id),
  ];

  return (
    <>
      <ResourceItemStyled>
        <a href={link} target='_blank' rel='noreferrer'>
          <span className='resource__name'>{name}</span>
          <div>
            <Tooltip title='Visitar pagina' style={{ transition: 'all 300ms' }}>
              <IconStyled color={theme.colors.limeSix}>
                <IoEarth />
              </IconStyled>
            </Tooltip>
          </div>
        </a>
        {role !== RESIDENT_MODULE && (
          <>
            <Tooltip title='Editar recurso'>
              <IconStyled
                color={theme.colors.yellowSix}
                onClick={() => onEdit({ id, name, link })}
              >
                <EditOutlined />
              </IconStyled>
            </Tooltip>
            <Tooltip title='Eliminar recurso'>
              <IconStyled
                color={theme.colors.redFive}
                onClick={() => confirmationModal(...deletingParams)}
              >
                <DeleteOutlined />
              </IconStyled>
            </Tooltip>
          </>
        )}
      </ResourceItemStyled>
    </>
  );
}

ResourceItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
export default ResourceItem;
