import { optUsersService } from '../../../services';
const SERVICES_PATH = {
  getLogs: '/logs/residential',
  deleteLogError: '/logs',
  downloadLogOfError: '/logs/:id/download',
  downloadDoc: '/logs/:id/doc/download',
  uploadFile: '/bulk-uploads/upload-users',
  commitBulkUpload: '/bulk-uploads/users',
  downloadUsersTemplate: '/templates/download',
  sendMassiveEmails: '/emails/log/:id',
};

export async function getUserBulkUploadLogs(params, residentialId, token) {
  try {
    const res = await optUsersService.get(
      `${SERVICES_PATH.getLogs}/${residentialId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function deleteLogOfError(id, token) {
  try {
    const res = await optUsersService.delete(
      `${SERVICES_PATH.deleteLogError}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function downloadErrorLogs(logId, token) {
  try {
    const url = SERVICES_PATH.downloadLogOfError.replace(':id', logId);
    const res = await optUsersService.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function downloadFileUploaded(logId, token) {
  try {
    const url = SERVICES_PATH.downloadDoc.replace(':id', logId);
    const res = await optUsersService.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function uploadFileToS3(formData, token) {
  try {
    const res = await optUsersService.post(SERVICES_PATH.uploadFile, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  } catch (error) {
    const serverError = error.response.data;
    if (serverError.statusCode !== 400)
      return {
        statusCode: 500,
      };
    return {
      statusCode: 400,
      message: serverError.message,
    };
  }
}

export async function commitUsersBulkUpload(payload, token) {
  try {
    const res = await optUsersService.post(
      SERVICES_PATH.commitBulkUpload,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export async function downloadUsersTemplate(token) {
  try {
    const res = await optUsersService.post(
      SERVICES_PATH.downloadUsersTemplate,
      {
        type: 'users-bulk-upload-template',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }
    );
    return res.data;
  } catch (error) {
    const serverError = error.response.status;
    if (serverError === 404 || serverError === 400)
      return {
        message:
          serverError === 400
            ? 'La plantilla a descargar no se encuentra en el almacenamiento.'
            : 'La plantilla a descargar no existe.',
        statusCode: serverError,
      };
    return false;
  }
}

export async function sendMassiveEmails(logId, token) {
  try {
    const url = SERVICES_PATH.sendMassiveEmails.replace(':id', logId);
    const res = await optUsersService.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    const serverError = error.response.data;
    if (serverError.statusCode === 404 || serverError.statusCode === 400)
      return {
        message: serverError.message,
        statusCode: serverError.statusCode,
      };
    return false;
  }
}
