import { useState } from 'react';
import { Row, Col, Form, Select, Button } from 'antd';
import { IoAttach } from 'react-icons/io5';
import { attachUserToProject } from 'redux/index';
import { openNotification } from 'helpers';
import { ERROR } from 'constants';
import { SUCCESS } from 'constants';

function AttachProject({
  projects,
  isRequesting,
  userId,
  onFetch,
  onFetchProjects,
}) {
  const [form] = Form.useForm();
  const [isAttachingProject, setAttachToProject] = useState(false);

  const handleFilterOption = (input, option) =>
    option.children.toLowerCase().includes(input.toLowerCase());

  const onFinish = async (data) => {
    setAttachToProject(true);
    const { projectId } = data;

    const res = await attachUserToProject({ projectId, userId });
    if (res == null) {
      openNotification(
        ERROR,
        'Por favor verifica que el usuario adjunto sea un usuario existente.',
        IoAttach
      );
      setAttachToProject(false);
      return;
    }

    openNotification(SUCCESS, '', IoAttach);
    onFetch();
    onFetchProjects();
    setAttachToProject(false);
    form.resetFields();
  };

  return (
    <Row justify='center' align='center' gutter={[0, 16]}>
      <Col xs={{ span: 18 }}>
        <Form
          size='large'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
          layout='vertical'
        >
          <Form.Item
            name='projectId'
            label='Proyectos en Optimus'
            style={{ display: 'block' }}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder='Buscar Proyecto'
              optionFilterProp='children'
              filterOption={handleFilterOption}
              loading={isRequesting}
            >
              {projects.map((project, i) => (
                <Select.Option value={project.id} key={project.id}>
                  {`${project.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 12 },
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <Button
              type='default'
              htmlType='submit'
              loading={isAttachingProject}
            >
              Asociar Proyecto
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default AttachProject;
