import { Tooltip } from 'antd';
import { TableActionButtonWrap } from './Wraps';

function TableActionButton(props) {
  return (
    <Tooltip
      title={props.title}
      className={props.className}
      onClick={props.onClick ?? null}
    >
      <TableActionButtonWrap
        onClick={props.onToggleModal}
        style={{ cursor: 'pointer' }}
      >
        {props.children}
      </TableActionButtonWrap>
    </Tooltip>
  );
}

export default TableActionButton;
