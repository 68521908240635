export const formRules = {
  image: [
    {
      required: true,
      message: '¡Por favor selecciona una imagen!',
    },
  ],
  imageUpdate: [
    {
      required: false,
      message: '',
    },
  ],
  title: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 6,
    },
    {
      max: 255,
    },
  ],
  description: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 50,
    },
    {
      max: 5000,
    },
  ],
};
