import { optUsersService } from 'services';
import { setWildcard } from 'helpers/routes-mapper';

const PATH_SERVICES = {
  getLinks: '/links',
  createLink: '/links',
  deleteLink: '/links/:id',
  updateLink: '/links/:id',
  getLink: '/links/:id',
  validateLink: '/links/validate/:id',
};

export async function getLinks(params, token) {
  try {
    const res = await optUsersService.get(PATH_SERVICES.getLinks, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params,
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function createLink(payload, token) {
  try {
    const res = await optUsersService.post(PATH_SERVICES.createLink, payload, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function deleteLink(id, token) {
  try {
    const url = setWildcard(PATH_SERVICES.deleteLink, id);
    await optUsersService.delete(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return true;
  } catch {
    return null;
  }
}

export async function updateLink(id, payload, token) {
  try {
    const url = setWildcard(PATH_SERVICES.updateLink, id);
    await optUsersService.put(url, payload, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return true;
  } catch {
    return null;
  }
}

export async function getLink(id, token) {
  try {
    const url = setWildcard(PATH_SERVICES.getLink, id);
    const res = await optUsersService.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function validateLink(id) {
  try {
    const url = setWildcard(PATH_SERVICES.validateLink, id);
    const res = await optUsersService.get(url);
    return res.data;
  } catch {
    return null;
  }
}
