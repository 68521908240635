import styled from 'styled-components';
import { MovileContainerStyled } from '../../../components';
import { Button } from 'antd';

export const AssetsContainer = styled(MovileContainerStyled)`
  height: calc(
    100% - 22% - ${(props) => props.marginCalc || '0rem'}
  ); /* 22% = 12% top bar && 10% bottom navigation */

  @media (min-width: 992px) {
    height: calc(100% - 12% - ${(props) => props.marginCalc || '0rem'});
  }
`;

export const ButtonStyled = styled(Button)`
  border-radius: 12px;
  margin: 2rem auto 0 auto;
`;

export const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  list-style: none;

  & > li:first-child,
  & > li:last-child {
    background-color: #006634;
    color: white;
  }
`;

export const ListItem = styled.li`
  border-bottom: 2px solid #ced4da;
  padding: 0.8rem 3rem;
  color: #6c757d;
  transition: all 300ms;

  &:hover {
    background-color: #d6d6d6;
    cursor: pointer;
    color: #6c757d;
  }
`;

export const ActionsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
`;

export const IconStyled = styled.div`
  cursor: pointer;
  font-size: 2rem;
  color : ${(props) => props.color};
`;