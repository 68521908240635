import React from 'react';
import { ProfileTopBarWrap, BodyContainer, HeaderContainer } from './Wraps';
import { useNavigate } from 'react-router-dom';
import { FaPen } from 'react-icons/fa';

function ProfileTopBar({ text, imgPath, alt }) {
  const navigate = useNavigate();
  const handlePrePage = () => {
    navigate(-1);
  };

  return (
    <ProfileTopBarWrap>
      <HeaderContainer>
        <span>
          <svg
            stroke='#fff'
            fill='#fff'
            strokeWidth='0'
            viewBox='0 0 512 512'
            width='30'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handlePrePage}
          >
            <path
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='48'
              d='M328 112L184 256l144 144'
            ></path>
          </svg>
        </span>
        <h1>{text}</h1>
      </HeaderContainer>
      <BodyContainer id='i-am-this-this'>
        <img src={imgPath} alt={alt} />
        <span>
          <FaPen size={20} />
        </span>
      </BodyContainer>
    </ProfileTopBarWrap>
  );
}

export default ProfileTopBar;
