import { configureStore } from '@reduxjs/toolkit';
import assetsReducer from './assets/assets-management-slice';
import reservationReducer from './booking/booking-management-slice';
import signinReducer from './auth/signin-slice';
import resetPasswordReducer from './auth/reset-password-slice';
import postsReducer from './posts/posts-slice';
import profilesReducer from './profiles/profiles-slice';
import notificationsReducer from './notifications/notifications-slice';
import projectsReducer from './projects/projects-slice';
import usersReducer from './users/users-slice';

const store = configureStore({
  reducer: {
    auth: signinReducer,
    asset: assetsReducer,
    reservation: reservationReducer,
    resetPassword: resetPasswordReducer,
    postsManagement: postsReducer,
    profilesManagement: profilesReducer,
    notificationsManagement: notificationsReducer,
    projectManagement: projectsReducer,
    users: usersReducer,
  },
});

export default store;
