import { useEffect, useState, useRef } from 'react';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tooltip, Grid, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { PostContainer, CircleBtnStyled } from './Wraps';
import { TopBarIcon, PostCard, HttpSpinner } from 'components';
import { RESIDENT_MODULE } from 'constants';
import {
  getPosts,
  selectPostsState,
  selectProjectId,
  selectUserRole,
  selectNotificationsState,
} from 'redux/index';

function ProfileWall() {
  const effectRan = useRef(false);
  const { colors } = useTheme();
  const screens = Grid.useBreakpoint();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCreateNewPost = () => navigate('crear');
  const { posts, hasMore, isMakingRequest } = useSelector(selectPostsState);
  const residentialId = useSelector(selectProjectId);
  const { isNotificationShow } = useSelector(selectNotificationsState);
  const role = useSelector(selectUserRole);
  const openNotification = () => {
    notification.success({
      message: 'Operación Exitosa',
      description: 'Publicación eliminada exitosamente.',
      icon: (
        <CheckOutlined
          style={{
            color: colors.primaryNeon,
          }}
        />
      ),
    });
  };
  useEffect(() => {
    if (effectRan.current === false) {
      dispatch(getPosts({ page, residentialId }));
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNotificationShow === true) openNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationShow]);
  if (isMakingRequest) return <HttpSpinner />;
  const fetchData = () => {
    const currentPage = page + 1;
    dispatch(getPosts({ page: currentPage }));
    setPage(currentPage);
  };
  const allPosts =
    posts.length === 0 ? (
      <p style={{ textAlign: 'center' }}>Sin publicaciones.</p>
    ) : (
      <InfiniteScroll
        dataLength={posts.length}
        next={fetchData}
        hasMore={hasMore}
        scrollableTarget='scrollableDiv'
        loader={<h4>Cargando...</h4>}
        endMessage={<h4>No hay más publicaciones para mostrar.</h4>}
      >
        {posts.map((post, i) => {
          const LIMIT = 499;
          let description;
          if (screens.xs && screens.sm === false) {
            description = post.description.slice(0, 100) + '...';
          } else if (screens.sm && screens.md === false) {
            description = post.description.slice(0, 250) + '...';
          } else {
            description = post.description.slice(0, LIMIT) + '...';
          }
          return (
            <PostCard
              key={i}
              id={post.id}
              description={description}
              image={post.image}
              title={post.title}
              role={role}
            />
          );
        })}
      </InfiniteScroll>
    );

  return (
    <>
      <TopBarIcon
        iconName='chat'
        text='Muro'
        icon={<IoChatbubbleEllipsesOutline size={40} />}
      />
      <PostContainer marginCalc='2rem' id='scrollableDiv'>
        {role !== RESIDENT_MODULE && (
          <Tooltip
            placement='left'
            title='Crear publicación'
            style={{ transition: 'all 300ms' }}
            onClick={handleCreateNewPost}
          >
            <CircleBtnStyled
              type='primary'
              shape='circle'
              icon={<PlusOutlined />}
              size={28}
            />
          </Tooltip>
        )}
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 18, offset: 3 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 10, offset: 7 }}
          >
            {allPosts}
          </Col>
        </Row>
      </PostContainer>
    </>
  );
}

export default ProfileWall;
