import { getResidentialId } from "redux/profiles/profiles-slice";

const baseUrl = `${process.env.REACT_APP_OPT_USERS_SERVICE}/users/project`;

export const searchBooker = (booker) => {
  const wordpoint = getResidentialId();

  const token = localStorage.getItem("VE9LRU5fS0VZ").slice(1, -1);

  const url = `${baseUrl}/${wordpoint}?search=${booker}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
