import styled from 'styled-components';

export const LogOutCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 1.6rem;
  height: 100%;
  padding: 2.4rem;
  border-radius: 1.6rem;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.11);
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

  & svg {
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    box-shadow: 0px 6px 16px 4px rgba(0, 0, 0, 0.1);
    svg {
      transform: scale(1.1);
    }
    p {
      font-weight: bold;
    }
  }
  & > p {
    color: #707070;
    font-size: 1.6rem;
    text-align: center;
  }
`;
