export const vehicleForm = {
  autoColor: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 1,
    },
    {
      max: 50,
    },
  ],
  license: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 3,
    },
    {
      max: 10,
    },
  ],
  carModel: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      whitespace: true,
    },
    {
      min: 1,
    },
    {
      max: 150,
    },
  ],

  carYear: [
    { transform: (value) => value.trim() },
    {
      required: true,
    },
    {
      type: 'date',
    },
  ],
};
