import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  isRequestingUser: false,
};

const reducers = {
  setUser: (state, action) => {
    state.user = action.payload?.user ?? null;
  },

  setUserReqStatus: (state, action) => {
    state.isRequestingUser = action.payload?.status ?? false;
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers,
});

const { actions, reducer } = usersSlice;

export const { setUser, setUserReqStatus } = actions;

// GETTERS
export const selectUsersState = (state) => state.users;

export default reducer;
