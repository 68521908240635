import styled from 'styled-components';
import { MovileContainerStyled } from 'components';
import { Button, List } from 'antd';

export const BookingContainer = styled(MovileContainerStyled)`
  margin-top: 0;
`;

export const ButtonStyled = styled(Button)`
  border-radius: 12px;
  margin: 2rem auto;
`;

export const ListStyled = styled(List)`
  padding: 0.2rem;
`;

export const ImageStyled = styled.img`
  width: 100%;
  max-width: 50rem;
`;
