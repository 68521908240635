import { Row, Col, Grid, Tabs } from 'antd';
import { useTheme } from 'styled-components';
import { FaUsersCog } from 'react-icons/fa';
import { TopBarIcon, TabWrap, CustomAutoScreenWrap } from 'components';
import { ICON_SIZE } from './constans';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'redux/index';
import ProfilesTab from './tabs/ProfilesTab';
import { useParams } from 'react-router-dom';

function Profiles() {
  const screens = Grid.useBreakpoint();
  const { uuid } = useParams();
  const theme = useTheme();
  const token = useSelector(selectIsAuthenticated);

  let titleResponsive = '';
  if (screens.xs === true || screens.sm === true)
    titleResponsive = 'G. Perfiles';
  if (screens.md === true) titleResponsive = 'Gestión de Perfiles';

  return (
    <>
      <CustomAutoScreenWrap>
        <TopBarIcon
          bgColor={theme.colors.primary}
          color={theme.colors.white}
          text={titleResponsive}
          icon={<FaUsersCog size={35} color={theme.colors.white} />}
        />

        <Row justify='center' align='center'>
          <Col
            xs={{ span: 20 }}
            sm={{ span: 20 }}
            md={{ span: 20 }}
            lg={{ span: 18 }}
          >
            <Tabs size='large' defaultActiveKey='1'>
              <Tabs.TabPane
                tab={
                  <TabWrap>
                    <FaUsersCog size={ICON_SIZE} />
                    Perfiles
                  </TabWrap>
                }
                key='1'
              >
                <ProfilesTab token={token} theme={theme} userId={uuid} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </CustomAutoScreenWrap>
    </>
  );
}

export default Profiles;
