import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)`
  & .ant-modal-header {
    padding-top: 4rem;
    border-bottom: none;
    & .ant-modal-title {
      text-align: center;
      font-size: 2.2rem;
      color: #006634;
      font-weight: bold;

      @media (min-width: 767px) {
        font-size: 2.4rem;
      }
    }
  }

  & .ant-modal-body {
    padding-bottom: 4rem;
  }
`;
