import { Spin } from 'antd';
import { HTTPSpinnerWrap } from './Wraps';

function HttpSpinner() {
  return (
    <HTTPSpinnerWrap>
      <Spin size='large' />
    </HTTPSpinnerWrap>
  );
}

export default HttpSpinner;
