import { useState, useRef, useEffect } from 'react';
import { Form, Col, Row, Button, Input, Modal, Spin } from 'antd';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import { IoCarSportSharp, IoPawSharp } from 'react-icons/io5';
import { FaPencilAlt } from 'react-icons/fa';
import {
  ButtonStyled,
  ButtonLightStyled,
  TagsContainer,
  TagStyled,
  PetForm,
  VehicleForm,
  ListData,
  AmountContainer,
  AddTagContainer,
} from 'components';
import {
  preventDefault,
  userRules,
  generateCapitalLetter,
  generateRandomId,
  mapProfileData,
  openNotification,
  mapShippingMembers,
  mapShippingPets,
  mapShippingVisits,
  mapShippingVehicles,
} from 'helpers';
import {
  selectIsGettingProfilesWithRelations as isMakingReq,
  updateProfileWithRelations,
} from 'redux/index';
import { useSelector } from 'react-redux';
import { ERROR } from 'constants';
import { SUCCESS } from 'constants';

function EditUserProfiles({ profile }) {
  const isFetchingData = useSelector(isMakingReq);
  const [form] = Form.useForm();
  const memberInputRef = useRef(null);
  const visitInputRef = useRef(null);
  // entities
  const [members, setMembers] = useState([]);
  const [pets, setPets] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [visits, setVisists] = useState([]);
  // item on edition mode
  const [editionItem, setEditionItem] = useState(null);
  // modals
  const [isVehicleModalVisible, setIsVehicleModalVisible] = useState(false);
  const [isPetModalVisible, setIsPetModalVisible] = useState(false);
  const [isItemsModalVisible, setIsItemsModalVisible] = useState(false);
  // save profile data to edition
  const [currentList, setCurrentList] = useState('Vehiculos');

  useEffect(() => {
    if (profile === null) return;

    const { formObj, frequentVisitsArr, membersArr, vehiclesArr, petsArr } =
      mapProfileData(profile);

    form.setFieldsValue(formObj);

    setVehicles(vehiclesArr);
    setMembers(membersArr);
    setVisists(frequentVisitsArr);
    setPets(petsArr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  if (isFetchingData === true)
    return (
      <center>
        <Spin size='large' />
      </center>
    );

  const handleCloseVehicle = () => {
    setIsVehicleModalVisible(false);
    setEditionItem(null);
  };
  const handleOpenVehicleModal = () => setIsVehicleModalVisible(true);
  const handleItemModalCancel = () => setIsItemsModalVisible(false);

  const handleOpenPetModal = () => setIsPetModalVisible(true);
  const handleClosePet = () => {
    setIsPetModalVisible(false);
    setEditionItem(null);
  };

  // Member Handlers
  const handleMembers = () => {
    const memberValue = memberInputRef.current.input.value.trim();
    const cleanField = () => {
      form.setFieldsValue({
        members: '',
      });
    };
    if (memberValue.length < 6) {
      cleanField();
      return;
    }
    cleanField();
    const mappedValue = generateCapitalLetter(memberValue);
    setMembers((preValue) => [
      {
        id: generateRandomId(),
        value: mappedValue,
      },
      ...preValue,
    ]);
  };
  const handleDeleteMember = (id) => {
    setMembers((preValue) => {
      return preValue.filter((member) => member.id !== id);
    });
  };

  // Set Vehicles
  const handleVehicles = (vehicles) => {
    setVehicles((preValue) => [
      {
        id: generateRandomId(),
        ...vehicles,
      },
      ...preValue,
    ]);
    handleCloseVehicle();
  };
  const handleVehiclesEdition = (newVehicle) => {
    const updatedVehicles = vehicles.filter(
      (vehicle) => vehicle.id !== newVehicle.id
    );
    updatedVehicles.push(newVehicle);
    setVehicles(updatedVehicles);
    setEditionItem(null);
    handleCloseVehicle();
  };

  // Set pets
  const handlePets = (pets) => {
    setPets((preValue) => [{ id: Math.random() * 1000, ...pets }, ...preValue]);
    handleClosePet();
  };
  const handlePetsEdition = (newPet) => {
    const updatedPets = pets.filter((pets) => pets.id !== newPet.id);
    updatedPets.push(newPet);
    setPets(updatedPets);
    setEditionItem(null);
    handleClosePet();
  };

  const handleDeleteFrequentVisit = (id) => {
    setVisists((preValue) => {
      return preValue.filter((visit) => visit.id !== id);
    });
  };

  const showItemsModal = (type) => {
    setCurrentList(type);
    setIsItemsModalVisible(true);
  };

  // Edition
  const handleItemEdition = (item, type) => {
    handleItemModalCancel();
    if (type === 'vehiculos') handleOpenVehicleModal();
    else handleOpenPetModal();
    setEditionItem(item);
  };
  const handleItemElimination = (type, newData) => {
    if (type === 'vehiculos') setVehicles(newData);
    else setPets(newData);
  };

  // Frequent visit Handlers
  const handleFrequentVisits = () => {
    const frequentVisit = visitInputRef.current.input.value.trim();
    const cleanField = () => {
      form.setFieldsValue({
        frequentVisits: '',
      });
    };
    if (frequentVisit.length < 6) {
      cleanField();
      return;
    }
    cleanField();
    const mappedValue = generateCapitalLetter(frequentVisit);
    setVisists((preValue) => [
      {
        id: generateRandomId(),
        value: mappedValue,
      },
      ...preValue,
    ]);
  };

  const handleSubmit = (data) => {
    const formData = { visits, pets, vehicles };

    formData.address = data.address ?? '';
    formData.parkingNumber = data.parking ?? '';

    // Mapers
    if (members.length > 0) formData['members'] = mapShippingMembers(members);

    if (pets.length > 0) formData['pets'] = mapShippingPets(pets);

    if (visits.length > 0) formData['visits'] = mapShippingVisits(visits);

    if (vehicles.length > 0)
      formData['vehicles'] = mapShippingVehicles(vehicles);

    const res = updateProfileWithRelations({
      profileId: profile.id,
      payload: formData,
    });
    if (res === null) {
      openNotification(
        ERROR,
        'Actualización fallida, Por favor verifica los datos e intenta de nuevo.'
      );
      return;
    }

    openNotification(SUCCESS, 'El pefil fue editado exitosamente.');
  };

  const listData =
    currentList.toLocaleLowerCase() === 'vehiculos' ? (
      <ListData
        items={vehicles}
        type={currentList}
        onEdition={handleItemEdition}
        onElimination={handleItemElimination}
      />
    ) : (
      <ListData
        items={pets}
        type={currentList}
        onEdition={handleItemEdition}
        onElimination={handleItemElimination}
      />
    );

  return (
    <>
      <Modal
        centered
        title={currentList === 'Vehiculos' ? 'Vehículos' : currentList}
        visible={isItemsModalVisible}
        onCancel={handleItemModalCancel}
        footer={null}
      >
        {listData}
      </Modal>

      <Modal
        title='Información del vehículo'
        visible={isVehicleModalVisible}
        onCancel={handleCloseVehicle}
        footer={null}
      >
        <VehicleForm
          edition={editionItem}
          onVehicles={handleVehicles}
          onEdition={handleVehiclesEdition}
        />
      </Modal>

      <Modal
        title='Información de la mascota'
        visible={isPetModalVisible}
        onCancel={handleClosePet}
        footer={null}
      >
        <PetForm
          edition={editionItem}
          onPets={handlePets}
          onEdition={handlePetsEdition}
        />
      </Modal>

      <Form form={form} size='large' onFinish={handleSubmit} autoComplete='off'>
        <Row justify='center' align='center' gutter={[16, 16]}>
          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <Form.Item
              label='Dirección del usuario'
              name='address'
              tooltip='Por favor, Escribe la dirección de la persona a agregar.'
              rules={userRules.address}
              hasFeedback
              style={{
                display: 'block',
              }}
            >
              <Input
                placeholder='Dirección de usuario'
                onPressEnter={preventDefault}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <Form.Item
              label='Numero de parqueadero'
              name='parking'
              hasFeedback
              tooltip='Por favor escribe la identificación del lugar de aparcamiento.'
              style={{
                display: 'block',
              }}
              rules={userRules.parking}
            >
              <Input placeholder='B-2' onPressEnter={preventDefault} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <AddTagContainer
              label='Personas con las que vive'
              tooltip='Por favor escribe los nombre y apellidos de las personas con las que vive.'
              style={{
                display: 'block',
              }}
              hasFeedback
            >
              <Form.Item
                name='members'
                hasFeedback
                rules={userRules.members}
                noStyle
              >
                <Input
                  placeholder='Nombre del miembro'
                  ref={memberInputRef}
                  onPressEnter={preventDefault}
                />
              </Form.Item>
              <Button
                type='primary'
                className='add-member'
                onClick={handleMembers}
              >
                <BsFillPersonPlusFill size={22} />
              </Button>
            </AddTagContainer>

            <TagsContainer>
              {members.map((member) => (
                <TagStyled
                  key={member.id}
                  closable
                  onClose={() => handleDeleteMember(member.id)}
                >
                  {member.value}
                </TagStyled>
              ))}
            </TagsContainer>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <AddTagContainer
              label='Visitas frecuentes'
              tooltip='Por favor escribe el nombre completo del visitante.'
              style={{
                display: 'block',
              }}
              hasFeedback
            >
              <Form.Item
                label='Visitas frecuentes'
                name='frequentVisits'
                hasFeedback
                rules={userRules.frequentVisits}
                noStyle
              >
                <Input
                  placeholder='Nombre del visitante'
                  ref={visitInputRef}
                  onPressEnter={preventDefault}
                />
              </Form.Item>
              <Button
                type='primary'
                className='add-member'
                onClick={handleFrequentVisits}
              >
                <BsFillPersonPlusFill size={22} />
              </Button>
            </AddTagContainer>

            <TagsContainer>
              {visits.map((visit) => (
                <TagStyled
                  key={visit.id}
                  closable
                  onClose={() => handleDeleteFrequentVisit(visit.id)}
                >
                  {visit.value}
                </TagStyled>
              ))}
            </TagsContainer>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <AmountContainer>
              <span>
                Total Vehículos: <strong>{vehicles.length}</strong>
              </span>
              <Button
                type='primary'
                size='middle'
                onClick={() => showItemsModal('Vehiculos')}
              >
                <span>Ver Vehículos</span>
                <span>
                  <IoCarSportSharp size={18} />
                </span>
              </Button>
            </AmountContainer>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <AmountContainer>
              <span>
                Total Mascotas: <strong>{pets.length}</strong>
              </span>
              <Button
                type='primary'
                size='middle'
                onClick={() => showItemsModal('Mascotas')}
              >
                <span>Ver Mascotas</span>
                <span>
                  <IoPawSharp size={18} />
                </span>
              </Button>
            </AmountContainer>
          </Col>

          {/* Action add-auto */}
          <Col xs={{ span: 22 }} sm={{ span: 16 }} md={{ span: 9 }}>
            <ButtonLightStyled
              size='large'
              block
              htmlType='button'
              onClick={handleOpenVehicleModal}
            >
              Agregar Vehículo
            </ButtonLightStyled>
          </Col>

          {/* Action add-pet */}
          <Col xs={{ span: 22 }} sm={{ span: 16 }} md={{ span: 9 }}>
            <ButtonLightStyled
              size='large'
              block
              htmlType='button'
              onClick={handleOpenPetModal}
            >
              Agregar Mascota
            </ButtonLightStyled>
          </Col>

          {/* Submit action */}
          <Col
            xs={{ span: 22 }}
            sm={{ span: 16 }}
            md={{ span: 11 }}
            lg={{ span: 8 }}
          >
            <ButtonStyled type='primary' size='large' block htmlType='submit'>
              Editar Perfil
              <FaPencilAlt size={20} />
            </ButtonStyled>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default EditUserProfiles;
