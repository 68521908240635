import styled from 'styled-components';
import { Form } from 'antd';

export const AddTagContainer = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
