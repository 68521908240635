import {
  ADMIN_MODULE as ROLE_ADMIN,
  OPERATOR_MODULE as ROLE_OPERATOR,
  SUPERUSER_MODULE as ROLE_SUPERADMIN,
} from 'constants';

export const ICON_SIZE = 24;

export const roles = [ROLE_ADMIN, ROLE_OPERATOR, ROLE_SUPERADMIN].map(
  (role, i) => {
    return {
      id: i,
      key: role,
      name: role.split('-')[1],
    };
  }
);
