import { fetchWithToken } from './booking-helper';
import { getResidentialId } from '../profiles/profiles-slice';



export async function getBooking() {
  const wordpoint = getResidentialId();
  try {
    const res = await fetchWithToken({
      endpoint: wordpoint,
      method: 'GET',
    });
    const data = await res.json();

    return data;
  } catch (error) {
   console.log(error?.data?.message);
  }
}

export async function validateBooking(payload, field) {

  try {
    const res = await fetchWithToken({
      endpoint: field,
      data: payload,
      method: 'POST',
    });
    const data = await res.json();

    return data;
  } catch (error) {
   console.log(error?.data?.message);
  }
}

export async function cresteBookings(payload) {
  const wordpoint = getResidentialId();
  payload.workPoint = wordpoint;
 
  try {
    const res = await fetchWithToken({ data: payload, method: 'POST' });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error?.data?.message);
  
  }
}

export async function updateStateBookings(booking) {
 
  try {
    const res = await fetchWithToken({  endpoint: booking, method: 'PUT' });
    const data = await res.json();
    return data;
  } catch (error) {
   console.log(error?.data?.message);
  
  }
}
