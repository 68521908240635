import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  posts: [],
  hasMore: true,
  page: null,
  post: null,
  isDeleted: null,
  isMakingRequest: false,
};

const reducers = {
  setPosts: (state, actions) => {
    const { posts, page } = actions.payload;
    if (page !== state.page) {
      state.posts = [...state.posts, ...posts[0]];
      state.page = page;
      if (posts[0].length === 0 || posts[0].length < 20) state.hasMore = false;
    }
  },
  setPost: (state, actions) => {
    const { post } = actions.payload;
    state.post = post;
  },
  cleanPost: (state, actions) => {
    state.post = null;
  },
  setIsDeleted: (state, actions) => {
    const { status, id } = actions.payload;
    state.isDeleted = status;
    if (status === true) {
      state.posts = state.posts.filter((post) => post.id !== id);
    }
  },
  attachPost: (state, action) => {
    const { post } = action.payload;
    state.posts = [post, ...state.posts];
  },
  updatePostLocally: (state, action) => {
    const updatedPost = action.payload;
    state.posts = state.posts.map((post) => {
      if (post.id === updatedPost.id) {
        return {
          id: post.id,
          title: updatedPost.title,
          image: updatedPost.image,
          description: updatedPost.description,
        };
      }
      return post;
    });
  },
  setIsMakingRequest: (state, action) => {
    state.isMakingRequest = action.payload;
  },
  resetPostStates: (state, _) => {
    state.posts = [];
    state.hasMore = true;
    state.page = null;
    state.post = null;
    state.isDeleted = null;
    state.isMakingRequest = false;
  },
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers,
});

const { actions, reducer } = postsSlice;

// ACTIONS
export const {
  setPost,
  setPosts,
  attachPost,
  cleanPost,
  setIsDeleted,
  updatePostLocally,
  resetPostStates,
  setIsMakingRequest,
} = actions;
// GETTERS
export const selectPostsState = (state) => state.postsManagement;
export const selectDeletedPost = (state) => state.postsManagement.isDeleted;

export default reducer;
