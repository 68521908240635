import {
  colors,
  sizes,
  borders,
  spacing,
  breakpoints,
  backgrounds,
} from './properties';

const theme = {
  colors,
  sizes,
  borders,
  spacing,
  breakpoints,
  backgrounds,
};

export default theme;
