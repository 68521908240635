import styled from 'styled-components';

export const BookingSubtitleStyled = styled.h2`
text-align: center;
  margin-top: 1rem;
  color: #707070;
  font-size: 2.4rem;

  @media (min-width: 992px) {
    text-align: center;
    font-size: 3rem;
  }
`;
