import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
  border-radius: 12px;
  margin: 2rem auto;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
