import { Col } from 'antd';
import { NavigationCard } from '../..';
import {
  IoCalendarOutline,
  IoPeopleOutline,
  IoChatbubbleEllipsesOutline,
  IoLinkOutline,
  IoIdCardOutline,
  IoCashOutline,
  IoDocumentTextOutline,
  IoPaperPlaneOutline,
} from 'react-icons/io5';

export const SuperAdminOptions = ({ uuid }) => {
  return (
    <>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        <NavigationCard text='Perfil' to={`/dashboard/perfil/${uuid}`}>
          <IoIdCardOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        <NavigationCard text='Muro' to='/dashboard/muro'>
          <IoChatbubbleEllipsesOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        <NavigationCard text='Reservas' to='/dashboard/reservas'>
          <IoCalendarOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        <NavigationCard text='Recursos' to='/dashboard/recursos'>
          <IoLinkOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        <NavigationCard text='Gestión activos' to='/dashboard/activos'>
          <IoCashOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        <NavigationCard text='Gestión usuarios' to='/dashboard/usuarios'>
          <IoPeopleOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        <NavigationCard
          text='Carga masiva'
          to='/dashboard/cargas-masivas/usuarios'
        >
          <IoDocumentTextOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 5 }}
      >
        {/* Please fix me on the route */}
        <NavigationCard text='Enlaces Dinámicos' to='/dashboard/enlaces'>
          <IoPaperPlaneOutline />
        </NavigationCard>
      </Col>
    </>
  );
};

export const AdminOptions = ({ uuid }) => {
  return (
    <>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Perfil' to={`/dashboard/perfil/${uuid}`}>
          <IoIdCardOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Muro' to='/dashboard/muro'>
          <IoChatbubbleEllipsesOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Reservas' to='/dashboard/reservas'>
          <IoCalendarOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Recursos' to='/dashboard/recursos'>
          <IoLinkOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Gestión activos' to='/dashboard/activos'>
          <IoCashOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Gestión usuarios' to='/dashboard/usuarios'>
          <IoPeopleOutline />
        </NavigationCard>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }}>
        <NavigationCard
          text='Carga masiva'
          to='/dashboard/cargas-masivas/usuarios'
        >
          <IoDocumentTextOutline />
        </NavigationCard>
      </Col>
    </>
  );
};

export const ResidentOptions = ({ uuid }) => {
  return (
    <>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Perfil' to={`/dashboard/perfil/${uuid}`}>
          <IoIdCardOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Muro' to='/dashboard/muro'>
          <IoChatbubbleEllipsesOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Reservas' to='/dashboard/reservas'>
          <IoCalendarOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Recursos' to='/dashboard/recursos'>
          <IoLinkOutline />
        </NavigationCard>
      </Col>
    </>
  );
};

export const OperatorOptions = ({ uuid }) => {
  return (
    <>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Perfil' to={`/dashboard/perfil/${uuid}`}>
          <IoIdCardOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Muro' to='/dashboard/muro'>
          <IoChatbubbleEllipsesOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Reservas' to='/dashboard/reservas'>
          <IoCalendarOutline />
        </NavigationCard>
      </Col>
      <Col
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <NavigationCard text='Recursos' to='/dashboard/recursos'>
          <IoLinkOutline />
        </NavigationCard>
      </Col>
    </>
  );
};
