import axios from 'axios';
import { getToken } from 'helpers/localstorage';

export const optUsersService = axios.create({
  baseURL: process.env.REACT_APP_OPT_USERS_SERVICE,
  headers: {
    'Content-type': 'application/json',
    channel: 'Optimus',
  },
});

optUsersService.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${getToken()}`;
    return config;
  },
  (error) => {
    console.log(error);
    // return Promise.reject(error);
  }
);
