import styled from 'styled-components';

export const TableActionButtonWrap = styled.span`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 0.2rem 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 300ms ease-in;

  &:first-child {
    cursor: pointer;
  }

  &:hover {
    & > svg {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
`;
