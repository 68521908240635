import styled from 'styled-components';
import { ButtonStyled } from '../../../';

export const ButtonLightStyled = styled(ButtonStyled)`
  color: #006634;
  border-color: #006634;
  margin: 1rem 0 0 0;
  transform: scale(0.8);
  transition: all 300ms;

  &:hover,
  &:active {
    transform: scale(0.85);
  }
`;
