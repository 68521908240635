import { useState, useEffect } from 'react';
import 'moment/locale/es';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  TimePicker,
  message,
  Checkbox,
  Switch,
  Grid,
} from 'antd';
import { IoCashOutline } from 'react-icons/io5';
import { days } from '../assets/assets-constants';
import { formRules } from './form-rules';
import { HttpSpinner, TopBarIcon } from 'components';
import { NewBookingContainer, InputNumberCustom, ButtonStyled } from './Wraps';
import {
  getAssets,
  updateAsset,
  cresteAsset,
  assetUpdateState,
} from 'redux/index';

function CreateAsset() {
  const screens = Grid.useBreakpoint();
  const id = useParams();
  const navigate = useNavigate();
  const { Item } = Form;
  const { TextArea } = Input;
  const { RangePicker } = TimePicker;
  const [form] = Form.useForm();
  const [selectedAsset, setAssets] = useState({});
  const [isAssetActive, setIsAssetActive] = useState(false);

  const handlerAssets = async () => {
    if (id.uuid) {
      const asset = await getAssets(id.uuid);
      const { schedule, ...res } = asset;

      const diary = days.map((item) => {
        const signedDay = asset.schedule.find((e) => e.day === item.day);
        if (signedDay) {
          return {
            day: signedDay.day,
            startTime: signedDay.startTime,
            endingTime: signedDay.endingTime,
          };
        } else {
          return item;
        }
      });
      const selectedDays = asset.schedule.map((item) => {
        return item.day;
      });
      setIsAssetActive(res.state === 'Disabled' ? false : true);
      setAssets({ asset: res, schedule: diary, selectedDays });
      form.setFieldsValue({
        days: selectedDays,
        capacity: res.capacity,
        name: res.name,
        description: res.description,
      });
    }
  };

  useEffect(() => {
    handlerAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //console.log(selectedAsset)
  const handleStatus = async (status) => {
    const payload = {
      id: id.uuid,
      action: status === true ? 'Activate' : 'Disable',
    };
    const res = await assetUpdateState(payload);
    if (res === false) {
      message.error(
        'Error de actualización, no fue posible editar el estado del activo. '
      );
      return;
    }
    message.success('Se ha actualizado correctamente el activo.');
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (data) => {
    const request = { schedule: [] };

    for (const property in data) {
      if (property.split('_').includes('hour') && data[property]) {
        request.schedule.push({
          day: `${property.slice(9)}`,
          startTime: data[property][0]._d.toTimeString().slice(0, 5),
          endingTime: data[property][1]._d.toTimeString().slice(0, 5),
          state: true,
        });
      }

      if (id.uuid && !property.includes('_')) {
        if (data[property] && property !== 'days') {
          request[property] = data[property];
        }
      }
      if (!id.uuid && !property.includes('_') && property !== 'days') {
        request[property] = data[property];
      }
    }

    if (id.uuid) {
      request.asset = id.uuid;
      selectedAsset.schedule.forEach(({ day, startTime, endingTime }) => {
        if (
          !data.days.includes(day) &&
          selectedAsset.selectedDays.includes(day)
        ) {
          request.schedule.push({ day, startTime, endingTime, state: false });
        }
      });

      const res = await updateAsset(request);
      if (res.ok) {
        handlerAssets();
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    } else {
      request.multipleReservation =
        request.multipleReservation === undefined ? false : true;
      const res = await cresteAsset(request);
      if (res.ok) {
        message.success(res.message);
        navigate(-1);
      } else {
        message.error(res.message);
      }
      onReset();
    }
  };

  if (id.uuid && selectedAsset.asset === undefined) return <HttpSpinner />;

  let titleResponsive = '';
  if (screens.xs === true) titleResponsive = 'G. Activos';
  else titleResponsive = 'Gestión de Activos';

  // this fix the error of initialValues instead of defaultValue
  // const initialValues = selectedAsset.schedule.map((item) => {
  //   return {
  //     [`hour_day_${item.day}`]:
  //       item.startTime === '00:00'
  //         ? []
  //         : [
  //             moment(item.startTime, 'HH:mm:ss'),
  //             moment(item.endingTime, 'HH:mm:ss'),
  //           ],
  //   };
  // });

  return (
    <>
      <TopBarIcon text={titleResponsive} icon={<IoCashOutline size={40} />} />

      <NewBookingContainer>
        <Form
          onFinish={onFinish}
          autoComplete='off'
          size='large'
          autoSave='on'
          form={form}
          // initialValues={initialValues}
        >
          <Row justify='center'>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 11 }}
            >
              <Item
                name='name'
                label='Nombre completo'
                tooltip='Por favor escribe el nombre completo del activo.'
                style={{
                  display: 'block',
                }}
                rules={!id.uuid && formRules.name}
                hasFeedback
                required
              >
                <Input placeholder='Nombre del activo' />
              </Item>
            </Col>
          </Row>

          <Row justify='center'>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 11 }}
            >
              <Item
                name='days'
                label='Horario'
                tooltip='Por favor selecciona los días, horas de inicio y fin en la que el activo estará disponible para su uso.'
                style={{
                  display: 'block',
                }}
              >
                <Checkbox.Group
                  style={{
                    width: '100%',
                  }}
                >
                  {id.uuid && selectedAsset
                    ? selectedAsset.schedule.map((item) => (
                        <Row
                          key={selectedAsset.schedule.indexOf(item)}
                          justify='start'
                          align='middle'
                        >
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Checkbox name='day' value={item.day}>
                              {item.day}
                            </Checkbox>
                          </Col>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Item name={`hour_day_${item.day}`}>
                              <RangePicker
                                format='h:mm:ss A'
                                placeholder={
                                  item.startTime !== ''
                                    ? [item.startTime, item.endingTime]
                                    : ['Hora Inicio', 'Hora fin']
                                }
                                defaultValue={
                                  item.startTime === '00:00'
                                    ? []
                                    : [
                                        moment(item.startTime, 'HH:mm:ss'),
                                        moment(item.endingTime, 'HH:mm:ss'),
                                      ]
                                }
                              />
                            </Item>
                          </Col>
                        </Row>
                      ))
                    : days.map((item) => (
                        <Row
                          key={days.indexOf(item)}
                          justify='start'
                          align='middle'
                        >
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Checkbox name='day' value={item.day}>
                              {item.day}
                            </Checkbox>
                          </Col>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Item name={`hour_day_${item.day}`}>
                              <RangePicker
                                format='h:mm:ss A'
                                placeholder={['Hora Inicio', 'Hora fin']}
                              />
                            </Item>
                          </Col>
                        </Row>
                      ))}
                </Checkbox.Group>
              </Item>
            </Col>
          </Row>
          {!id.uuid && (
            <Row justify='center'>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 11 }}
              >
                <Item
                  name='multipleReservation'
                  label='El activo permite múltiples reservas en el mismo horario ?'
                  required
                  tooltip='Por favor indícanos si el activo podrá ser reservado por varias personas en el mismo horario.'
                  style={{
                    display: 'block',
                  }}
                  rules={!id.uuid && formRules.multipleReservation}
                >
                  <Switch
                    defaultChecked={false}
                    checkedChildren='Si'
                    unCheckedChildren='No'
                  />
                </Item>
              </Col>
            </Row>
          )}
          <Row justify='center'>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 11 }}
            >
              <Item
                name='capacity'
                label='Capacidad máxima'
                required
                tooltip='Por favor selecciona la cantidad maxima de personas para el activo.'
                style={{
                  display: 'block',
                }}
                rules={!id.uuid && formRules.capacity}
              >
                <InputNumberCustom min={1} placeholder='1' />
              </Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 11 }}
            >
              <Item
                name='description'
                label='Descripción'
                tooltip='Por favor describa el activo y su rol.'
                hasFeedback
                style={{
                  display: 'block',
                }}
                rules={[
                  {
                    required: false,
                  },
                  {
                    max: 255,
                  },
                ]}
              >
                <TextArea
                  rows={6}
                  placeholder='Por favor escribe aquí.'
                  style={{ resize: 'none' }}
                />
              </Item>
            </Col>
          </Row>

          {id.uuid ? (
            <Row justify='center'>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 11 }}
              >
                <Item
                  // name='state'
                  label='Estado'
                  tooltip='Si esta encendido sera activo, de lo contrario sera inactivo.'
                >
                  <Switch
                    defaultChecked={isAssetActive}
                    checkedChildren='Activo'
                    unCheckedChildren='Inactivo'
                    onChange={handleStatus}
                  />
                </Item>
              </Col>
            </Row>
          ) : (
            ''
          )}

          <Row justify='center'>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 11 }}
            >
              <ButtonStyled
                type='primary'
                size='large'
                block
                htmlType='submit'
                onClick={null}
              >
                {!id.uuid ? 'Crear' : 'Actualizar'}
              </ButtonStyled>
            </Col>
          </Row>
        </Form>
      </NewBookingContainer>
    </>
  );
}

export default CreateAsset;
