import {
  useState,
  useEffect,
  // useRef
} from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { IoAttach, IoSwapHorizontalOutline } from 'react-icons/io5';
import { UserModal, IconStyled, ActionsWrap } from 'components';
import { confirmationModal } from 'helpers/confirmations';
import AssociationModalContent from './components/AssociationModalContent';
import {
  syncronizeOdooProject,
  clearModalContent,
  selectUsers,
  selectOptUsers,
  getOdooUsers,
  getOdooUsersInOpt,
} from 'redux/index';
import { openNotification } from 'helpers/notifications';
import { ERROR, SUCCESS } from 'constants';
import { useDispatch, useSelector } from 'react-redux';

function Actions({ project, token, theme, fetchProjects }) {
  // const effectRan = useRef(false);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const yellow = { color: theme.colors.yellowSix };
  const lightBlue = { color: theme.colors.primaryNeon };
  const users = useSelector(selectUsers);
  const [odooUsersTopLevel, setOdooUsers] = useState([]);
  const optUsers = useSelector(selectOptUsers);
  const [optimusUsers, setOptimusUsers] = useState([]);

  useEffect(() => {
    setOdooUsers(users);
    setOptimusUsers(optUsers);
  }, [users, optUsers]);

  // I'm working here, I have to find a way of catch the users from odoo the first time is loaded

  const fetchOdooUsers = () => dispatch(getOdooUsers({ token }));
  const fetchOdooUsersInOpt = () =>
    dispatch(getOdooUsersInOpt({ projectId: project.id }));

  const toggleModal = () => {
    setIsModalVisible((preValue) => {
      const newStatus = !preValue;
      if (newStatus === false) {
        dispatch(clearModalContent());
      } else {
        // if (effectRan.current === false) {
        fetchOdooUsers();
        fetchOdooUsersInOpt();
        // effectRan.current = true;
        // }
      }
      return newStatus;
    });
  };

  const onSyncronize = async () => {
    const res = await syncronizeOdooProject(project.id);
    if (res.statusCode !== 200 && res.statusCode !== 500) {
      openNotification(ERROR, res.message, IoSwapHorizontalOutline);
      return;
    }
    if (res.statusCode === 500) {
      openNotification(
        ERROR,
        'Error de consulta, por favor intenta nuevamente.',
        IoSwapHorizontalOutline
      );
      return;
    }
    openNotification(SUCCESS, res.message, IoSwapHorizontalOutline);
    fetchProjects();
  };

  const confirmationModalContent = [
    '¿Esta seguro de sincronizar el proyecto?',
    null,
    'Sincronizar',
    'primary',
    null,
    null,
    onSyncronize,
  ];

  return (
    <>
      <UserModal
        title='Asociar Usuarios'
        isModalVisible={isModalVisible}
        handleSaveModal={toggleModal}
        handleCancelModal={toggleModal}
        saveText={''}
        width='70rem'
      >
        <AssociationModalContent
          token={token}
          project={project}
          // users={users}
          // optUsers={optUsers}
          // onFetchOdoo={fetchOdooUsers}
          // onFetchOpt={fetchOdooUsersInOpt}
          onFetchProjects={fetchProjects}
          odooUsersTopLevel={odooUsersTopLevel}
          onSetOdooUsers={setOdooUsers}
          optimusUsersTopLevel={optimusUsers}
          setOptimusUsers={setOptimusUsers}
        />
      </UserModal>

      <ActionsWrap>
        <Tooltip title='Asociar Usuarios'>
          <IconStyled onClick={toggleModal}>
            <IoAttach style={yellow} />
          </IconStyled>
        </Tooltip>

        <Tooltip title='Sincronizar Proyecto con Odoo'>
          <IconStyled
            onClick={() => confirmationModal(...confirmationModalContent)}
          >
            <IoSwapHorizontalOutline style={lightBlue} />
          </IconStyled>
        </Tooltip>
      </ActionsWrap>
    </>
  );
}

Actions.propTypes = {
  project: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Actions;
