// TOKEN KEYS
export const TOKEN_KEY = 'VE9LRU5fS0VZ';
export const TOKEN_TTL_KEY = 'VE9LRU5fVFRMX0tFWQ==';
export const ROLE_KEY = 'bmF2aWdhdGlvbi10eXBl';

// PROFILES KEYS
export const PROJECT_KEY = 'UFJPRklMRV9LRVk';
export const PROFILE_KEY = 'UFJPRklMRV9LRVk=';

// ANT DESING MESSAGES KEY
export const AUTH_KEY = 'auth';

// CHANNELS
export const OPTIMUS_CHANNEL = 'Optimus';

// MODULES TYPES
export const ADMIN_MODULE = 'Optimus-Administrador';
export const RESIDENT_MODULE = 'Optimus-Residente';
export const OPERATOR_MODULE = 'Optimus-Operador';
export const SUPERUSER_MODULE = 'Optimus-Superuser';

// NOTIFICATIONS
export const ERROR = 'error';
export const SUCCESS = 'success';
export const LOANDING = 'loading';

// SWITCH PROFILE COMPONENT
export const PROFILE = 'profile';
export const USER_EDITION = 'user_edition';


export const ADMINISTRATIVEROLES = [
    'Optimus-Administrador',
    'Optimus-Operador',
    'Optimus-Superuser',
];
// ICON SIZE ON IN TABS
export const ICON_SIZE = 32;
