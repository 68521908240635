import { useState, useEffect, useRef } from 'react';
import { Row, Col, Grid } from 'antd';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { IoDocumentTextOutline } from 'react-icons/io5';
import Actions from './actions/Actions';
import { BulkUploadContainer, TagStyled, TagDateStyled } from './Wraps';
import {
  getUserBulkUploadLogs,
  selectProjectId,
  selectIsAuthenticated,
  selectProfileId,
} from 'redux/index';
import { TopBarIcon, TableStyled } from 'components';
import { nofityLogElimination } from './helpers';
import TopActions from './top-actions';

function UsersBulkUpload() {
  const screens = Grid.useBreakpoint();
  const effectRan = useRef(false);
  const theme = useTheme();
  const residentialId = useSelector(selectProjectId);
  const profileId = useSelector(selectProfileId);
  const token = useSelector(selectIsAuthenticated);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: 'Usuarios a cargar',
      dataIndex: 'totalRecords',
      responsive: ['md'],
      sorter: true,
      render: (value) => (
        <TagStyled color={theme.colors.blueSix}>{value}</TagStyled>
      ),
    },
    {
      title: 'Cargas exitosas',
      dataIndex: 'successfulUploads',
      // responsive: ['md'],

      sorter: true,
      render: (value) => (
        <TagStyled color={theme.colors.primaryNeon}>{value}</TagStyled>
      ),
    },
    {
      title: 'Cargas fallidas',
      dataIndex: 'failedUploads',
      // responsive: ['md'],

      sorter: true,
      render: (value) => (
        <TagStyled color={theme.colors.redFive}>{value}</TagStyled>
      ),
    },
    {
      title: 'Fecha y Hora',
      dataIndex: 'createdAt',
      // responsive: ['md'],

      sorter: true,
      render: (value) => (
        <TagDateStyled color={theme.colors.blueSky}>{value}</TagDateStyled>
      ),
    },
    {
      title: 'Acciones',
      key: 'operation',
      align: 'right',

      render: (log) => (
        <Actions log={log} onElimination={handleLogElimination} token={token} />
      ),
    },
  ];

  const handleLogElimination = (id) => {
    let page;
    const totalUsers = logs.length;
    if (totalUsers > 1) page = pagination.current;
    if (totalUsers === 1 && pagination.current === 1) page = pagination.current;
    if (totalUsers === 1 && pagination.current !== 1)
      page = pagination.current - 1;
    nofityLogElimination(
      id,
      token,
      {
        limit: pagination.pageSize,
        page,
        total: pagination.total,
      },
      fetchData
    );
  };

  const fetchData = async (params = {}) => {
    setLoading(true);
    const res = await getUserBulkUploadLogs(params, residentialId, token);
    if (res == null) {
      setLogs([]);
      setLoading(false);
      return;
    }
    const { logs, total } = res;
    setLogs(logs);
    setPagination({
      current: params.page,
      pageSize: params.limit,
      total,
    });
    setLoading(false);
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    const { current: page, pageSize: limit } = newPagination;
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: page,
      limit,
    });
  };

  useEffect(() => {
    if (effectRan.current === false) {
      fetchData({
        limit: pagination.pageSize,
        page: pagination.current,
      });
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topActionsProps = {
    theme,
    profileId,
    token,
    fetchData,
    pagination,
  };

  let titleResponsive = '';
  if (screens.xs === true || screens.sm === true)
    titleResponsive = 'Carga Masiva';
  if (screens.md === true) titleResponsive = 'Usuarios Carga Masiva';

  return (
    <>
      <TopBarIcon
        bgColor={theme.colors.primary}
        color={theme.colors.white}
        text={titleResponsive}
        icon={<IoDocumentTextOutline size={35} color={theme.colors.white} />}
      />

      <BulkUploadContainer>
        <Row justify='center' align='center' style={{ marginBottom: '2rem' }}>
          <Col xs={{ span: 24 }} lg={{ span: 18 }}>
            <TopActions {...topActionsProps} />
          </Col>
        </Row>
        <Row justify='center' align='center'>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 18 }}
          >
            <TableStyled
              columns={columns}
              rowKey={(user) => user.id}
              dataSource={logs}
              pagination={pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </BulkUploadContainer>
    </>
  );
}

export default UsersBulkUpload;
