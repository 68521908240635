import styled from 'styled-components';
import { Button, Input, Form } from 'antd';

export const FullScreenWrap = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;
export const SpaceYWrap = styled.div`
  margin-top: 5rem;
  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: 992px) {
    margin-top: 5rem;
  }
`;
export const ImgLogo = styled.div`
  & > img {
    max-width: 60%;
    max-height: 60%;
    @media (min-width: 992px) {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
export const InputWrap = styled.div`
  & > input,
  & > span input {
    border-radius: 0.8rem;
  }
`;
export const ButtonStyled = styled(Button)`
  white-space: normal;
  height: auto;
  border-radius: 12px;
  margin-top: 1rem;
  text-align: center !important;
  justify-content: center !important;
  font-size: 1.4rem;
  @media (min-width: 992px) {
    border-radius: 8px;
    font-size: auto;
  }
`;
export const InputStyled = styled(Input)`
  margin-top: 1rem;
  border-radius: 8px;
  padding: 0.8rem 1rem;
  font-size: 1.4rem;
  @media (min-width: 992px) {
    border-radius: 8px;
    font-size: auto;
  }
`;
export const PasswordStyled = styled(Input.Password)`
  border-radius: 8px;
  padding: 0.8rem 1rem;
`;
export const Title = styled.h2`
  margin-top: 1rem;
  color: #707070;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  @media (min-width: 992px) {
    text-align: center;
    font-size: 3rem;
  }
`;
export const FormItem = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex !important;
    gap: 1rem !important;
    justify-content: center !important;
  }
`;
