import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfilesActions from '../tab-actions/ProfilesActions';
import ProfilesTable from '../tables/ProfilesTable';
import { getProfilesByUserId } from 'redux/index';

function ProfilesTab({ theme, token, userId }) {
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const searching = useRef({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const fetchProfiles = async (params = {}) => {
    setLoading(true);
    Object.assign(params, searching.current);
    console.log(params);
    const res = await getProfilesByUserId({ userId, params });

    if (res == null) {
      navigate(-1);
      setProfiles([]);
      setLoading(false);
      return;
    }
    const { data, total } = res;
    setProfiles(data);
    setPagination({
      current: params.page,
      pageSize: params.limit,
      total,
    });
    setLoading(false);
  };

  return (
    <>
      <ProfilesActions fetchProfiles={fetchProfiles} searching={searching} />
      <ProfilesTable
        theme={theme}
        token={token}
        profiles={profiles}
        loading={loading}
        pagination={pagination}
        fetchProfiles={fetchProfiles}
      />
    </>
  );
}

export default ProfilesTab;
