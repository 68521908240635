import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import ProfilesActions from '../actions/ProfilesActions';
import { TableStyled, TagStyled } from 'components';
import { IoRemoveOutline } from 'react-icons/io5';

function ProfilesTable({
  theme,
  profiles,
  loading,
  pagination,
  fetchProfiles,
}) {
  const effectRan = useRef(false);

  const columns = [
    {
      title: 'Proyecto',
      dataIndex: 'project',
      width: 'auto',
      sorter: true,
      filterSearch: true,
      render: (value) => (
        <TagStyled color={theme.colors.blueSix}>{value.name}</TagStyled>
      ),
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      responsive: ['lg'],
      width: 'auto',
      sorter: true,
      render: (value) =>
        value == null ? <IoRemoveOutline size={30} /> : value,
    },
    {
      title: 'Vehículos',
      dataIndex: 'vehicles',
      responsive: ['lg'],
      width: '1%',
      render: (value) => (
        <TagStyled color={theme.colors.cianSix}>{value.length}</TagStyled>
      ),
    },
    {
      title: 'Visitas',
      dataIndex: 'visits',
      responsive: ['lg'],
      width: '1%',
      render: (value) => (
        <TagStyled color={theme.colors.cianSix}>{value.length}</TagStyled>
      ),
    },
    {
      title: 'Mascotas',
      dataIndex: 'pets',
      responsive: ['lg'],
      width: '1%',

      render: (value) => (
        <TagStyled color={theme.colors.cianSix}>{value.length}</TagStyled>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      responsive: ['lg'],
      width: '1%',
      sorter: true,
      render: (status) => {
        return (
          <>
            {status === true ? (
              <TagStyled color={theme.colors.primaryNeon}>Activo</TagStyled>
            ) : (
              <TagStyled color={theme.colors.graySix}>Inactivo</TagStyled>
            )}
          </>
        );
      },
    },
    {
      title: 'Acciones',
      key: 'operation',
      align: 'center',
      width: '5%',
      render: (profile) => (
        <ProfilesActions
          profile={profile}
          profiles={profiles}
          theme={theme}
          fetchProfiles={fetchProfiles}
          pagination={pagination}
        />
      ),
    },
  ];

  const handleTableChange = (newPagination, _filters, sorter) => {
    const { current: page, pageSize: limit } = newPagination;
    fetchProfiles({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: page,
      limit,
    });
  };

  useEffect(() => {
    if (effectRan.current === false) {
      fetchProfiles({
        limit: pagination.pageSize,
        page: pagination.current,
      });
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableStyled
      columns={columns}
      rowKey={(profile) => profile.id}
      dataSource={profiles}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      showSorterTooltip={false}
    />
  );
}

ProfilesTable.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default ProfilesTable;
