export const stepOneRules = {
  userName: [
    { transform: (value) => value.trim() },
    {
      required: true,
      message: '¡Por favor ingresa tú usuario o email!',
    },
    {
      whitespace: true,
      message: '¡El nombre de usuario no puede estar vacío!',
    },
    {
      min: 8,
      message: '¡El nombre de usuario debe tener minimo 8 caracteres!',
    },
    {
      max: 80,
      message: '¡El nombre de usuario debe tener máximo 50 caracteres!',
    },
  ],
};

export const stepTwoRules = {
  customDigit: [
    { transform: (value) => value.trim() },
    {
      required: true,
      message: false,
    },
    {
      max: 1,
      min: 1,
      message: false,
    },
  ],
  digit: [
    { transform: (value) => value.trim() },
    {
      required: true,
      message: false,
    },
  ],

  password: [
    { transform: (value) => value.trim() },
    {
      required: true,
      message: '¡Por favor ingresa tú nueva contraseña!',
    },
    {
      whitespace: true,
      message: '¡La contraseña no pueder estar vacía!',
    },
    {
      min: 8,
      message: '¡Por favor ingresa al menos 8 caracteres!',
    },
    {
      max: 80,
      message: '¡La contraseña debe tener máximo 80 caracteres!',
    },
  ],

  confirmation: [
    { transform: (value) => value.trim() },
    {
      required: true,
      message: 'Por favor confirma tú contraseña',
    },
    {
      whitespace: true,
      message: '¡La contraseña no pueder estar vacía!',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('¡Las contraseñas ingresadas no coinciden!')
        );
      },
    }),
  ],
};
