import { optUsersService } from 'services';
import { setUsers, setRequest, setOptUsers } from './projects-slice';
import { setWildcard } from 'helpers/routes-mapper';

const SERVICES_PATH = {
  getProjects: '/projects',
  getOdooProjects: 'odoo/projects',
  createOdooProject: '/projects',
  getAllUsers: '/odoo/users',
  getOdooUsersInOpt: '/users/odoo/:id',
  attachUsersToProject: '/odoo/users/create',
  syncronizeOdooProject: '/odoo/syncronize-project/:id',
  getAllOptProjects: '/projects/opt/projects',
  attachToProject: '/projects/:id/attach',
};

export async function getProjects(params) {
  try {
    const res = await optUsersService.get(`${SERVICES_PATH.getProjects}`, {
      params: params,
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function getOdooProjects(token) {
  try {
    const res = await optUsersService.get(`${SERVICES_PATH.getOdooProjects}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function createOdooProject(payload, token) {
  try {
    await optUsersService.post(SERVICES_PATH.createOdooProject, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return true;
  } catch {
    return null;
  }
}

export function getOdooUsers({ token }) {
  return async (dispatch) => {
    try {
      dispatch(setRequest({ status: true, type: 'odoo' }));
      const res = await optUsersService.get(SERVICES_PATH.getAllUsers, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setRequest({ status: false, type: 'odoo' }));
      dispatch(setUsers({ users: res.data }));
    } catch {
      dispatch(setRequest({ status: false, type: 'odoo' }));
      return null;
    }
  };
}

export function getOdooUsersInOpt({ projectId }) {
  return async (dispatch) => {
    try {
      dispatch(setRequest({ status: true, type: 'opt' }));
      const url = setWildcard(SERVICES_PATH.getOdooUsersInOpt, projectId);
      const res = await optUsersService.get(url);
      dispatch(setRequest({ status: false, type: 'opt' }));
      dispatch(setOptUsers({ optUsers: res.data }));
    } catch {
      dispatch(setRequest({ status: false, type: 'opt' }));
      return null;
    }
  };
}

export async function attachOdooUsersToProject(payload) {
  try {
    await optUsersService.post(SERVICES_PATH.attachUsersToProject, payload);
    return true;
  } catch {
    return null;
  }
}

export async function syncronizeOdooProject(projectId) {
  try {
    const url = setWildcard(SERVICES_PATH.syncronizeOdooProject, projectId);
    const res = await optUsersService.post(url);

    return res.data;
  } catch (error) {
    const { statusCode, message } = error?.response?.data;
    return { statusCode, message };
  }
}

export async function getAllOptProjects({ userId }) {
  try {
    const res = await optUsersService.post(SERVICES_PATH.getAllOptProjects, {
      userId,
    });
    return res.data;
  } catch {
    return null;
  }
}

export async function attachUserToProject({ projectId, userId }) {
  try {
    const url = setWildcard(SERVICES_PATH.attachToProject, projectId);
    const res = await optUsersService.post(url, { userId });
    return res.data;
  } catch {
    return null;
  }
}
