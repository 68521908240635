import { optUsersService } from '../../services/ajax/opt-users-service';
import {
  setStep,
  setStepError,
  setErrorMessage,
  setReqStatus,
} from './reset-password-slice';
const SERVICES_PATH = {
  resetPassword: '/auth/reset-password',
  changePassword: '/auth/change-password',
};

export function resetPasswordHttp({ userName }) {
  return async (dispatch) => {
    try {
      dispatch(setReqStatus({ status: true }));
      const res = await optUsersService.post(SERVICES_PATH.resetPassword, {
        userName,
      });
      dispatch(setStep({ step: res.data.step }));
    } catch (error) {
      dispatch(setReqStatus({ status: false }));
      dispatch(setStepError());
      dispatch(
        setErrorMessage({ errorMessage: error?.response?.data?.message })
      );
    }
  };
}

export function changePasswordHttp({ code, userName, password, confirmation }) {
  return async (dispatch) => {
    try {
      dispatch(setReqStatus({ status: true }));
      const res = await optUsersService.post(SERVICES_PATH.changePassword, {
        code,
        userName,
        password,
        confirmation,
      });
      dispatch(setStep({ step: res.data.step }));
    } catch (error) {
      dispatch(setReqStatus({ status: false }));
      dispatch(setStepError());
      dispatch(
        setErrorMessage({ errorMessage: error?.response?.data?.message })
      );
    }
  };
}
