import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Upload,
  Spin,
  Modal,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { IoCloudUploadSharp } from 'react-icons/io5';
import { CloudUploadOutlined } from '@ant-design/icons';
import {
  preventDefault,
  userRules,
  getBase64,
  genders,
  prefixSelector,
  mapUserData,
  openNotification,
  userEditionRules,
} from 'helpers';
import { ERROR } from 'constants';
import { ButtonStyled } from 'components';
import { SUCCESS } from 'constants';
import { uploadUserImageToS3, updateUserData } from 'redux/index';

function UserForm({ form, onFinish, preview = null, isMakingRequest }) {
  return (
    <>
      <Modal
        visible={preview.previewVisible}
        title={preview.previewTitle}
        footer={null}
        onCancel={preview.handleCancel}
      >
        <img
          alt='imagen de la publicaicón'
          style={{
            width: '100%',
          }}
          src={preview.previewImage}
        />
      </Modal>

      <Form size='large' onFinish={onFinish} autoComplete='off' form={form}>
        <Row justify='center' gutter={[{ md: 48 }, { xs: 16, sm: 16, md: 16 }]}>
          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <Form.Item
              label='Nombre completo'
              name='fullName'
              required
              tooltip='Por favor escribe los nombres y apellidos de la persona.'
              rules={userRules.fullName}
              style={{
                display: 'block',
              }}
            >
              <Input
                placeholder='Nombre completo'
                onPressEnter={preventDefault}
              />
            </Form.Item>

            <Form.Item
              label='Nombre de usuario'
              name='userName'
              required
              tooltip='Por favor, Escribe el nombre de usuario, por ejemplo: carlos-mario11'
              rules={userEditionRules.userName}
              style={{
                display: 'block',
              }}
            >
              <Input
                placeholder='Nombre de usuario'
                onPressEnter={preventDefault}
              />
            </Form.Item>

            <Form.Item
              label='Email del usuario'
              name='email'
              required
              tooltip='Por favor, Escribe el correo electronico del usuario a crear, recuerda que en este correo será enviado un correo de verificación para validar la cuenta.'
              rules={userEditionRules.email}
              style={{
                display: 'block',
              }}
            >
              <Input
                placeholder='example@gmail.com'
                onPressEnter={preventDefault}
              />
            </Form.Item>

            <Form.Item
              tooltip='Por favor ingresa un número celular, recuerda que este debe contener al menos 10 dígitos numéricos.'
              name='phoneNumber'
              label='Número celular'
              rules={userEditionRules.cellphone}
              style={{ display: 'block' }}
            >
              <Input
                addonBefore={prefixSelector}
                placeholder='3122343578'
                onPressEnter={preventDefault}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 11 }}>
            <Form.Item
              label='Fecha de nacimiento'
              name='dateOfBirth'
              tooltip='Por favor seleccionar la fecha de nacimiento.'
              rules={userRules.dayOfBirthday}
              style={{
                display: 'block',
              }}
            >
              <DatePicker
                placeholder='Fecha'
                style={{
                  display: 'block',
                }}
              />
            </Form.Item>
            <Form.Item
              label='Sexo'
              name='gender'
              tooltip='Por favor seleccionar el tipo de sexo.'
              style={{
                display: 'block',
              }}
              rules={userRules.gender}
            >
              <Select placeholder='Seleccionar...'>
                {genders.map((g) => (
                  <Select.Option value={g.key} key={g.key}>
                    {g.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Imagen'
              tooltip='Selecciona una imagen de tipo png, jpeg o jpg.'
              style={{
                display: 'block',
              }}
            >
              <ImgCrop rotate aspect={1} rules={[{ required: true }]}>
                <Upload
                  {...preview.props}
                  listType='picture-card'
                  onPreview={preview.handlePreview}
                  accept='.jpg, .jpeg, .png'
                >
                  <CloudUploadOutlined />
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>

          {/* Submit action */}
          <Col xs={{ span: 22 }} sm={{ span: 16 }} md={{ span: 11 }}>
            <ButtonStyled
              type='primary'
              size='large'
              block
              htmlType='submit'
              loading={isMakingRequest}
            >
              Editar Usuario
              <IoCloudUploadSharp size={20} />
            </ButtonStyled>
          </Col>
        </Row>
      </Form>
    </>
  );
}

function EditUserData({ isRequestingUser, user }) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [oldFile, setOldFile] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const props = {
    name: 'user-img',
    limit: 1,
    multiple: false,
    maxCount: 1,
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const newFileName = file.name;
      const whiteList = ['image/jpeg', 'image/png'];
      if (!whiteList.includes(file.type)) return;
      if (fileList.length > 0) {
        const oldFileName = fileList[0].name;
        if (newFileName === oldFileName) return;
      }
      if (file.size / (1024 * 1024) > 1) {
        openNotification(ERROR, 'La imagen debe ser menor a una 1 mega.');
        return;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    if (user == null) return;
    const { image, ...formData } = mapUserData(user);
    form.setFieldsValue(formData);
    setFileList(image);
    setOldFile(image.length !== 0 ? image[0]?.name : null);
  }, [user, form]);

  if (isRequestingUser)
    return (
      <center>
        <Spin size='large' />
      </center>
    );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);

    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleFinish = async (data) => {
    setIsMakingRequest(true);
    const payload = {
      fullName: data.fullName,
      email: data.email,
      userName: data.userName,
      phoneNumber: `+${data.prefix} ${data.phoneNumber}`,
    };

    // Upload Image to s3
    if (fileList.length > 0) {
      const newFile = fileList[0].name;
      if (oldFile !== newFile) {
        const iFormData = new FormData();
        iFormData.append('user-img', fileList[0]);
        const userImage = await uploadUserImageToS3(iFormData, null);
        if (userImage === null) {
          openNotification(
            ERROR,
            'Carga de imagen fallida, Por favor verifica que la imagen sea png, jpeg or jpg con un tamaño menor a 1 mega.'
          );
          setIsMakingRequest(false);
          return;
        }
        payload['image'] = userImage;
      }
    }

    if (data.dateOfBirth)
      payload.dateOfBirth = new Date(data.dateOfBirth.format('YYYY-MM-DD'));

    if (data.gender) payload.gender = data.gender;

    // make request
    const res = await updateUserData({ id: user.id, payload });

    if (res == null) {
      openNotification(
        ERROR,
        'Edición fallida, Por favor verifica el correo, telefono,y nombre de usuario e intenta nuevamente.'
      );
      setIsMakingRequest(false);
      return;
    }

    setIsMakingRequest(false);
    openNotification(
      SUCCESS,
      'Los datos del usuario fueron actualizados exitosamente.'
    );
  };

  return (
    <>
      <UserForm
        form={form}
        onFinish={handleFinish}
        isMakingRequest={isMakingRequest}
        preview={{
          previewVisible,
          previewImage,
          previewTitle,
          handleCancel,
          handlePreview,
          props,
        }}
      />
    </>
  );
}

export default EditUserData;
