import { Skeleton } from 'antd';
import {
  SkeletonContainer,
  SkeletonImgContainer,
  SkeletonContent,
} from './Wraps';

function UserInfoSkeleton() {
  return (
    <SkeletonContainer>
      <SkeletonImgContainer>
        <img src={'/assets/images/no-img.png'} alt='loanding...' />
      </SkeletonImgContainer>
      <SkeletonContent>
        <Skeleton.Button active size={15} className='skeleton-fullname' />
        <Skeleton.Button active size={15} className='skeleton-residential' />
        <Skeleton.Button active size={15} className='skeleton-address' />
      </SkeletonContent>
    </SkeletonContainer>
  );
}

export default UserInfoSkeleton;
