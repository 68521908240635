import styled from 'styled-components';
import { List, Avatar } from 'antd';

export const ListStyled = styled(List)`
  position: relative;
  width: 90%;
  height: auto;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: ${(props) => props.theme.borders['12']};
  margin-top: 6rem;
  margin-bottom: 6rem;
  z-index: 10000;
  background-color: ${(props) => props.theme.colors.white};

  & .ant-spin-nested-loading {
    height: 100%;
  }

  & li {
    padding: 2rem 4rem;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  & li:hover {
    padding: 2rem 4rem;
    background-color: rgb(0, 133, 96, 0.8);
    /* background-color: rgba(82, 196, 26, 0.5); */
    border-radius: ${(props) => props.theme.borders['12']};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: scale(1.05);
    font-weight: bold;

    & h4 {
      color: ${(props) => props.theme.colors.black};
    }
  }
`;

export const AvatarStyled = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
