import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import Actions from '../actions/Actions';
import { TableStyled, TagStyled } from 'components';

function LinksTable({
  theme,
  token,
  links,
  loading,
  pagination,
  fetchLinks,
  projectId,
}) {
  const effectRan = useRef(false);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      sorter: true,
      render: (value) => (
        <TagStyled color={theme.colors.blueSix}>{value}</TagStyled>
      ),
    },
    {
      title: 'Registros Realizados',
      dataIndex: 'completedRecords',
      sorter: true,
      responsive: ['sm'],
      render: (value) => (
        <TagStyled color={theme.colors.blueSky}>{value}</TagStyled>
      ),
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      responsive: ['md'],
      sorter: true,
    },
    {
      title: 'Fecha de caducidad',
      dataIndex: 'expirationTime',
      responsive: ['md'],
      sorter: true,
      render: (value) => (
        <TagStyled color={theme.colors.redFive}>{value}</TagStyled>
      ),
    },
    {
      title: 'Acciones',
      key: 'operation',
      align: 'center',
      render: (link) => (
        <Actions
          link={link}
          token={token}
          theme={theme}
          fetchLinks={fetchLinks}
        />
      ),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    const { current: page, pageSize: limit } = newPagination;
    fetchLinks({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: page,
      limit,
      projectId,
    });
  };

  useEffect(() => {
    if (effectRan.current === false) {
      fetchLinks({
        limit: pagination.pageSize,
        page: pagination.current,
        projectId,
      });
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableStyled
      columns={columns}
      rowKey={(link) => link.id}
      dataSource={links}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
    />
  );
}

LinksTable.propTypes = {
  theme: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  fetchLinks: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default LinksTable;
