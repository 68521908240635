import { createSlice } from '@reduxjs/toolkit';
import { TOKEN_KEY, TOKEN_TTL_KEY, ROLE_KEY } from '../../constants';
const initialState = {
  isAuth: false,
  token: null,
  ttl: null,
  isBadReq: false,
};
const reducers = {
  setSignIn: (state, action) => {
    const { token, ttl, role } = action.payload;
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
    localStorage.setItem(ROLE_KEY, JSON.stringify(btoa(role)));
    localStorage.setItem(TOKEN_TTL_KEY, JSON.stringify(btoa(ttl)));
    state.token = token;
    state.ttl = ttl;
    state.isAuth = true;
    state.isBadReq = false;
  },
  setLogOut: (state, _action) => {
    localStorage.clear();
    return initialState;
  },

  setReqError: (state, action) => {
    state.isBadReq = action.payload.isBadReq;
  },
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers,
});
const { actions, reducer } = authSlice;
// ACTIONS
export const { setSignIn, setLogOut, setReqError } = actions;
// GETTERS
export const selectAuthState = (state) => state.auth;
export const selectIsAuthenticated = () => {
  try {
    const rawToken = localStorage.getItem(TOKEN_KEY);
    if (!rawToken) return null;
    return JSON.parse(rawToken);
  } catch (error) {
    return null;
  }
};
export const selectUserRole = () => {
  try {
    const role = localStorage.getItem(ROLE_KEY);
    if (!role) return null;
    const parsedRole = JSON.parse(role);
    const decoded = atob(parsedRole);
    return decoded;
  } catch {
    return null;
  }
};

export const selectTTL = () => {
  try {
    const rawTTL = localStorage.getItem(TOKEN_TTL_KEY);
    if (!rawTTL) {
      return 0;
    }
    const parsedTTL = JSON.parse(rawTTL);
    const decoded = atob(parsedTTL);
    if (decoded.length < 9) {
      return 0;
    }
    return Number(decoded);
  } catch {
    return 0;
  }
};

export default reducer;
