import { useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { downloadUsersTemplate } from 'redux/index';
import { readAndDonwloadFile } from 'helpers/file-reader';
import { ERROR } from 'constants';
import { openNotification } from 'helpers/notifications';
import { ButtonStyled } from 'components';

function ManageTemplate({ token, theme }) {
  const [isMakingReq, setIsMakingReq] = useState(false);
  const handleDownloadTemplate = async () => {
    setIsMakingReq(true);
    const res = await downloadUsersTemplate(token);
    setIsMakingReq(false);
    if (res === false) {
      openNotification(
        ERROR,
        'Error de descarga, por favor comunicate con soporte.',
        IoCloudDownloadOutline
      );
      return;
    }
    if (res?.statusCode === 400 || res?.statusCode === 404) {
      openNotification(ERROR, res.message, IoCloudDownloadOutline);
      return;
    }
    readAndDonwloadFile(res);
  };

  const btnColor = {
    color: theme.colors.primaryNeon,
  };
  return (
    <>
      <ButtonStyled
        type='default'
        size='large'
        onClick={handleDownloadTemplate}
      >
        <span>Descargar Plantilla</span>
        <span>
          {isMakingReq === true ? (
            <LoadingOutlined style={btnColor} />
          ) : (
            <FileExcelOutlined size={20} style={btnColor} />
          )}
        </span>
      </ButtonStyled>
    </>
  );
}

export default ManageTemplate;
