import { useCallback, useState, useEffect, useRef } from 'react';
import { Grid } from 'antd';
import { useMatch, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserRole, setLogOut, selectTTL } from '../../../redux';
import { FullScreenWrap, DesktopNavigation, MobileNavigation } from '../..';
import { AutoScreenWrap, OpenMenu } from '../../';
import {
  ADMIN_MODULE,
  OPERATOR_MODULE,
  RESIDENT_MODULE,
  SUPERUSER_MODULE,
} from '../../../constants';
import { logoutUser, events } from './logout';

function DashBoard() {
  let timer;
  const effectRan = useRef(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const userRole = useSelector(selectUserRole);
  const tokenTTL = useSelector(selectTTL);
  const isDashboard = !!useMatch('/dashboard');
  const isHome = !!useMatch('/dashboard/inicio');
  const isProjects = !!useMatch('/dashboard/proyectos');
  const isProfile = !!useMatch('/dashboard/:uuid/perfiles');
  const isNotShowMenu = isHome || isDashboard || isProjects || isProfile;

  const handleDrawer = useCallback(
    () => setVisible((preValue) => !preValue),
    []
  );
  const handleCloseDrawer = useCallback(() => setVisible(false), []);

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      events.forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutUser();
    }, tokenTTL);
  };
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (
      ![
        RESIDENT_MODULE,
        OPERATOR_MODULE,
        ADMIN_MODULE,
        SUPERUSER_MODULE,
      ].includes(userRole)
    ) {
      dispatch(setLogOut());
    }
  }, [dispatch, userRole]);
  useEffect(() => {
    if (effectRan.current === false) {
      events.forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nestedRoute = (
    <>
      <Outlet />
      {!screens.lg && !isNotShowMenu && <MobileNavigation />}
    </>
  );

  return (
    <>
      {screens.lg && !isNotShowMenu && (
        <OpenMenu onOpen={handleDrawer} visible={visible} />
      )}
      {screens.lg && !isNotShowMenu && (
        <DesktopNavigation visible={visible} onClose={handleCloseDrawer} />
      )}
      {isNotShowMenu ? (
        <AutoScreenWrap>{nestedRoute}</AutoScreenWrap>
      ) : (
        <FullScreenWrap>{nestedRoute}</FullScreenWrap>
      )}
    </>
  );
}
export default DashBoard;
