export const formRules = {
  name: [
    {
      required: true,
      message: '¡Por favor ingresa el nombre del activo!',
    },
    {
      whitespace: true,
      message: '¡El nombre del activo no puede estar vacío!',
    },
    {
      min: 8,
      message: '¡El nombre del activo debe tener mínimo 8 caracteres!',
    },
    {
      max: 80,
      message: '¡El nombre del activo debe tener máximo 50 caracteres!',
    },
  ],
  /* schedule: [
    {
      required: true,
      message: '¡Por favor ingresa al menos una día!',
    },
  ], */
  capacity: [
    {
      required: true,
      message: '¡Por favor ingresa la capicadad máxima del activo!',
    },
  ],

 
};
