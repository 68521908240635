import { Row, Col, Grid, Tabs } from 'antd';
import { useTheme } from 'styled-components';
import {
  IoDocumentTextOutline,
  IoAlbumsOutline,
  IoPeopleOutline,
} from 'react-icons/io5';
import { TopBarIcon, TabWrap, CustomAutoScreenWrap } from 'components';
import { ICON_SIZE } from './constans';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'redux/index';
import ProjectsTab from './tabs/ProjectsTab';
import UsersTab from './tabs/UsersTab';

function Projects() {
  const screens = Grid.useBreakpoint();
  const theme = useTheme();
  const token = useSelector(selectIsAuthenticated);

  let titleResponsive = '';
  let tabSize = 'small';

  if (screens.xs === true || screens.sm === true) {
    titleResponsive = 'Odoo';
    tabSize = 'small';
  }
  if (screens.md === true) {
    titleResponsive = 'Gestión de Odoo';
    tabSize = 'large';
  }

  return (
    <>
      <CustomAutoScreenWrap>
        <TopBarIcon
          bgColor={theme.colors.primary}
          color={theme.colors.white}
          text={titleResponsive}
          icon={<IoDocumentTextOutline size={35} color={theme.colors.white} />}
        />

        <Row justify='center' align='center'>
          <Col
            xs={{ span: 22 }}
            sm={{ span: 20 }}
            md={{ span: 20 }}
            lg={{ span: 18 }}
          >
            <Tabs size={tabSize} defaultActiveKey='1'>
              <Tabs.TabPane
                tab={
                  <TabWrap>
                    <IoAlbumsOutline size={ICON_SIZE} />
                    Proyectos Optimus
                  </TabWrap>
                }
                key='1'
              >
                {/* here comes projects */}
                <ProjectsTab token={token} theme={theme} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <TabWrap>
                    <IoPeopleOutline
                      style={{
                        fontSize: '2.5rem',
                        fontWeight: 'bold',
                      }}
                    />
                    Gestión de Usuarios
                  </TabWrap>
                }
                key='2'
              >
                <UsersTab token={token} theme={theme} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </CustomAutoScreenWrap>
    </>
  );
}

export default Projects;
