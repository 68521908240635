import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonStyled = styled(Button)`
  height: 5rem;

  transform: scale(0.9);
  transform: all 300ms;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  position: fixed;
  bottom: 12%;
  right: 8%;
  z-index: 30000;

  &:hover {
    transform: scale(1);
    background-color: white;
    border: none;
    box-shadow: rgb(99 99 99 / 20%) 0px 4px 8px 2px;
  }

  @media (min-width: 992px) {
    top: 20%;
    right: 8%;
  }
`;
