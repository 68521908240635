import styled from 'styled-components';

export const ResourceItemStyled = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 5rem;
  height: auto;
  padding: 1.6rem 2.4rem;
  border-radius: 0.8rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.11);
  background-color: #fbfbfb;
  scroll-behavior: smooth;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
  & > a > span:first-child {
    font-size: 1.6rem;
    font-weight: bold;
  }
  & > a {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #c9c9c9;
    div {
      display: flex;
      align-items: center;
    }
  }
  & .resource__name {
    color: ${(props) => props.theme.colors.gray};
  }
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11);
  }
  @media (min-width: 992px) {
    height: 8rem;
  }
`;
export const IconStyled = styled.div`
  cursor: pointer;
  font-size: 2rem;
  margin-right: 0.5rem;
  color: ${(props) => props.color};
`;
