import { optUsersService } from 'services/ajax/opt-users-service';
import {
  setProfiles,
  setHomeInfo,
  setRequestProgress,
  setProfileWithRelations,
  setProfileWithRelationsRequest,
} from './profiles-slice';
import { setWildcard } from 'helpers/routes-mapper';

const SERVICES_PATH = {
  getProfiles: '/profiles',
  getUserByProfile: '/profiles/',
  getAuthUserByResidential: '/profiles/auth/',
  getProfileDataByResidential: '/profiles/home/',
  getAllProfilesByUserId: 'profiles/user/:id',
  deleteProfile: 'profiles/:id',
  getProfileWithRelations: 'profiles/:id/relations',
  updateProfileWithRelations: 'profiles/:id/relations',
};

export function getProfiles(token) {
  return async (dispatch) => {
    try {
      dispatch(setRequestProgress(true));
      const res = await optUsersService.get(SERVICES_PATH.getProfiles, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(setProfiles({ profiles: res.data }));
    } catch {
    } finally {
      setTimeout(() => {
        dispatch(setRequestProgress(false));
      }, 200);
    }
  };
}

// to resident users
export async function getUserByProfile({ profileId, token }) {
  try {
    const res = await optUsersService.get(
      SERVICES_PATH.getUserByProfile + profileId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

// for own profile
export async function getAuthUserByResidential({ residentialId, token }) {
  try {
    const res = await optUsersService.get(
      SERVICES_PATH.getAuthUserByResidential + residentialId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch {
    return null;
  }
}

export function getProfileDataByResidential(residentialId, token) {
  return async (dispatch) => {
    try {
      const res = await optUsersService.get(
        SERVICES_PATH.getProfileDataByResidential + residentialId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        setHomeInfo({
          info: res.data,
        })
      );
    } catch (error) {
      dispatch(
        setHomeInfo({
          info: null,
        })
      );
    }
  };
}

export async function getProfilesByUserId({ userId, params }) {
  try {
    const url = setWildcard(SERVICES_PATH.getAllProfilesByUserId, userId);
    const res = await optUsersService.get(url, {
      params,
    });

    return res.data;
  } catch {
    return null;
  }
}

export async function deleteProfile({ profileId }) {
  try {
    const url = setWildcard(SERVICES_PATH.deleteProfile, profileId);
    await optUsersService.delete(url);
    return true;
  } catch {
    return null;
  }
}

export async function updateProfile({ profileId, payload }) {
  try {
    const url = setWildcard(SERVICES_PATH.deleteProfile, profileId);
    await optUsersService.patch(url, payload);
    return true;
  } catch {
    return null;
  }
}

export function getProfileWithRelations({ id }) {
  return async (dispatch) => {
    try {
      dispatch(setProfileWithRelationsRequest({ status: true }));
      const url = setWildcard(SERVICES_PATH.getProfileWithRelations, id);
      const res = await optUsersService.get(url);
      dispatch(setProfileWithRelations({ data: res.data }));
      dispatch(setProfileWithRelationsRequest({ status: false }));
    } catch {
      dispatch(setProfileWithRelationsRequest({ status: false }));
      return null;
    }
  };
}

export async function updateProfileWithRelations({ profileId, payload }) {
  try {
    const url = setWildcard(
      SERVICES_PATH.updateProfileWithRelations,
      profileId
    );
    await optUsersService.put(url, payload);
    return true;
  } catch {
    return null;
  }
}
