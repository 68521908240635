import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { LogOutCardWrap } from './Wraps';
import PropTypes from 'prop-types';

function LogOutCard({ onClick }) {
  const handleClick = () => {
    onClick();
  };

  return (
    <LogOutCardWrap onClick={handleClick}>
      <span>
        <FaSignOutAlt size={40} />
      </span>
      <p>Cerrar Sesión</p>
    </LogOutCardWrap>
  );
}

LogOutCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default LogOutCard;
