import styled from 'styled-components';
import { Col } from 'antd';

export const SheetBorder = styled.div`
  width: 100%;
  height: 100%;

  border-radius: ${(props) => props.theme.borders['8']};
  padding: 2rem 1rem;
  /* border: 1px solid #ccc; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  @media (min-width: 557px) {
    padding: 2rem 3rem;
  }
  @media (min-width: 992px) {
    padding: 4rem 5rem;
  }
`;
export const CreatedByContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;

  & .img-container > img {
    width: 5rem;
    height: 5rem;
    border-radius: ${(props) => props.theme.borders['circle']};
  }

  & .user-data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  & .user-data-container > p {
    margin-bottom: 0;
  }

  & .user-name {
    font-weight: 500;
  }

  & .published {
    color: ${(props) => props.theme.colors['gray']};
  }

  @media (min-width: 576px) {
    font-size: 1.8rem;

    & .user-data-container > p {
      margin-bottom: 0;
    }
    & .published {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
    }
  }
`;

export const PostHeaderContainer = styled.div`
  margin-top: 3rem;
  & .main-title {
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;
    letter-spacing: -1px;
    font-weight: bold;
  }

  & .img-container > img {
    width: 100%;
    border-radius: ${(props) => props.theme.borders['4']};
  }

  & .paragraph-container {
    margin-top: 1rem;
    padding: 1rem;
    & > p {
      color: ${(props) => props.theme.colors['black']};
      line-height: 2;
      font-size: 1.6rem;
    }
    & > p::first-letter {
      transform: capitalize;
      font-size: 2.4rem;
      letter-spacing: 1px;
    }
  }
`;

export const CustomColumn = styled(Col)`
  @media (max-width: 575px) {
    margin: 0rem 0 2rem 0;
  }

  @media (min-width: 992px) {
    margin: 2rem 0 6rem 0;
  }
`;
