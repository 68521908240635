import { ADMIN_MODULE, OPERATOR_MODULE, SUPERUSER_MODULE } from 'constants';

export function getFriendlyRole(role) {
  const friendlyRole = {
    superAdmin: 'Super Usuario',
    admin: 'Administrador C.',
    operator: 'Operario',
    user: 'Residente',
  };

  switch (true) {
    case role === SUPERUSER_MODULE:
      return friendlyRole.superAdmin;
    case role === ADMIN_MODULE:
      return friendlyRole.admin;
    case role === OPERATOR_MODULE:
      return friendlyRole.operator;
    default:
      return friendlyRole.user;
  }
}
