import { useState, useCallback, useEffect } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input, Upload, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import {
  updatePost,
  uploadPostImageToS3,
  selectIsAuthenticated,
  getPost,
  updatePostLocally,
} from '../../../redux';
import { TopBarIcon, ButtonStyled, HttpSpinner } from '../../../components';
import { PostContainer } from '../Wraps';
import { getBase64 } from 'helpers/file-reader';
import { openNotification } from 'helpers/notifications';
import { getImageObject } from './helpers';
import { formRules } from '../form-rules';
import { ERROR, SUCCESS } from 'constants';

function EditFormContainer() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [post, setPost] = useState(null);
  const [oldFile, setOldFile] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const token = useSelector(selectIsAuthenticated);

  const props = {
    name: 'dragger',
    limit: 1,
    multiple: false,
    maxCount: 1,
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const newFileName = file.name;
      const whiteList = ['image/jpeg', 'image/png'];
      if (!whiteList.includes(file.type)) return;
      if (fileList.length > 0) {
        const oldFileName = fileList[0].name;
        if (newFileName === oldFileName) return;
      }
      if (file.size / (1024 * 1024) > 1) {
        openNotification(ERROR, 'La imagen debe ser menor a una 1 mega.');
        return;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleRequest = useCallback(async () => {
    setIsMakingRequest(true);
    const post = await getPost(uuid);
    if (!post) return navigate(-1);
    const imageObject = getImageObject(post);
    setFileList([imageObject]);
    setOldFile(imageObject.name);
    setPost(post);
    setIsMakingRequest(false);
  }, [uuid, navigate]);

  useEffect(() => {
    handleRequest();
    return () => setIsMakingRequest(false);
  }, [handleRequest]);

  if (isMakingRequest || !post) return <HttpSpinner />;

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const defaultValues = {
    title: post.post.title,
    description: post.post.description,
  };

  const onFinish = async (loginData) => {
    if (fileList.length === 0) {
      openNotification(
        ERROR,
        'Por favor, Selecciona una imagen para la publicación.'
      );
      return;
    }
    let imgLocation = null;
    const newFile = fileList[0].name;
    if (oldFile !== newFile) {
      setIsMakingRequest(true);
      // upload to s3 if the image changed
      const formData = new FormData();
      formData.append('post-img', fileList[0]);
      imgLocation = await uploadPostImageToS3(formData, token);
      setIsMakingRequest(false);
      if (imgLocation == null) {
        openNotification(
          ERROR,
          'Carga de imagen fallida, Por favor verifica que la imagen sea png, jpeg or jpg con un tamaño menor a 1 mega.'
        );
        return;
      }
    }
    const payload = {
      title: loginData.title,
      description: loginData.description,
    };
    if (imgLocation != null)
      Object.assign(payload, {
        image: imgLocation,
      });
    const updatedPost = await updatePost(uuid, payload, token);
    if (!updatedPost) {
      openNotification(
        ERROR,
        'Creación de publicación fallida, Por favor verifica los datos e intenta nuevamente.'
      );
      return;
    }
    openNotification(SUCCESS, 'Publicación actualizada exitosamente.');
    dispatch(updatePostLocally(updatedPost));
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };
  const handleCancel = () => setPreviewVisible(false);

  return (
    <>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt='imagen de la publicaicón'
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>

      <TopBarIcon
        text='Editar Publicación'
        icon={<IoChatbubbleEllipsesOutline size={40} />}
      />
      <PostContainer>
        <Row justify='center' align='center'>
          <Col
            xs={{ span: 22 }}
            md={{ span: 18 }}
            lg={{ span: 12 }}
            xl={{ span: 10 }}
          >
            <Form
              size='large'
              initialValues={defaultValues}
              form={form}
              onFinish={onFinish}
              autoSave='on'
              autoComplete='off'
            >
              <Form.Item
                label='Por favor, Selecciona una imagen para la publicación'
                hasFeedback
                style={{ display: 'block' }}
                tooltip='Selecciona una imagen de tipo png, jpeg o jpg'
              >
                <ImgCrop rotate aspect={2}>
                  <Upload
                    {...props}
                    listType='picture-card'
                    onPreview={handlePreview}
                    accept='.jpg, .jpeg, .png'
                  >
                    <CloudUploadOutlined />
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                label='Por favor, Ingresa un título para la publicación'
                name='title'
                rules={formRules.title}
                style={{ display: 'block' }}
                tooltip='Recuerda que el titular de la publicación no puede ser mayor a 255 caracteres.'
              >
                <Input placeholder='Título de la publicación' maxLength={255} />
              </Form.Item>
              <Form.Item
                label='Por favor, Ingresar la premisa de la publicación'
                name='description'
                tooltip='Recuerda que la premisa de la publicación no puede ser mayor a 400 caracteres.'
                rules={formRules.description}
                style={{ display: 'block' }}
              >
                <Input.TextArea
                  rows={8}
                  placeholder='Premisa de la publicación'
                  style={{ resize: 'none' }}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  xs: { span: 12 },
                  sm: { span: 16, offset: 4 },
                  lg: { span: 12, offset: 6 },
                }}
              >
                <ButtonStyled
                  type='primary'
                  size='large'
                  block
                  htmlType='submit'
                >
                  Editar Publicación
                </ButtonStyled>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </PostContainer>
    </>
  );
}
export default EditFormContainer;
