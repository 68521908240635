import React from 'react';
import { NavLink } from 'react-router-dom';
import { MobileNavWrap } from './Wraps';
import {
  IoCalendar,
  IoPeopleSharp,
  IoChatbubbleEllipsesSharp,
  IoLinkSharp,
  IoIdCard,
  IoCashSharp,
  IoHome,
  IoDocumentAttach,
  IoPaperPlaneSharp,
} from 'react-icons/io5';
import { FaSignOutAlt } from 'react-icons/fa';

export const SuperAdminNav = ({ uuid, onLogOut }) => {
  return (
    <MobileNavWrap>
      <NavLink to='inicio'>
        <IoHome />
      </NavLink>
      <NavLink
        to={`perfil/${uuid}`}
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoIdCard />
      </NavLink>
      <NavLink
        to='muro'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoChatbubbleEllipsesSharp />
      </NavLink>
      <NavLink
        to='reservas'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoCalendar />
      </NavLink>
      <NavLink
        to='recursos'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoLinkSharp />
      </NavLink>
      <NavLink
        to='activos'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoCashSharp />
      </NavLink>
      <NavLink
        to='usuarios'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoPeopleSharp />
      </NavLink>
      <NavLink
        to='cargas-masivas/usuarios'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoDocumentAttach />
      </NavLink>

      <NavLink
        to='enlaces'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoPaperPlaneSharp />
      </NavLink>
      <NavLink onClick={onLogOut} to='#'>
        <FaSignOutAlt color='#bfbfbf' />
      </NavLink>
    </MobileNavWrap>
  );
};

export const AdminNav = ({ uuid, onLogOut }) => {
  return (
    <MobileNavWrap>
      <NavLink to='inicio'>
        <IoHome />
      </NavLink>
      <NavLink
        to={`perfil/${uuid}`}
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoIdCard />
      </NavLink>
      <NavLink
        to='muro'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoChatbubbleEllipsesSharp />
      </NavLink>
      <NavLink
        to='reservas'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoCalendar />
      </NavLink>
      <NavLink
        to='recursos'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoLinkSharp />
      </NavLink>
      <NavLink
        to='activos'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoCashSharp />
      </NavLink>
      <NavLink
        to='usuarios'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoPeopleSharp />
      </NavLink>
      <NavLink
        to='cargas-masivas/usuarios'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoDocumentAttach />
      </NavLink>
      <NavLink onClick={onLogOut} to='#'>
        <FaSignOutAlt color='#bfbfbf' />
      </NavLink>
    </MobileNavWrap>
  );
};

export const OperatorNav = ({ uuid, onLogOut }) => {
  return (
    <MobileNavWrap>
      <NavLink to='inicio'>
        <IoHome />
      </NavLink>
      <NavLink
        to={`perfil/${uuid}`}
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoIdCard />
      </NavLink>
      <NavLink
        to='muro'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoChatbubbleEllipsesSharp />
      </NavLink>
      <NavLink
        to='reservas'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoCalendar />
      </NavLink>
      <NavLink
        to='recursos'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoLinkSharp />
      </NavLink>
      <NavLink onClick={onLogOut} to='#'>
        <FaSignOutAlt color='#bfbfbf' />
      </NavLink>
    </MobileNavWrap>
  );
};

export const ResidentNav = ({ uuid, onLogOut }) => {
  return (
    <MobileNavWrap>
      <NavLink to='inicio'>
        <IoHome />
      </NavLink>
      <NavLink
        to={`perfil/${uuid}`}
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoIdCard />
      </NavLink>
      <NavLink
        to='muro'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoChatbubbleEllipsesSharp />
      </NavLink>
      <NavLink
        to='reservas'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoCalendar />
      </NavLink>
      <NavLink
        to='recursos'
        className={({ isActive }) => (isActive ? 'is-active' : '')}
      >
        <IoLinkSharp />
      </NavLink>
      <NavLink onClick={onLogOut} to='#'>
        <FaSignOutAlt color='#bfbfbf' />
      </NavLink>
    </MobileNavWrap>
  );
};
