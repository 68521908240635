import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import UserActions from '../actions/UserActions';
import { Table } from 'antd';
import { TagStyled } from 'components';
import { getFriendlyRole } from 'helpers/friendly-data';

function UsersTable({ theme, users, loading, pagination, fetchUsers, token }) {
  const effectRan = useRef(false);

  const columns = [
    {
      title: 'Nombre y Apellidos',
      dataIndex: 'fullName',
      width: 'auto',
      sorter: true,
      filterSearch: true,
      render: (value) => (
        <TagStyled color={theme.colors.blueSix}>{value}</TagStyled>
      ),
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      width: 'auto',
      sorter: true,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      width: 'auto',
      sorter: true,
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      width: 'auto',
      render: (role) => (
        <TagStyled color={theme.colors.cianSix}>
          {getFriendlyRole(role.name)}
        </TagStyled>
      ),
    },
    {
      title: 'Email Verificado',
      dataIndex: 'status',
      width: 'auto',
      sorter: true,
      render: (status) => {
        return (
          <>
            {status === true ? (
              <TagStyled color={theme.colors.primaryNeon}>Verificado</TagStyled>
            ) : (
              <TagStyled color={theme.colors.graySix}>Sin Verificar</TagStyled>
            )}
          </>
        );
      },
    },
    {
      title: 'Estado',
      dataIndex: 'userStatus',
      width: '1%',
      sorter: true,
      render: (value) => {
        const mappedValue = value[0].toUpperCase() + value.slice(1);
        switch (true) {
          case value === 'activo':
            return (
              <TagStyled color={theme.colors.primaryNeon}>
                {mappedValue}
              </TagStyled>
            );

          case value === 'rechazado':
            return (
              <TagStyled color={theme.colors.redFive}>{mappedValue}</TagStyled>
            );

          default:
            return (
              <TagStyled color={theme.colors.graySix}>{mappedValue}</TagStyled>
            );
        }
      },
    },
    {
      title: 'Acciones',
      key: 'operation',
      align: 'center',
      width: 'auto',
      render: (user) => (
        <UserActions
          users={users}
          user={user}
          token={token}
          theme={theme}
          fetchUsers={fetchUsers}
          pagination={pagination}
        />
      ),
    },
  ];

  const handleTableChange = (newPagination, _filters, sorter) => {
    const { current: page, pageSize: limit } = newPagination;
    fetchUsers({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: page,
      limit,
    });
  };

  useEffect(() => {
    if (effectRan.current === false) {
      fetchUsers({
        limit: pagination.pageSize,
        page: pagination.current,
      });
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      size='middle'
      columns={columns}
      rowKey={(user) => user.id}
      dataSource={users}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      showSorterTooltip={false}
      scroll={{ x: 'scroll' }}
    />
  );
}

UsersTable.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default UsersTable;
