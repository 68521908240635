import moment from 'moment';
import {
  ADMIN_MODULE,
  OPERATOR_MODULE,
  RESIDENT_MODULE,
  ERROR,
} from 'constants';
import { Form, Select } from 'antd';
import { openNotification } from 'helpers/notifications';
import { gender } from './form-rules';

const USER = 'Usuario';
const OPERATOR = 'Operador';
const ADMINISTRATOR = 'Administrador';

export const generateRandomId = () => {
  return Array(10)
    .fill(null)
    .map(() => Math.round(Math.random() * 16).toString(16))
    .join('');
};

export const generateCapitalLetter = (value) => {
  if (!value) throw new Error('value param forgotten');
  return value
    .trim()
    .split(' ')
    .map((name) => {
      return name[0].toUpperCase() + name.slice(1);
    })
    .join(' ');
};

export const getImageObject = (payload) => {
  const url = new URL(payload.post.image);
  const fileName = url.pathname.split('/').slice(-1)[0];
  return {
    uid: fileName,
    name: fileName,
    status: 'done',
    url: payload.post.image,
  };
};

export function mapProfileData(profile) {
  const { user, vehicles, members, pets, visits } = profile;
  const formObj = {
    fullName: user.fullName,
    address: profile.address,
    role: mapRole(user.role.name),
    userName: user.userName,
    email: user.email,
    userId: user.id,
  };
  if (user.dateOfBirth != null)
    formObj['dayOfBirthday'] = moment(user.dateOfBirth);
  if (user.gender != null) formObj['gender'] = user.gender;
  if (user.apartmentNumber != null)
    formObj['apartmentNumber'] = user.apartmentNumber;
  if (profile.parkingNumber != null) formObj['parking'] = profile.parkingNumber;

  // phone number
  const { prefix, phone } = mapPhoneNumber(user.phoneNumber);
  // eslint-disable-next-line eqeqeq
  formObj.cellphone = user.phoneNumber == false ? '' : phone; // r
  formObj.prefix = prefix.slice(1);

  // image
  const image = user.image != null ? mapImage(user.image) : [];
  // frequent visits
  const frequentVisitsArr =
    visits.length !== 0 ? mapFrequentVisits(visits) : [];
  // members
  const membersArr = members.length !== 0 ? mapMembers(members) : [];
  // vehicles
  const vehiclesArr = vehicles.length !== 0 ? mapVehicles(vehicles) : [];
  // pets
  const petsArr = pets.length !== 0 ? mapPets(pets) : [];

  return {
    id: user.id,
    profileId: profile.id,
    formObj,
    image,
    frequentVisitsArr,
    membersArr,
    vehiclesArr,
    petsArr,
  };
}

function mapImage(imgUrl) {
  const url = new URL(imgUrl);
  const fileName = url.pathname.split('/').slice(-1)[0];
  return [
    {
      uid: fileName,
      name: fileName,
      status: 'done',
      url: imgUrl,
    },
  ];
}

function mapPets(pets) {
  return pets.map((pet) => {
    const petObj = {
      id: pet.id,
      petName: pet.name,
      petColor: pet.color,
    };
    if (pet.dogBreed) petObj['dogBreed'] = pet.dogBreed;
    if (pet.dateOfBirth) petObj['petBirthday'] = moment(pet.dateOfBirth);
    return petObj;
  });
}
function mapVehicles(vehicles) {
  return vehicles.map((vehicle) => {
    return {
      id: vehicle.id,
      license: vehicle.plate,
      autoColor: vehicle.color,
      carModel: vehicle.model,
      carYear: moment(vehicle.year),
    };
  });
}
function mapMembers(members) {
  return members.map((member) => {
    return {
      id: member.id,
      value: member.name,
    };
  });
}
function mapFrequentVisits(visits) {
  const mappedVisits = visits.map((visit) => {
    return {
      id: visit.id,
      value: visit.fullName,
    };
  });
  return mappedVisits;
}

function mapPhoneNumber(phoneNumber) {
  if (phoneNumber == null) return { prefix: '+57', phone: '' };
  const [prefix, phone] = phoneNumber.split(' ');
  return { prefix, phone };
}

export function mapRole(role) {
  if (role === RESIDENT_MODULE) return USER;
  if (role === OPERATOR_MODULE) return OPERATOR;
  if (role === ADMIN_MODULE) return ADMINISTRATOR;
  return null;
}

export function getOriginalRole(role) {
  if (role === USER) return RESIDENT_MODULE;
  if (role === OPERATOR) return OPERATOR_MODULE;
  if (role === ADMINISTRATOR) return ADMIN_MODULE;
  return null;
}

// Submit
export function mapShippingPhone(prefix, phoneNumber) {
  return `+${prefix} ${phoneNumber}`;
}

export function mapShippingMembers(members) {
  return members.map((member) => {
    return { name: member.value };
  });
}

export function mapShippingPets(pets) {
  return pets.map((pet) => {
    const petObj = {
      name: pet.petName,
      color: pet.petColor,
    };
    if (pet?.dogBreed) petObj['dogBreed'] = pet.dogBreed;
    if (pet?.petBirthday) petObj['dateOfBirth'] = pet.petBirthday.format();
    return petObj;
  });
}

export function mapShippingVisits(visists) {
  return visists.map((visit) => visit.value);
}

export function mapShippingVehicles(vehicles) {
  return vehicles.map((vehicle) => {
    return {
      plate: vehicle.license,
      color: vehicle.autoColor,
      model: vehicle.carModel,
      year: vehicle.carYear.format('YYYY'),
    };
  });
}

export function mapShippingDateOfBirth(dayOfBirthday) {
  return new Date(dayOfBirthday.format('YYYY-MM-DD')).toISOString();
}

export function hanldeShippingValidation(formData) {
  if (formData.gender) {
    if (!gender.includes(formData.gender)) {
      openNotification(
        ERROR,
        'Error fatal, El genero seleccionado no está disponible.'
      );
      return;
    }
  }
  return true;
}

export function mapShippingUsername(username) {
  return username.trim().split(' ').join('').toLowerCase();
}

export const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Select.Option value='57'>+57</Select.Option>
    </Select>
  </Form.Item>
);
