import { useEffect } from 'react';
import { Form, Button, Input, Row, Col } from 'antd';
import { resourceRules } from '../form-rules';
import { useSelector } from 'react-redux';
import {
  createResource,
  selectProfileId,
  selectProjectId,
  updateResource,
} from 'redux/index';
import { openNotification } from 'helpers/notifications';
import { ERROR, SUCCESS } from 'constants';
import PropTypes from 'prop-types';

function ResourceModalContent({ onCreation, token, edition, onUpdate }) {
  const [form] = Form.useForm();
  const profileId = useSelector(selectProfileId);
  const residentialId = useSelector(selectProjectId);

  const onReset = () => {
    form.resetFields();
  };

  const onFill = () => {
    form.setFieldsValue(edition);
  };

  const handleCreationReq = async (payload) => {
    const newResource = await createResource(payload, token);
    if (newResource == null) {
      openNotification(
        ERROR,
        'El recurso no puedo ser creado, Por favor verifica los datos e intenta nuevamente.'
      );
      return;
    }
    return newResource;
  };
  const handleUpdate = async (payload) => {
    const updatedResource = await updateResource({
      id: edition?.id,
      payload,
      token,
    });
    if (updateResource == null) {
      openNotification(
        ERROR,
        'El recurso no puedo ser actualizado, Por favor verifica los datos e intenta nuevamente.'
      );
      return;
    }
    return updatedResource;
  };

  const handleFinish = async (data) => {
    const payload = { profileId, residentialId, ...data };
    if (edition == null) {
      const newResource = await handleCreationReq(payload);
      if (newResource == null) return;
      onCreation(newResource);
      openNotification(SUCCESS, 'El recurso fue creado exitosamente.');
    } else {
      const updatedResource = await handleUpdate(data);
      if (updatedResource == null) return;
      onUpdate(updatedResource);
      openNotification(SUCCESS, 'El recurso fue actualizado exitosamente.');
    }
    onReset();
  };

  useEffect(() => {
    if (edition != null) onFill();
    return () => {
      onReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edition]);

  return (
    <Form size='large' onFinish={handleFinish} autoComplete='off' form={form}>
      <Form.Item
        label='Nombre'
        tooltip='Por favor, Escribe el nombre del recurso aquí'
        name='name'
        rules={resourceRules.name}
        hasFeedback
        required
        style={{
          display: 'block',
        }}
      >
        <Input placeholder='Escribe el nombre del recurso' />
      </Form.Item>
      <Form.Item
        label='Link'
        name='link'
        tooltip='Por favor, Ingresa un enlace, por ejemplo: https://www.google.com'
        rules={resourceRules.link}
        hasFeedback
        required
        style={{
          display: 'block',
        }}
      >
        <Input placeholder='Pega el enlace del recurso' />
      </Form.Item>
      <Row justify='center'>
        <Col span={10}>
          <Form.Item>
            <Button key='submit' htmlType='submit' type='primary' block>
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

ResourceModalContent.propTypes = {
  token: PropTypes.string.isRequired,
  onCreation: PropTypes.func.isRequired,
  edition: PropTypes.object,
  onUpdate: PropTypes.func,
};
export default ResourceModalContent;
