import styled from 'styled-components';
import { Button } from 'antd';

export const FixedWrap = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(-150%, -100%);
  transition: all 300ms;
  z-index: 10000;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 6rem;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 300ms ease-out;
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    border: none;
    color: ${(props) => props.theme.colors.primary};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 2px;
  }
`;
