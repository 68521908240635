import styled from 'styled-components';
import { Tag } from 'antd';

export const TagStyled = styled(Tag)`
  font-weight: bold;
  font-size: 1.4rem;
  opacity: 0.9;
  transition: all 300ms;
  &:hover {
    transform: scale(1.2);
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
`;

export const ActiveState = styled.span`
  color: ${(props) => props.theme.colors.primaryNeon};
`;
export const InactiveState = styled.span`
  color: ${(props) => props.theme.colors.graySix};
  font-weight: bold;
`;
