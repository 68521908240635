import { useEffect, useRef } from 'react';
import { Row, Col, List, Grid, message } from 'antd';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  IoBusinessSharp,
  IoLocationOutline,
  IoLogOutOutline,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { ListStyled, AvatarStyled } from './Wraps';
import {
  TopBarIcon,
  AutoScreenWrap,
  FloatBackground,
  HttpSpinner,
  ButtonStyled,
} from 'components';
import {
  selectProfilesState,
  getProfiles,
  saveResidential,
  cleanResidential,
  resetPostStates,
  selectIsAuthenticated,
  selectUserRole,
  setLogOut,
} from 'redux/index';
import ProjectsManagementBtn from './components/ProjectsManagementBtn';

const Dashboard = () => {
  const effectRan = useRef(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const screens = Grid.useBreakpoint();
  const dispatch = useDispatch();
  const { profiles, isMakingRequest } = useSelector(selectProfilesState);
  const userRole = useSelector(selectUserRole);
  const token = useSelector(selectIsAuthenticated);
  const layout = screens.md ? 'horizontal' : 'vertical';

  useEffect(() => {
    message.destroy();
    if (effectRan.current === false) {
      dispatch(getProfiles(token));
      return () => {
        effectRan.current = true;
      };
    }

    return () => {
      dispatch(cleanResidential());
      dispatch(resetPostStates());
    };
  }, [dispatch, token]);

  if (isMakingRequest === true) return <HttpSpinner />;

  const handleClick = ({ residentialId, profileId }) => {
    dispatch(saveResidential({ residentialId, profileId }));
    navigate('inicio');
  };

  const handleLogOut = () => {
    dispatch(setLogOut());
    navigate('/login', { replace: true });
  };

  return (
    <>
      <ProjectsManagementBtn role={userRole} navigate={navigate} />

      <AutoScreenWrap>
        <FloatBackground />
        <TopBarIcon
          text='Seleccionar Proyecto'
          noActions={true}
          icon={<IoLocationOutline size={40} />}
        />
        <Row id='center-container-testing' align='center' justify='center'>
          <Col
            xs={{ span: 22 }}
            sm={{ span: 20 }}
            md={{ span: 18 }}
            lg={{ span: 16 }}
            xl={{ span: 12 }}
            xxl={{ span: 10 }}
          >
            <ButtonStyled onClick={handleLogOut} size='large' type='ghost'>
              <span>Cerrar Sesión</span>
              <IoLogOutOutline size={30} />
            </ButtonStyled>

            <ListStyled
              itemLayout={layout}
              dataSource={profiles}
              renderItem={(profile) => (
                <List.Item
                  onClick={() =>
                    handleClick({
                      residentialId: profile.project.id,
                      profileId: profile.profileId,
                    })
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <AvatarStyled
                        icon={<IoBusinessSharp color={theme.colors.gray} />}
                      />
                    }
                    title={<p>Proyecto - {`${profile.project.name}`}</p>}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </AutoScreenWrap>
    </>
  );
};
export default Dashboard;
