import { useState } from 'react';
import { Tooltip } from 'antd';
import { IoRepeatOutline } from 'react-icons/io5';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { UserModal, IconStyled, ActionsWrap } from 'components';
import { confirmationModal } from 'helpers/confirmations';
import {
  deleteProfile,
  updateProfile,
  getProfileWithRelations,
  setProfileWithRelations,
  selectProfileWithRelations,
} from 'redux/index';
import { useDispatch, useSelector } from 'react-redux';
import { executeAndUpdateTableRecords } from 'helpers/tables';
import EditUserProfiles from './components/EditUserProfiles';

function UserActions({ theme, profile, profiles, fetchProfiles, pagination }) {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const profileAndRelations = useSelector(selectProfileWithRelations);

  const fetchProfilesWithRelations = () =>
    dispatch(getProfileWithRelations({ id: profile.id }));

  const toggleModal = () => {
    setIsModalVisible((preValue) => {
      const newStatus = !preValue;
      if (newStatus === false) {
        dispatch(setProfileWithRelations({}));
      } else {
        fetchProfilesWithRelations();
      }
      return newStatus;
    });
  };

  const changeProfileStatus = async () => {
    await executeAndUpdateTableRecords(
      profiles,
      () =>
        updateProfile({
          profileId: profile.id,
          payload: { status: !profile.status },
        }),
      pagination,
      {
        failed: `Actualización de estado fallida, Por favor recarga la página y vuelve a intentar.`,
        success: 'Estado del perfil actualizado exitosamente.',
      },
      fetchProfiles,
      IoRepeatOutline
    );
  };

  const newProfileStatus = profile.status === true ? 'Desactivar' : 'Activar';
  const newProfileStatusContent =
    profile.status === true
      ? 'Recuerda que una vez Desactivado el perfil este dejará de estar disponible.'
      : 'Recuerda que una vez Activado el perfil este volverá a estar disponible.';

  const confirmationModalContent = [
    `¿Esta seguro de ${newProfileStatus} el perfil?`,
    newProfileStatusContent,
    newProfileStatus,
    'primary',
    null,
    null,
    () => changeProfileStatus(),
  ];

  const onDeleteUser = async () => {
    await executeAndUpdateTableRecords(
      profiles,
      () => deleteProfile({ profileId: profile.id }),
      pagination,
      {
        // failed: '',
        success:
          'Perfil eliminado con todas sus relaciones, visitas, vehículos y demás.',
      },
      fetchProfiles,
      DeleteOutlined
    );
  };

  const deleteUserParams = [
    null, // title
    null, // content
    null, // okText
    null, // okType
    null, // cancelText
    null, // Icon
    onDeleteUser, // callback
  ];

  const iconsStyles = (color, size) => {
    return { color: color, fontSize: size };
  };

  const renderStatus =
    profile.status === true ? (
      <Tooltip title='Desactivar Perfi'>
        <IconStyled
          onClick={() => confirmationModal(...confirmationModalContent)}
        >
          <IoRepeatOutline
            style={{ ...iconsStyles(theme.colors.primaryNeon, '2.2rem') }}
          />
        </IconStyled>
      </Tooltip>
    ) : (
      <Tooltip title='Activar Perfil'>
        <IconStyled
          onClick={() => confirmationModal(...confirmationModalContent)}
        >
          <IoRepeatOutline
            style={{ ...iconsStyles(theme.colors.primaryNeon, '2.2rem') }}
          />
        </IconStyled>
      </Tooltip>
    );

  return (
    <>
      <UserModal
        title='Editar Perfiles'
        isModalVisible={isModalVisible}
        handleSaveModal={toggleModal}
        handleCancelModal={toggleModal}
        saveText={''}
        width='70rem'
        children={<EditUserProfiles profile={profileAndRelations} />}
      />

      <ActionsWrap>
        {renderStatus}

        <Tooltip title='Editar Perfil'>
          <IconStyled onClick={toggleModal}>
            <EditOutlined
              style={{ ...iconsStyles(theme.colors.yellowSix, '2rem') }}
            />
          </IconStyled>
        </Tooltip>

        <Tooltip title='Eliminar Perfil'>
          <DeleteOutlined
            onClick={() => confirmationModal(...deleteUserParams)}
            style={{
              ...iconsStyles(theme.colors.redFive, '1.9rem'),
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </ActionsWrap>
    </>
  );
}

export default UserActions;
