import { DeleteOutlined } from '@ant-design/icons';
import { deleteLogOfError } from 'redux/index';
import { openNotification } from 'helpers/notifications';
import { ERROR, SUCCESS } from 'constants';

export const nofityLogElimination = async (id, token, pagination, cb) => {
  const res = await deleteLogOfError(id, token);
  if (res == null) {
    openNotification(
      ERROR,
      'Eliminación fallida, Por favor recarga la página e intenta nuevamente.',
      DeleteOutlined
    );
    return;
  }
  openNotification(
    SUCCESS,
    'Eliminación satisfactoria, el registro a eliminar ha sido removido del sistema.',
    DeleteOutlined
  );
  cb(pagination);
};
