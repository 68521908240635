import styled from 'styled-components';

export const TopActionsWrap = styled.div`
  width: 100%;
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
  flex-wrap: wrap;
`;
